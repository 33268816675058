import { AnimatePresence, motion } from 'framer-motion';
import { Children, memo } from 'react';
import { useAnimatedText } from './use-animated-text';

export const AnimatedText = memo(({ className = "", wrapAs = 'div', ...rest }) => {
  const { isVisible, charVerients, words, wrapperVarients } = useAnimatedText(rest)
  if (!isVisible) {
    return null;
  }
  const TextWrapper = motion[wrapAs] || motion.div;
  return (
    <AnimatePresence exitBeforeEnter>
      <TextWrapper
        className={className}
        variants={wrapperVarients}
        initial="enter"
        animate="center"
      >{
          Children.toArray(words.map((word, i) => {
            return (
              <motion.div style={{ display: 'inline-block', overflow: 'hidden' }}>
                <motion.div
                  variants={charVerients}
                  initial="enter"
                  animate="center"
                  style={{ display: 'inline-block', willChange: 'transform', overflow: 'hidden' }}
                  custom={i}
                >
                  {word}
                </motion.div>
              </motion.div>
            )
          }))}
      </TextWrapper>
    </AnimatePresence>
  );
});