import { fetchMessages } from "Store/reducers/messages";
import { fetchMessage } from "Store/reducers/message";
import { fetchMessageExperience } from "Store/reducers/message-experience";
import { fetchMessageUser } from "Store/reducers/message-user";
import { fetchToken } from "Store/reducers/token";

export const loadMessages = orderToken => async (dispatch, getState) =>  {
  return dispatch(fetchMessages(orderToken));
}

export const loadMessage = qrCode => async (dispatch, getState) =>  {
  return dispatch(fetchMessage(qrCode));
}

export const loadMessageExperience = messageToken => dispatch => {
  dispatch(fetchMessageExperience(messageToken));
} 

export const loadMessageUser = (messageToken, ocassionId) => dispatch => {
  dispatch(fetchMessageUser(messageToken, ocassionId));
} 
