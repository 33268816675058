import React from "react";
import ReactHowler from "react-howler";
import raf from "raf"; // requestAnimationFrame
import Button from "./Button";

class FullControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      loaded: false,
      loop: false,
      mute: false,
      volume: 1.0,
      seek: 0.0,
      isSeeking: false,
      enabled: props.enabled,
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleOnLoad = this.handleOnLoad.bind(this);
    this.handleOnEnd = this.handleOnEnd.bind(this);
    this.handleOnPlay = this.handleOnPlay.bind(this);
    this.handleStop = this.handleStop.bind(this);
    this.renderSeekPos = this.renderSeekPos.bind(this);
    this.handleLoopToggle = this.handleLoopToggle.bind(this);
    this.handleMuteToggle = this.handleMuteToggle.bind(this);
    this.handleMouseDownSeek = this.handleMouseDownSeek.bind(this);
    this.handleMouseUpSeek = this.handleMouseUpSeek.bind(this);
    this.handleSeekingChange = this.handleSeekingChange.bind(this);
  }

  mute() {
    this.setState({
      volume: 0.0,
    });
  }

  componentWillUnmount() {
    this.clearRAF();
  }

  handleToggle() {
    this.setState({
      playing: !this.state.playing,
    });
    this.props.prePlaySounds();
  }

  handlePrePlaySounds() {
    this.props.prePlaySounds();
  }
  handleOnLoad() {
    this.setState({
      loaded: true,
      duration: this.player.duration(),
    });
    this.props.onLoad();
  }

  handleOnPlay() {
    this.renderSeekPos();
    this.props.prePlaySounds();
    this.props.onPlay();
  }

  handleOnEnd() {
    this.setState({
      playing: false,
    });
    this.clearRAF();

    this.props.onEnd();
  }

  handleStop() {
    this.player.stop();
    this.setState({
      playing: false, // Need to update our local state so we don't immediately invoke autoplay
    });
    this.renderSeekPos();
  }

  handleLoopToggle() {
    this.setState({
      loop: !this.state.loop,
    });
  }

  handleMuteToggle() {
    this.setState({
      mute: !this.state.mute,
    });
  }

  handleMouseDownSeek() {
    this.setState({
      isSeeking: true,
    });
  }

  handleMouseUpSeek(e) {
    this.setState({
      isSeeking: false,
    });

    this.player.seek(e.target.value);
  }

  handleSeekingChange(e) {
    this.setState({
      seek: parseFloat(e.target.value),
    });
  }

  renderSeekPos() {
    if (!this.state.isSeeking) {
      this.setState({
        seek: this.player.seek(),
      });
    }
    if (this.state.playing) {
      this._raf = raf(this.renderSeekPos);
    }
  }

  clearRAF() {
    raf.cancel(this._raf);
  }

  isPlaying() {
    if (this.player) {
      return this.state.playing;
    }
  }

  isLoaded() {
    if (this.player) {
      return this.state.loaded;
    }
  }

  getDuration() {
    if (this.player) {
      return this.player.duration();
    }
  }

  getSeek() {
    if (this.player) {
      return this.player.seek();
    }
  }

  play() {
    if (this.player) {
      this.player.play();
      this.setState({
        playing: true, // Need to update our local state so we don't immediately invoke autoplay
      });
    }
  }

  render() {
    return (
      <div className="full-control">
        <ReactHowler
          src={[this.props.audioFile]}
          playing={this.state.playing}
          onLoad={this.handleOnLoad}
          onPlay={this.handleOnPlay}
          onEnd={this.handleOnEnd}
          loop={this.state.loop}
          mute={this.state.mute}
          volume={this.state.volume}
          ref={(ref) => (this.player = ref)}
        />
        <Button
          className="howler-play-button"
          onClick={this.handleToggle}
          disabled={!this.state.enabled}
        >
          {this.state.playing ? (
            <span className="pause-icon"></span>
          ) : (
            <span className="play-icon"></span>
          )}
        </Button>
      </div>
    );
  }
}

export default FullControl;
