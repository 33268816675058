import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
// import OrbitControls from 'three-orbitcontrols';

export const createScene = () => {
  return new THREE.Scene();
}

export const createRenderer = ({ props }) => {
  const { width, height, rendererOptions = {}, primaryColour } = props;
  const { antialias = true, pixelRatio = window.devicePixelRatio, clearColor = primaryColour } = rendererOptions;
  const renderer = new THREE.WebGLRenderer({ antialias, alpha: true, });
  renderer.setPixelRatio(pixelRatio);
  renderer.setClearColor(clearColor);
  renderer.setSize(width, height);
  return renderer;
}

export const createCamera = ({ props, renderer }) => {
  const { width, height, cameraOptions = {} } = props;
  const { fov = 75, near = 0.1, far = 1000, positionZ = 10, enableControls = false } = cameraOptions;
  const camera = new THREE.PerspectiveCamera(fov, width / height, near, far);
  camera.position.z = positionZ;

  //Camera Controls
  const controls = new OrbitControls(camera, renderer.domElement);
  controls.enabled = enableControls;

  return camera;
}

export const createPointLights = ({ scene }) => {
  const initialValue = {
    color: 0xffffff,
    intensity: 1,
    distance: 0,
    x: 0,
    y: 200,
    z: 0
  };
  [{
    ...initialValue,
    color: 0x304ffe,
  }, {
    ...initialValue,
    x: 100,
    z: 100
  }, {
    ...initialValue,
    x: -100,
    y: -200,
    z: -100
  }].forEach(({ color, intensity, distance, x, y, z }) => {
    const pointLightCenter = new THREE.PointLight(color, intensity, distance);
    pointLightCenter.position.set(x, y, z);
    scene.add(pointLightCenter);
  });
}