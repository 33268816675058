import * as THREE from "three";
import gsap from "gsap";
import { GetAssetPath } from "Services/apiService";

export class WalkingMan {

  constructor(parent, color, duration, positionVector3) {

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(GetAssetPath('Black_label-walking-man.png'));

    // create container
    const container = new THREE.Group();
    container.position.set(positionVector3.x, positionVector3.y, positionVector3.z);
    parent.add(container);

    for (var i = 0; i < 8; i++) {

      for (var j = 0; j < 4; j++) {

        // create plane 
        const geometry = new THREE.PlaneGeometry(13.6, 24.0, 2);
        const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
        const plane = new THREE.Mesh(geometry, material);
        container.add(plane);

        // set plane initial state
        plane.position.set(25 * i, 5 * i + j * 40, -1000);

        // animate   
        gsap.to(plane.position, { delay: 0.05 * i, z: -50, ease: "expo.inOut", duration: duration });
        gsap.to(plane.position, { delay: duration + 0.05 * i, y: 500, ease: "expo.inOut", duration: duration });

      }
    }

    gsap.to(container.position, {
      delay: duration + 0.5, y: -1, ease: "expo.inOut", duration: duration, delay: duration, onComplete: function () {
        parent.remove(container);
      }
    });


  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default WalkingMan; // Don’t forget to use export default!