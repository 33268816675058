import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { GET_BRANDS } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  brandData: [],
  brandState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(GET_BRANDS);

export const fetchBrands = (retailerKey) => {
  const data = retailerKey;
  return apiAction({
    url: "/offtrade/brands",
    onStart,
    onSuccess,
    onFailure,
    label: GET_BRANDS,
    data,
    method: "POST",
  });
};

export const brandsReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: GET_BRANDS,
      onSuccess: (state, payload) => {
        return {
          ...state,
          brandState: REQUEST_STATUS.SUCCESS,
          brandData: payload,
        };
      },
      stateField: "brandState",
    }),
  },
  initialState
);

export const brandsReducerSelector = (state) => {
  return state.brandsReducer.brandData;
};
