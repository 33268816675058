import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"

export class AnimationTextureTalisker_2 extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('talisker-texture-blue.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(7.50, 13.34, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		var sc = 1.5;
		plane1.scale.set(sc, sc, sc);

		// set plane initial state
		plane1.position.set(0, -20, -4);

		// load and set texture initial state
		const texture2 = new THREE.TextureLoader().load(this.getAssetPath('talisker-6.jpg'));

		// create plane 
		const geometry2 = new THREE.PlaneGeometry(5.08, 9.02, 2);
		const material2 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture2 });
		const plane2 = new THREE.Mesh(geometry2, material2);
		this.parent.add(plane2);

		plane2.position.set(0, -14, 0);

		gsap.to(plane2.position, { delay: 0.2, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane2.position, { delay: this.duration + 0.1, y: 14, ease: "expo.inOut", duration: this.duration })



		const positionVector3 = new THREE.Vector3(-3, -10, 0);
		const coord = new Coordinates(this.parent, this.colors[2], this.duration, positionVector3);

		gsap.to(coord.position, { delay: 0, y: -3.8, ease: "expo.inOut", duration: this.duration });
		gsap.to(coord.position, { delay: this.duration, y: 20, ease: "expo.inOut", duration: this.duration });


		// animate
		//gsap.to(texture.offset,{delay:0, x:0, y:0, ease: "expo.inOut", duration:this.duration})
		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, {
			y: 20, delay: this.duration, ease: "expo.inOut", duration: this.duration, onComplete: function () {
				//self.isAnimating = false;

				self.parent.remove(coord);
				self.parent.remove(plane1);
				self.parent.remove(plane2);
			}
		});
	}

	destroy() {

	}

}

export default AnimationTextureTalisker_2; // Don’t forget to use export default!