import { BaseCreative } from "./base-creative";
import * as THREE from "three";


export class Tile extends BaseCreative {
  constructor({ noKeywords, textureConfig, isOccasion, ...props }) {
    super(props);
    this.noKeywords = noKeywords;
    this.isOccasion = isOccasion;
    this.textureConfig = textureConfig;

    this.halfDuration = this.duration / 2;
    this.halfPi = Math.PI / 2;
  }
  addTexture = () => {
    this.textureConfig.forEach(({ columns, y }) => {
      this.dummyMapper(columns, i => {
        const mesh = this.addMesh(null, 1, 1, 5, { map: this.loadAsset().element });
        mesh.setPosition(-10, columns, 3, {
          x: i - 2,
          delay: 0.05 * i,
          y,
        }, {
          y: 11,
          delay: this.duration
        });
        mesh.setRotation(
          0,
          this.halfPi,
          0,
          {
            y: 0,
            delay: this.halfDuration,
            duration: this.halfDuration
          },
          {
            y: this.halfPi,
            delay: this.duration,
            duration: this.duration
          }
        );
      });
    })
  }

  occasionKeyword = () => {
    const textLine = this.addTextLine(2, 4, 300, this[this.textColour[0]], undefined, this.font[this.fontName[0]]);
    textLine.setRotation(0, 0, Math.PI / 2);
    textLine.setPosition(0, 0, 4,
      {}, {
      delay: this.duration,
      x: -20
    });
    textLine.setMaterialOffset({
      y: 1
    }, {
      delay: 0,
      y: 0
    });
  }

  addKeyword = () => {
    if (this.isOccasion) {
      return this.occasionKeyword();
    }
    if (this.noKeywords) {
      return;
    }
    const x = -3;
    const y = -3;

    //console.log("tile");

    this.dummyMapper(5, i => {

      const textLine = this.addTextLine(1.75, 4, 300, this[this.textColour[0]], undefined, this.font[this.fontName[0]]);
      
      // getting bounding box to measure text width
      var box = new THREE.Box3().setFromObject( textLine.element );

       // calculating text width
      const textWidth = box.max.x - box.min.x;

      textLine.setPosition(
        x+(textWidth/2) + (i * 0.85),
        y + (i * 1.2),
        4.75, {
          y: 10,
          delay: this.duration
        }
      );
      textLine.setMaterialOffset({
        y: i + 1
      }, {
        delay: 0.05 * i,
        y: 0,
        duration: this.halfDuration
      }, {
        delay: this.duration,
        y: -1,
        duration: this.halfDuration
      });
    });
  }

  start() {
    this.addBackground({ sameBackground: true });
    this.addTexture();
    this.addKeyword();
  }
}