import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { apiUrl } from "Utils/common";
import LoadingScreen from "../../components/loading/loading";
import { useDispatch, useSelector } from "react-redux";
import { fetchOfftradeTranslations } from "../../store/reducers/offtrade";

export default function Scan() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const [errorMessage, setErrorMessage] = useState({});

  useEffect(() => {
    console.log('Fetching Translations')
    const url = new URL(window.location.href.replace("/#", ""));
    const queryParams = new URLSearchParams(url.search);
    dispatch(fetchOfftradeTranslations(queryParams.get("qrCode")));
  }, [dispatch]);

  useEffect(() => {
    const url = new URL(window.location.href.replace("/#", ""));
    const queryParams = new URLSearchParams(url.search);
    const qrCode = queryParams.get("qrCode");
    const retailerId = queryParams.get("retailerId");
    const utm_source = queryParams.get("utm_source");

    const scanQrCode = async () => {
      const res = await axios({
        baseURL: apiUrl(),
        url: "message/scan",
        data: {
          qrCode: qrCode,
          retailerId: retailerId,
          utm_source: utm_source,
        },
        method: "post",
      });

      setTimeout(() => {
        if (res.status) {
          const redirect = res.data.RedirectTo;
          window.location.href = redirect;
        } else {
          setLoading(false);
          setErrorMessage(res);
        }
      }, 250);
    };
    scanQrCode();
  }, [history]);

  return (
    <>
      {(isLoading === false && offTradeTranslationData) ? (
        <div className="page-wrap page-wrap--light sway-wrap">
          <section className="ant-layout ant-layout-has-sider">
            <aside className="side-bar side-bar--light side-bar--welcome">
              <Bottle className="bottle-image" />
            </aside>
            <main className="ant-layout">
              <div className="container">{errorMessage}</div>
            </main>
          </section>
        </div>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
}

// ("/messsage?qrCode=${qrCode}&retailerId=${retailerId}&utm_source=${utm_source}");
