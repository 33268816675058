import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Brush from "../common/Brush.js"
import TextLine from "../common/textLine.js"


export class AnimationBrushSingleton_1 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

    const self = this;

    const positionVector3 = new THREE.Vector3(-1, -1, 0 );
    var brush = new Brush(this.parent, positionVector3, 0, this.colors[3], this.duration);
    brush.rotation.set(0,0,-Math.PI/8);

    const positionVector3b = new THREE.Vector3(1, -5, 0 );
    var brush2 = new Brush(this.parent, positionVector3b, 0.1, this.colors[3], this.duration);
    brush2.rotation.set(0,0,0);

    const word = new TextLine(this.keyword, 1, 1, 400, this.colors[2], undefined, "Authenia W05");
    this.parent.add(word);

    word.position.set(0,-2,3.1);
    word.material.map.offset.set(0,1);

    const wordTop1 = new TextLine(this.keyword, 1, 1, 400, this.colors[2], undefined, "Authenia W05");
    this.parent.add(wordTop1);

    wordTop1.position.set(-2.5,2,3.1);
    wordTop1.material.map.offset.set(0,1);

     const wordTop2 = new TextLine(this.keyword, 1, 1, 400, this.colors[2], undefined, "Authenia W05");
    this.parent.add(wordTop2);

    wordTop2.position.set(2.5,2,3.1);
    wordTop2.material.map.offset.set(0,1);

    gsap.to(word.material.map.offset,{delay:0.1, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordTop1.material.map.offset,{delay:0.1, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordTop2.material.map.offset,{delay:0.1, y:0, ease: "expo.inOut", duration:this.duration});


    gsap.to(word.material.map.offset,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordTop1.material.map.offset,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordTop2.material.map.offset,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(brush);
      self.parent.remove(brush2);
      self.parent.remove(word);
      self.parent.remove(wordTop1);
      self.parent.remove(wordTop2);
    }});
  }

 
  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationBrushSingleton_1; // Don’t forget to use export default!