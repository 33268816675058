import { handleActions } from 'redux-actions'
import { REQUEST_STATUS } from 'Axios/constants'
import { defineLoopActions, requestLoopHandlers } from 'Axios/state'
import { apiAction } from 'Axios/apiCall'
import { ADD_RECORED_TEXT, CLEAR_TEXT_RECORDING } from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  addTextState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  addTextData: null,
  error: {}
}

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure
} = defineLoopActions(ADD_RECORED_TEXT)

export const fetchAddText = data => {
  return apiAction({
    url: '/authoring/addText',
    method: 'POST',
    label: ADD_RECORED_TEXT,
    data,
    onStart,
    onSuccess,
    onFailure,
  })
}

export const addTextReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: ADD_RECORED_TEXT,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          addTextState: REQUEST_STATUS.SUCCESS,
          addTextData: payload
        }
      },
      onFailure: (state) => ({
        ...state,
        addTextState: REQUEST_STATUS.FAIL,
        addTextData: null
      })
    }),
    [CLEAR_TEXT_RECORDING]: (state) => ({
      ...state,
      addTextData: null,
      error: null,
    }),
  },
  initialState
)


export const clearTextRecording = () => {
  return {
    type: CLEAR_TEXT_RECORDING,
  }
}
