import { cipher, decipher } from './cipher';
import { settings } from './configs';

const Storage = typeof window !== 'undefined' ? window[settings.STORAGE_TYPE] : null;

const WebStorage = {
  setItem: (key, value)=> {
    if (Storage) {
      Storage.setItem(key, cipher(settings.SALT)(value));
    }
  },
  getItem: (key) => {
    if (Storage) {
      return decipher(settings.SALT)(Storage.getItem(key));
    }

    return null;
  },
  removeItem: (key)=> {
    if (Storage) {
      Storage.removeItem(key);
    }
  },
  keys: ()=> {
    if (Storage) {
      return Object.keys(Storage);
    }
    return [];
  },
};

const returnObj = (key) => ({
  value: WebStorage.getItem(key),
  clear: ()=> WebStorage.removeItem(key),
});

export const QrCode = (token) => {
  const key = settings.QR_CODE;
  if (token) {
    WebStorage.setItem(key, token);
  }

  return returnObj(key);
};

export const OrderToken = (token) => {
  const key = settings.ORDER_TOKEN;
  if (token) {
    WebStorage.setItem(key, token);
  }
  return  returnObj(key);
};


export const AuthToken = (token) => {
  const key = settings.DUMMY;
  if (token) {
    WebStorage.setItem(key, token);
  }
  return  returnObj(key);
};