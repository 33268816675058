import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Parallelogram from "../common/Parallelogram.js"
import TextLine from "../common/textLine.js"

export class AnimationParallelogram_1 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

 	  this.createParallelogram(3, 0.2, this.colors[3]);

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 4; i++) {
      const word = new TextLine(this.keyword.toUpperCase(), 1.8, 1.8, 400, this.colors[3], undefined, "JohnnieWalkerWeb-Headline");
      wordContainer.add(word);
      word.position.set(-1+i*1.3,-2,0);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.x = 1;
      gsap.to(word.material.map.offset,{delay:0.05*i, x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration+0.05*i, x:-1, ease: "expo.inOut", duration:this.duration})
    }

    const self = this;
    gsap.to(wordContainer.position,{delay:this.duration+0.5, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(wordContainer);
    }})

  }

  createParallelogram(yPosition, startDelay, color){
  	 var parallelogram = new Parallelogram(this.parent, yPosition, startDelay, color, this.duration);
  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationParallelogram_1; // Don’t forget to use export default!