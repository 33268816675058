import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"

export class AnimationMask extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('black_label_1.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(50.8, 90.2, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		// set plane initial state
		plane1.position.set(0, -250, -90);

		const tl = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[2], undefined, "JohnnieWalkerWeb-Headline");
		this.parent.add(tl);
		tl.position.set(0, -70, -2);
		gsap.to(tl.position, { delay: 0, y: -4, ease: "expo.inOut", duration: this.duration })
		gsap.to(tl.position, { delay: this.duration, y: 14, ease: "expo.inOut", duration: this.duration });

		// animate
		gsap.to(texture.offset, { delay: 0, x: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(texture.offset, { delay: 0, delay: this.duration, x: 0, y: 1, ease: "expo.inOut", duration: 1 });


		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, {
			y: 250, delay: this.duration, ease: "expo.inOut", duration: 1, onComplete: function () {
				//self.isAnimating = false;
				self.parent.remove(tl);
				self.parent.remove(plane1);
			}
		});
	}

	destroy() {

	}

}

export default AnimationMask; // Don’t forget to use export default!