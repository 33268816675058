import { BaseCreative } from "./base-creative.js";
import * as THREE from "three";



export class VerticalHalf extends BaseCreative {
  constructor({ imagePosition, isHorizontalText, scaleFactor = 0, isOccasion = false, ...props }) {
    super(props);
    const isTextureAtRight = (imagePosition || 'RIGHT').toUpperCase() === 'RIGHT';
    this.pos = {
      word: isTextureAtRight ? -2 : 2,
      texture: isTextureAtRight ? 2 : -2,
      textureStart: isTextureAtRight ? 5 : -5,
      textureEnd: isTextureAtRight ? 20 : -20,
      isHorizontalText,
      scaleFactor,
      isOccasion
    };
  }
  addTexture = () => {
    const [width, height] = this.pos.isOccasion ? [1, 1] : [1, 1]
    const [start, end] = [{ x: this.pos.texture, delay: 0 }, { x: this.pos.textureEnd, delay: this.duration }]
    const mesh = this.addMesh(null, width, height, 2, { map: this.loadAsset().element });
    mesh.setPosition(this.pos.textureStart, 0, 0, start, end);
    if (this.pos.scaleFactor) {
      if (!this.pos.isOccasion) {
        mesh.setScale(this.pos.scaleFactor, this.pos.scaleFactor, this.pos.scaleFactor);
      } else {
        mesh.setScale(this.pos.scaleFactor, this.pos.scaleFactor, this.pos.scaleFactor);
      }
    }
  }

  addKeyword = () => {
    const wordContainer = this.addGroup();
    if (this.pos.isHorizontalText) {
      const x = -20;
      wordContainer.setPosition(x, 0, 1, {
        delay: 0,
        x: 0,
      }, {
        delay: this.duration,
        x
      });
    }

    this.dummyMapper(2, i => {
      const c = this[this.textColour[0]];

      const textLine = this.addTextLine(2, 2, 400, c, undefined, this.font[this.fontName[i]], true, { parentEl: wordContainer.element });
      const angle = this.pos.isHorizontalText ? 0 : Math.PI / 2;
      textLine.setRotation(0, 0, angle);

      // getting bounding box to measure text width
      var box = new THREE.Box3().setFromObject( textLine.element );

       // calculating text width
      var textWidth = box.max.y - box.min.y;

      const isFirst = i === 0;
      let x = this.pos.word;
      let y = isFirst ? -24 : 24;
      let yIn =  isFirst ? 1-textWidth/2 : 2+textWidth/2; // setting end position for vertical text based on text width to avoid overlapping
      let yOut = y;

      if (this.pos.isHorizontalText) {


        // calculating text width
        textWidth = box.max.x - box.min.x;

        var scale = textWidth/20;
        textLine.setScale(scale,scale,scale);

        y = isFirst ? 6 : -6;
        yIn = y;
        yOut = y;
        x = 1.75;
      }

      textLine.setPosition(
        x,
        y,
        0,
        {
          delay: 0,
          y: yIn,
        },
        {
          delay: this.duration,
          y: yOut,
        });
    });
  }

  start() {
    this.addBackground({ sameBackground: true });
    this.addTexture();
    this.addKeyword();
  }
}
