import axios from "axios";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
import { Pair, UnPair } from "../../store/actions/pair";
import getAuthToken from "../../utils/pair/get-auth";
import "./pair.css";

const PairPage = () => {
  console.log("localstorage is, ", window.localStorage.getItem("authurData"));

  const history = useHistory();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.order, shallowEqual);

  console.log("STATE IS data:", data);

  async function confirmPairing(product) {
    const orderNumber = data?.data?.OrderNumber;
    const request = {
      OrderNumber: orderNumber,
      MessageToken: product.messageToken,
      QrCode: product.qrCode,
    };

    dispatch(Pair(request));
    //history.push("/pair") //#/pair
  }

  async function handleUnpairBtnClick(product) {
    const orderNumber = data?.data?.OrderNumber;
    const request = {
      OrderNumber: orderNumber,
      MessageToken: product.messageToken,
      QrCode: product.qrCode,
    };

    dispatch(UnPair(request));
    //history.push("/pair")
  }

  return (
    <section className="center-content section-pair">
      <div className="container sm:w-96 mx-auto">
        <Header />
        <div className="bg-white">
          <div className="px-6 py-8">
            <p className="text-3xl text-black">Select a product to pair</p>
            <p className="text-sm mb-1 mt-2">
              QR codes are blank by default. They are not associated with any
              bottle. The process of pairing associates a QR code with the
              bottle that has been ordered.
            </p>
            <label className="block text-sm font-bold text-black  mb-6">
                Order number: <span className="font-normal">{data?.data?.OrderNumber}</span>
              </label>

              {data?.data?.Skus?.map((product) => {
                return (
                  <div key={product.productContentId}>
                  <div className="mt-8 bordered shadow-md">
                    <div className="flex justify-between rounded-md border-gray-400 border py-5 pl-6 pr-3 shadow-md mt-4">
                      <div className="full-width flex-wrap">
                        {product.isPaired && (
                          <div className="full-width flex-header">
                            <div className="px-6 py-3 font-bold text-sm inline-block mt-6 btn-green">&#x2713; Pairing confirmed</div>
                            <button
                                onClick={() => handleUnpairBtnClick(product)}
                                className="px-6 py-3 font-bold text-sm inline-block mt-6 btn-transparent"
                              >
                                Unpair
                              </button>
                          </div>
                        )}
                        <div className="flex-left">
                          <p className="font-bold text-sm mb-1">
                            Brand: <span className="font-normal">{product.brand}</span>
                          </p>
                          <p className="font-bold text-sm">
                            SKU: <span className="font-normal">{product.name}</span>
                          </p>
                        </div>
                        <div className="flex-right">
                          <img
                            className="w-16"
                            src={product.image}
                            alt={product.name}
                          />
                        </div>
                        {!product.isPaired && (
                          <button className="btn-bg rounded-md px-6 py-3 font-bold text-sm inline-block mt-6 confirm-btn" onClick={() => confirmPairing(product)}>
                            Confirm Pairing
                          </button>
                        )}
                      </div>


                    </div>
                  </div>
                  {product.isPaired && (
                    <button onClick={() => history.push("/pair/welcome")}
                    className="btn-bg rounded-md px-6 py-3 font-bold text-sm inline-block mt-6">
                      Done
                    </button>
                  )}
                  </div>
                );
              })}

              {/*
              <button
                onClick={() => history.push("pair")}
                className="btn-bg rounded-md px-6 py-3 font-bold text-sm inline-block mt-6"
              >
                Finish
              </button>
            */}
            
            <Footer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PairPage;
