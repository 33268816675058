import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import WalkingMan from "../common/WalkingMan.js"
import TextLine from "../common/textLine.js"

export class AnimationWalkingMan extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

  	const positionVector3 = new THREE.Vector3( -75, -10, -150 );
    new WalkingMan(this.parent, this.colors[2], this.duration, positionVector3);

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 5; i++) {
      const word = new TextLine(this.keyword.toUpperCase(), 1.5, 1.5, 400, this.colors[2], undefined, "JohnnieWalkerWeb-Headline");
      wordContainer.add(word);
      word.position.set(2+i*-0.5,-1.3-i*1.1,0);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0.05*i, y:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration+0.05*i, y:-1, ease: "expo.inOut", duration:this.duration})
    }
     const self = this;

     gsap.to(wordContainer.position,{delay:this.duration+0.3, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(wordContainer);
     }})

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationWalkingMan; // Don’t forget to use export default!