const KEY = "authurData";

export function setAuthToken(token = null) {
  localStorage.setItem(KEY, token);
}

export default function getAuthToken() {
  let locale = localStorage.getItem(KEY);
  if (!locale) {
    locale = null;
  }
  return locale;
}
