import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export var allowedTextCultures = ["en-GB", "en-US", "it-IT", "es-ES"];
var useAllowTextJourney = function () {
    var transcribeCulture = useSelector(function (state) { var _a, _b; return (_b = (_a = state === null || state === void 0 ? void 0 : state.offTradeReducer) === null || _a === void 0 ? void 0 : _a.offTradeTranslationData) === null || _b === void 0 ? void 0 : _b.transcribeCulture; });
    var _a = useState(false), allowTextJourney = _a[0], setAllowTextJourney = _a[1];
    useEffect(function () {
        setAllowTextJourney(allowedTextCultures.includes(transcribeCulture));
    }, [transcribeCulture]);
    return allowTextJourney;
};
export default useAllowTextJourney;
