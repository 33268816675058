import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import JWStripe from "../common/JWStripe.js"
import TextLine from "../common/textLine.js"

export class AnimationStripe_1 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 2; i++) {
      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[3], undefined, "JWCapsSerif-Book", 30);
      wordContainer.add(word);
      word.position.set(-3.5+i*7.2,-5+i*10,-2);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.x = 1;
      gsap.to(word.material.map.offset,{x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration, x:-1, ease: "expo.inOut", duration:this.duration})
    }

    const positionVector3 = new THREE.Vector3(0, -150, 0 );
    new JWStripe(this.parent, this.colors[3], this.duration, positionVector3); 

    const self = this;
    gsap.to(wordContainer.position,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(wordContainer);
    }});

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationStripe_1; // Don’t forget to use export default!