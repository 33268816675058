import { Link } from "react-router-dom";
import { useTranslation } from "Contexts/translation-context";
import { AnimatedContainer } from "../framer"
import { Heading } from "../heading";

export const PageNotFound = () => {
  const { t } = useTranslation();
  return (
    <AnimatedContainer className="container">
      <h1 className="multi-size">
        <Heading keyName="PAGE_NOT_FOUND" />
      </h1>
      <Link
        to="/author/welcome"
        className="underlined underlined--light"
      >
        {t("BACK_TO_HOME")}
      </Link>
    </AnimatedContainer>
  )
}