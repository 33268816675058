import { useState } from "react";
import { useLocation } from "react-router-dom";

export const useQrCode = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const [qrCode] = useState(query.get("qrCode"));
  const [journey] = useState(query.get("journey"));
  const [retailerId] = useState(query.get("retailerId"));
  const [isConsumerOffTrade] = useState(journey !== null);

  return {
    qrCode,
    retailerId,
    isOffTrade: isConsumerOffTrade,
  };
};

export const useOnlyQrCode = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  let qrCode = query.get("qrCode");
  if (!qrCode) qrCode = query.get("qrcode");

  return {
    qrCode: qrCode,
  };
};
