import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"
import TaliskerLogo from "../common/TaliskerLogo.js"
import TaliskerSea from "../common/TaliskerSea.js"

export class AnimationBrandTalisker_1 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	var self = this;

	// create plane  2
	const geometry2 = new THREE.PlaneGeometry(200 , 400, 2 );
	const material2 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
	const plane2 = new THREE.Mesh( geometry2, material2 );
	this.parent.add( plane2 );

	// set plane initial state
	plane2.position.set(0, -300,-200);
	plane2.scale.set(1,0,1);


	const logoContainer = new THREE.Group();
	this.parent.add(logoContainer);

	logoContainer.position.set(0,-100,0);

	for (var i = 0; i < 8; i++) {

		var positionVector3 = new THREE.Vector3(0, i*3, -2);

		 if (i % 2 === 0) { 
			const logo = new TaliskerLogo(logoContainer, this.colors[0], this.duration, positionVector3);
      	}else{
      		const sea = new TaliskerSea(logoContainer, this.colors[0], this.duration, positionVector3);
      	}
		
		//gsap.to(word.material.map.offset,{delay:this.duration+0.05*i, y:-1, ease: "expo.inOut", duration:this.duration})
	}

	gsap.to(logoContainer.position,{y:-10, ease: "expo.inOut", duration:this.duration});
	gsap.to(logoContainer.position,{y:100, ease: "expo.inOut", duration:this.duration, delay:this.duration})


	const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 6; i++) {

      var xPos = -2;
      var yPos = -3.5+i*4;
      if(i>2){
        xPos = 2;
        yPos = -3.5+(i-3)*4;
      }

      var c = this.colors[0];
      if(i == 1 || i == 4){
        c = this.colors[2];
      }

    
      const word = new TextLine(this.keyword.toUpperCase(), 0.8, 0.8, 400, c, undefined, 'DIN Condensed W05', -20);
      wordContainer.add(word);
      word.position.set(xPos,yPos, 3);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});
    }
   

	// animate
	gsap.to(plane2.scale,{y:1, ease: "expo.inOut", duration:this.duration})
	gsap.to(plane2.position, {y: 0, delay:0, ease: "expo.inOut", duration:this.duration});
	gsap.to(plane2.position, {y: 400, delay:this.duration, ease: "expo.inOut", duration:this.duration, onComplete:function(){
		//self.isAnimating = false;
		self.parent.remove(wordContainer);
		self.parent.remove(logoContainer);
		self.parent.remove(plane2);
	
	}});

  }

  destroy(){

  }
   
}

export default AnimationBrandTalisker_1; // Don’t forget to use export default!