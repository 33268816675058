import React, { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';

import {
  useHistory,
  withRouter
} from 'react-router-dom';

import { Button } from 'antd';
import { Footer } from 'Components/Layout';

import './MultiProducts.scss';
import { ReactComponent as Bottle } from 'Assets/img/miab-bottle.svg';
import { ReactComponent as ArrowDown } from 'Assets/img/arrow-down.svg';
import { ReactComponent as MiabLogo } from 'Assets/img/miab-logo.svg';
import { ReactComponent as ArrowRight } from 'Assets/img/arrow-right.svg';

import * as actions from 'Store/actions/index';
import { fetchConsumer } from "Store/reducers/consumer";
import { useAuthorable } from 'Hooks/useAuthorable';
import { getImageUrl } from 'Utils/common';
import { Heading } from 'Components/heading';
import { useTranslation } from 'Contexts/translation-context';
import { AnimatedContainer } from 'Components/framer';
import { AnimatePresence } from "framer-motion";

var converter = require('number-to-words');

export function MultiProducts() {
  const { t } = useTranslation();
  const { redirect } = useAuthorable(true);
  const dispatch = useDispatch();
  let selectedBottles = useSelector(state => state.common.selectedBottles);
  const history = useHistory();
  const messagesData = useSelector(
    state => state.messagesReducer.messagesData
  );
  useEffect(() => {
    if (!messagesData || messagesData.length === 0) {
      redirect();
      return;
    }
    messagesData.forEach(({ authoring_complete, id }) => {
      if (authoring_complete) {
        selectedBottles.push(id);

        dispatch(actions.setSelectedBottles(selectedBottles));
      }
    });
  }, [messagesData])
  const [nextBottle, setNextBottle] = useState(converter.toWordsOrdinal(selectedBottles.length + 1).toUpperCase());
  useEffect(() => {
    const nxt = ((selectedBottles.length + 1) === messagesData.length) ? "LAST" : converter.toWordsOrdinal(selectedBottles.length + 1).toUpperCase();
    setNextBottle(nxt);
  }, [selectedBottles.length])
  const isNextBottle = useState(messagesData.length > selectedBottles.length);
  const [isCreatePage, setIsCreatePage] = useState(true);

  const onNextBottle = () => {
    setIsCreatePage(false)
  }

  const onSelectBottle = (bottleIndex) => {
    selectedBottles.push(bottleIndex);
    dispatch(actions.setSelectedBottles(selectedBottles));
    dispatch(actions.setBottleId(bottleIndex));
    const messageD = messagesData.find(md => md.id === bottleIndex)
    dispatch(fetchConsumer(messageD.consumer_id));
    history.push('/author/steps/gift-for')
  }

  const products = messagesData.map((product, index) => {
    const isCompleted = selectedBottles.includes(product.id);
    return <div className="-align-center" key={index}>
      <div className={`product-image 
        ${isCompleted ? 'product-image--complete' : ''}`}
      >
        <img
          src={product.product_image
            ? getImageUrl(product.product_image)
            : "https://via.placeholder.com/300"
          }
          alt={t("PLACEHOLDER")}
        />
      </div>
      <p>{product.product_name}</p>
      <Button
        onClick={() => onSelectBottle(product.id)}
        type="primary"
        shape="round"
        disabled={isCompleted}
      >
        {t("SELECT_THIS_BOTTLE")}
        <ArrowDown />
      </Button>
    </div>
  })

  return (<AnimatePresence exitBeforeEnter>
    {isCreatePage && <AnimatedContainer key="without-product" className="MultiProduct page-wrap page-wrap--light sway-wrap">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar side-bar--light">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container">
            <h1 className="multi-size -half-margin">
              <MiabLogo className="miab-logo" />
              <Heading keyName={nextBottle === "FIRST" ? "LETS_GET_STARTED" : "ON_ITS_WAY"} />
            </h1>
            <div className="sway-button">
              <Button
                type="primary"
                shape="round"
                onClick={onNextBottle}
                disabled={!isNextBottle}
              >
                {t(nextBottle) || t("CREATE_YOUR_NEXT_MESSAGE")}
                <ArrowRight />
              </Button>
            </div>
          </div>
        </main>
      </section>
      {/* <Footer logos={"show"} /> */}
    </AnimatedContainer>
    }
    {!isCreatePage && <AnimatedContainer key="with-product" className="MultiProduct page-wrap page-wrap--light">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container">
            <h1 className="multi-size">
              <Heading keyName="SELECT_A_BOTTLE" />
            </h1>
            <div className={`product-grid 
                ${messagesData.length === 2 ? 'product-grid--offset' : ''}`}
            >
              {products}
            </div>
          </div>
          {/* <Footer /> */}
        </main>
      </section>
    </AnimatedContainer>
    }
  </AnimatePresence>
  )
}

export default withRouter(MultiProducts)