import { BaseCreative } from "./base-creative.js";
import * as THREE from "three";


export class Photography extends BaseCreative {
  constructor({ textProps, sameBackground = false, scaleFactor = null, ...props }) {
    super(props);
    this.textPos = {
      doubleText: true,
      isVertical: false,
      coord: [0, -7],
      ...textProps
    };

    this.imagePosition = {
      [props.texture.imagePosition]: true,
      sameBackground,
      scaleFactor
    };
  }

  addTexture() {
    const renderTexture = (...args) => {
      const texture = this.addMesh(null, ...args, { map: this.loadAsset().element });
      if (this.imagePosition.scaleFactor) {
        texture.setScale(this.imagePosition.scaleFactor, this.imagePosition.scaleFactor, this.imagePosition.scaleFactor)
      }
      return {
        setPosition: y => {
          texture.setPosition(
            0,
            -250,
            y,
            {
              delay: 0,
              y: 0
            }, {
            y: 250,
            delay: this.duration
          });
        }
      }
    }
    if (this.imagePosition.CENTER) {
      renderTexture(this.imageWidth, this.imageHeight, 2).setPosition(-90);
    }
    else if (this.imagePosition.FULLSCREEN) {
      renderTexture(75, 133, 2).setPosition(-70);
    }

  }

  addKeywordMultiple = () => {

    this.addKeywordVertical();

    if ((!this.imagePosition.TOPHALF && !this.imagePosition.BOTTOMHALF) || this.textPos.isVertical) {
      return;
    }
    const getPosY = (base) => {
      const y = 8 - base * 3;

      if (this.imagePosition.TOPHALF) {
        return -y;
      }

      return y;
    }

    let font = this.getFontFamily(0);
    let color = this.getTextColor(0);
    if (this.imagePosition.TOPHALF) {
      font = this.getFontFamily(1);
      color = this.getTextColor(1);
    }
    this.dummyMapper(this.textPos.lines, i => {

      const word = this.addTextLine(3.5, 1, 100, color, undefined, font, true);

      // getting bounding box to measure text width
      var box = new THREE.Box3().setFromObject( word.element );

       // calculating text width
      const textWidth = box.max.x - box.min.x;

      var scale = 10/textWidth;
      //console.log(textWidth);

      // german text 2
      // congratulations
      word.setScale(scale,scale,scale);

      word.setPosition(
        -18,
        getPosY(i)*scale,
        -6,
        {
          delay: 0.1 * i,
          x: 0
        },
        {
          delay: this.duration - (0.1 * (this.textPos.lines - i)),
          y: 15,
        });
    });
  }

  getTextColor = (index) => {
    return this[this.textColour[this.imagePosition.sameBackground ? 0 : index]];
  }

  getFontFamily = (index) => {
    return this.font[this.fontName[index]]
  }

  addKeywordVertical = () => {
    if (!this.textPos.isVertical) {
      return;
    }
    const wordContainer = this.addGroup();

    wordContainer.setPosition(0, -1.5, 1, {}, {
      delay: this.duration,
      y: 100
    });

    this.dummyMapper(8, i => {
      const word = this.addTextLine(2, 2, 500, this.getTextColor(0), undefined, this.getFontFamily(0), { parentEl: wordContainer.element });
      word.setRotation(0, 0, Math.PI / 2);
      word.setPosition(
        3.2 - i * 1.5,
        -2.5,
        -1, {},
        {
          delay: this.duration,
          y: Math.max(100 * i, 50)
        });
      word.setMaterialOffset({
        y: 1
      }, {
        delay: 0,
        y: 0,
      });
    });
  }

  addKeyword = () => {
    if (!this.imagePosition.CENTER) {
      return;
    }
    const negateIt = (i) => {
      if (!i) return i;
      return (-1 * i);
    }
    this.dummyMapper(2, j => {

      let [x, y, xInOut = 50] = this.textPos.coord;

      if (j === 0 && this.textPos.doubleText) {
        x = negateIt(x);
        y = negateIt(y);
      }

      const textLine = this.addTextLine(1.5, 1.8, 400, this.getTextColor(j), undefined, this.getFontFamily(j));

            // getting bounding box to measure text width
      var box = new THREE.Box3().setFromObject( textLine.element );

       // calculating text width
      const textWidth = box.max.x - box.min.x;

      var scale = 5/textWidth;
      
      textLine.setScale(scale,scale,1);

      textLine.setPosition(-xInOut, y, 0, {
        delay: 0,
        x
      }, {
        delay: this.duration,
        x: xInOut
      });
    });
  }


  start() {
    this.addBackground(this.imagePosition);
    this.addTexture();
    this.addKeywordMultiple();
    this.addKeyword();
  }
}