import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useHistory, withRouter } from "react-router-dom";

import { Button } from "antd";
import { fetchConsumer } from "Store/reducers/consumer";

import "./Steps.scss";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { OrderToken } from "Utils/web-storage";
import { loadMessages } from "Services/messagesService";
import { useAuthorable } from "Hooks/useAuthorable";
import { useTranslation } from "Contexts/translation-context";
import { Heading } from "Components/heading";

const mainSteps = (slug, t) => [
  {
    title: "01",
    description: t("WHOS_IT_FOR"),
  },
  {
    title: "02",
    description: t("NAME_THE_OCCASSION"),
  },
  {
    title: "03",
    description: slug === 'audio' ? t("RECORD_YOUR_MESSAGE") : t("WRITE_YOUR_MESSAGE"),
  },
  {
    title: "04",
    description: t("ADD_YOUR_PHOTO"),
  },
  {
    title: "05",
    description: t("PREVIEW_SUMMARY"),
  },
];

export function Step() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [letsStart, setLetsStart] = useState(false);
  const messagesData = useSelector(
    (state) => state.messagesReducer.messagesData
  );
  useAuthorable();
  
  const slug = useSelector(state => state.common?.slug)

  const handleNavigation = () => {
    if (messagesData.length === 1) {
      // getConsumer(messagesData[0].consumer_id) 
      //  In this case, we got the following issues so we can't call it.
      // Uncaught Error: Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
      // 1. You might have mismatching versions of React and the renderer (such as React DOM)
      // 2. You might be breaking the Rules of Hooks
      // 3. You might have more than one copy of React in the same app
      // See https://reactjs.org/link/invalid-hook-call for tips about how to debug and fix this problem.
      dispatch(fetchConsumer(messagesData[0].consumer_id));
      history.push("/author/steps/gift-for");
    }
    else {
      history.push("/author/multi-products");
    }
  }

  const handleOnClick = async () => {
    if (messagesData.length === 0) {
      const orderToken = OrderToken().value;
      await dispatch(loadMessages(orderToken));
    };
    setLetsStart(true);
  };

  useEffect(() => {
    if (letsStart && messagesData.length > 0) {
      handleNavigation()
      setLetsStart(false);
    }
  }, [messagesData, letsStart]);

  const mainStepItems = mainSteps(slug, t).map((step, index) => (
    <div key={index} className="illustrative-steps__step">
      <div className="font-tight-small">{step.title}</div>
      <div>{step.description}</div>
    </div>
  ));
  
  return (
    <div className="page-wrap">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container container--with-steps">
            <div className="illustrative-steps -hidden-mobile">
              {mainStepItems}
            </div>
            <div>
              <h1 className="multi-size">
                <Heading keyName="FIVE_EASY_STEPS" />
              </h1>
              <div className="illustrative-steps -hidden-desktop">
                {mainStepItems}
              </div>
              <Button type="primary" shape="round" onClick={handleOnClick}>
                {t("LETS_GET_STARTED")}
                <ArrowDown />
              </Button>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}

export default withRouter(Step);
