var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _this = this;
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
var TextFitter = function (_a) {
    var _b = _a.ignoreWindow, ignoreWindow = _b === void 0 ? false : _b, _c = _a.ignoreChild, ignoreChild = _c === void 0 ? false : _c, _d = _a.allowGrow, allowGrow = _d === void 0 ? false : _d, _e = _a.allowShrink, allowShrink = _e === void 0 ? true : _e, _f = _a.matchParent, matchParent = _f === void 0 ? false : _f, _g = _a.debounceTimer, debounceTimer = _g === void 0 ? 100 : _g, initTimer = _a.initTimer, children = _a.children, style = _a.style;
    var mounted = useRef(null);
    var wrapper = useRef(null);
    var _h = useState(1), scale = _h[0], setScale = _h[1];
    var debounce = function (callback, wait) {
        var timeoutId;
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            window.clearTimeout(timeoutId);
            timeoutId = window.setTimeout(function () {
                callback.apply(null, args);
            }, wait);
        };
    };
    useEffect(function () {
        if (mounted.current)
            return;
        mounted.current = _this;
        var calculateFittingRatio = debounce(function () {
            var parentWidth = (matchParent ? wrapper.current.parentElement.offsetWidth : wrapper.current.offsetWidth);
            var contentWidth = (matchParent ? wrapper.current.offsetWidth : wrapper.current.scrollWidth);
            var scale = parentWidth / contentWidth;
            if (!allowShrink)
                scale = Math.max(scale, 1);
            if (!allowGrow)
                scale = Math.min(scale, 1);
            setScale(scale);
        }, debounceTimer);
        if (!ignoreWindow) {
            // Watch for window resize events.
            window.addEventListener('resize', calculateFittingRatio);
        }
        var observer;
        if (!ignoreChild) {
            // Watch for children changes
            observer = new MutationObserver(calculateFittingRatio);
            observer.observe(wrapper.current, { attributes: false, childList: true, subtree: true, characterData: true });
        }
        if (initTimer)
            setTimeout(function () { return calculateFittingRatio(); }, initTimer);
        return function () {
            if (!ignoreWindow)
                window.removeEventListener('resize', calculateFittingRatio);
            if (!ignoreChild)
                observer.disconnect();
        };
    }, [ignoreChild, ignoreWindow]);
    return _jsx("span", __assign({ ref: wrapper, style: __assign(__assign({ transformOrigin: '0 0' }, style), { transform: "scale(".concat(scale, ")") }) }, { children: children }));
};
export default TextFitter;
