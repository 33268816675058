import React from "react";
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { BrowserTracing  } from '@sentry/tracing';
import { CaptureConsole  } from '@sentry/integrations';
import { BrowserRouter as Router } from "react-router-dom";
import SideBar from "Components/Layout/SideBar";
import { Layout } from "antd";
import ROUTES, { RenderRoutes } from "Router/routes";
import { TranslationProvider } from "Contexts/translation-context";
import { Footer } from "Components/Layout";
const { Content } = Layout;

const history = createBrowserHistory();

Sentry.init({
  dsn: "https://e9e6c82812b1470d99d9937c8d07c9e9@o4503925103656960.ingest.sentry.io/4503925140094976",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
    new CaptureConsole({
      levels: ['error']
    })
  ],
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production"
});

function App() {
  return (
    <div className="App">
      <Router basename="/#" history={history}>
        <TranslationProvider>
          <Layout>
            <SideBar />
            <Layout>
              <Content>
                <RenderRoutes routes={ROUTES} />
              </Content>
            </Layout>
          </Layout>
        </TranslationProvider>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
