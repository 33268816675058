import React from "react";

const Footer = () => {
  return (
    <div className="pt-60 flex justify-between">
      <div>
        <a className="text-black underline font-bold text-sm" href="#">
          Need help?
        </a>
      </div>
      <div>
        <a className="text-black underline font-bold text-sm" href="#">
          Terms of use
        </a>
      </div>
    </div>
  );
};

export default Footer;
