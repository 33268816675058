import * as THREE from 'three';
import * as three from './three-helper';
import FontFaceObserver from 'fontfaceobserver';
import { legacyPack } from '../legacy-pack';
import { CreativePack, Keyword } from './creative-pack';
import { config } from './configuration';
import { getSentences } from '../creative-utils';

class ThreeFramework {
  constructor() {
    this.animationFrame = null;
    this.captionFrames = [];
    this.state = {
      hasAudioMessage: false,
      audioDuration: 0,
      words: [],
      experienceBeingPlayed: false,
      backgroundMusicLoaded: false,
      backgroundMusic: null,
      userAudio: null,
    }
  }
  setState(newState, setupExp = true) {
    this.state = {
      ...this.state,
      ...newState
    };
    if (setupExp) {
      this.setupExperience();
    }
  }

  setAudioDuration() {
    this.props.words.filter((x) => !!x.endTime).reverse().some(({ endTime }) => {
      if (endTime) {
        this.state.audioDuration = Math.max(Number(endTime), config.MINIMUM_PLAY_TIME);
        return true;
      }
      return false;
    });

    if (this.userAudio) {
      this.state.audioDuration = Math.max(this.state.audioDuration, this.state.userAudio.buffer.duration);
    }
  }

  initCreative() {
    const {
      primaryColour,
      secondaryColour,
      tertiaryColour,
      font,
      occasionText: keyword,
      texture,
      creativity
    } = this.props;
    const options = {
      scene: this.scene,
      primaryColour,
      secondaryColour,
      tertiaryColour,
      font,
      duration: config.CREATIVE_PLAY_TIME,
      keyword,
      texture,
      creativity,
      isTextOnly: !this.state.hasAudioMessage
    };
    this.creativePack = legacyPack(options) || CreativePack(options);
  }

  removeCanvas = () => {
    const canvas = document.querySelector(`#${this.state.canvasIdentifier}`);
    if (canvas) {
      canvas.remove();
    }
  }

  init(props) {
    this.props = props;
    this.setAudioDuration();
    if (props.audioMessage?.slice(-4) === '.mp3') {
      this.setState({ hasAudioMessage: true }, false);
    }
    this.state.canvasIdentifier = this.props.uid || "DiageoCreativeCanvas";

    this.removeCanvas();
    this.scene = this.createScene();
    this.renderer = this.createRenderer();
    this.renderer.domElement.id = this.state.canvasIdentifier;
    this.camera = this.createCamera();
    this.props.mount.current.appendChild(this.renderer.domElement);
    three.createPointLights(this);
    this.checkIfFontFaceLoaded();
    this.loadBackgroundMusic();

    this.initCreative();
    this.start();
  }

  createScene() {
    if (this.scene) {
      this.scene = null;
    }
    return three.createScene();
  }

  createRenderer() {
    if (this.renderer) {
      this.renderer = null;
    }

    return three.createRenderer(this);
  }

  createCamera() {
    if (this.camera) {
      this.camera = null;
    }
    return three.createCamera(this);
  }

  createPointLights() {
    const initialValue = {
      color: 0xffffff,
      intensity: 1,
      distance: 0,
      x: 0,
      y: 200,
      z: 0
    };
    [{
      ...initialValue,
      color: 0x304ffe,
    }, {
      ...initialValue,
      x: 100,
      z: 100
    }, {
      ...initialValue,
      x: -100,
      y: -200,
      z: -100
    }].forEach(({ color, intensity, distance, x, y, z }) => {
      const pointLightCenter = new THREE.PointLight(color, intensity, distance);
      pointLightCenter.position.set(x, y, z);
      this.scene.add(pointLightCenter);
    });
  }

  loadBackgroundMusic() {
    if (!this.state.backgroundMusicLoaded) {
      this.loadAudio({
        files: this.props.backgroundMusic,
        loop: true,
        volume: 1
      }, (backgroundMusic) => {
        this.setState({
          backgroundMusicLoaded: true,
          backgroundMusic
        });
      });
    }
  }

  loadAudio({ files = [], loop = false, volume = 5 }, onLoad = () => { }) {
    const audioListener = new THREE.AudioListener();
    this.camera.add(audioListener);
    const sounds = [];
    const fireOnload = (s) => {
      sounds.push(s);
      if (sounds.length === files.length) {
        onLoad(sounds);
      }
    }
    files.forEach(file => {
      const sound = new THREE.PositionalAudio(audioListener);
      const audioLoader = new THREE.AudioLoader();
      audioLoader.load(file, (buffer) => {
        sound.setBuffer(buffer);
        sound.hasPlaybackControl = true;
        sound.setBuffer(buffer);
        sound.setVolume(volume);
        sound.setRefDistance(1)
        fireOnload(sound);
      });
    })
  }

  checkIfFontFaceLoaded = () => {
    if (!this.state.fontsLoaded) {
      Promise.all([
        this.props.font.primaryFont,
        this.props.font.secondaryFont,
      ].map(fontFamilyName => new FontFaceObserver(fontFamilyName).load(null, 6000))).then(() => {
        this.state.fontsLoaded = true;
        this.setupExperience();
      }).catch((errors) => {
        // TODO - DEAL with errors
        this.state.fontsLoaded = true;
        //this.setupExperience();
        console.error(errors);
      });
    }
  };

  setupExperience = () => {

    if (!this.state.fontsLoaded) {
      this.checkIfFontFaceLoaded();
      return;
    };

    if (!this.state.backgroundMusicLoaded) {
      this.loadBackgroundMusic();
      return;
    }

    if (this.state.words.length === 0) {
      this.composeExperience();
      return;
    }

    this.playMessage();
  }

  composeExperience = () => {

    if (!this.state.hasAudioMessage) {
      this.composeTextOnlyExperience();
      return;
    }
    const sanitise = ({ startTime, endTime, ...rest }) => ({
      startTime: Number(startTime),
      endTime: Number(endTime),
      ...rest
    });
    const keywordsOnly = this.props.words.filter(({ keyword }) => keyword);
    this.state.words = keywordsOnly
      .map(word => sanitise(word))
      .sort((a, b) => {
        if (a.startTime > b.startTime) return 1;
        if (a.startTime < b.startTime) return -1;
        return 0;
      });

  };

  playMessage = () => {
    this.state.backgroundMusic[0].play();
    if (!this.state.hasAudioMessage) {
      this.animationStartTime = Date.now();
    } else {
      this.loadAudio({
        files: [this.props.audioMessage]
      }, (audioMessage) => {
        this.state.userAudio = audioMessage[0];
        this.setAudioDuration();

        this.state.userAudio.play();
        if (this.props.statusFeedback) {
          this.props.statusFeedback(true);
        }
      })
    }
  };

  composeTextOnlyExperience = () => {



    const captions = getSentences(this.props.textMessage);
    this.captionFrames = [];
    const interval = 1.5;

    for (var i = 0; i < captions.length; i += 2) {

      var obj = {
        startTime: i * interval,
        endTime: i * interval + 1,
        caption1: captions[i],
        caption2: captions[i + 1] || '',
      };
      this.captionFrames.push(obj);
    }

    this.animationStartTime = Date.now();
    this.sequenceCount = 0;

  };

  requestAnimation(timestamp) {
    if (this.time === null) {
      this.time = timestamp;
    }
    const elapsed = timestamp - this.time;
    let maxTime = 2000000000;
    if (!this.state.hasAudioMessage) {
      maxTime = 3000;
      this.animateTextOnlyExperience();
    } else {
      this.animateAudioExperience();
    }

    const ALLOWABLE_TIME_TO_FINISH = 2.5;

    if (((Date.now() - this.startedAt) / 1000) >= (this.state.audioDuration + ALLOWABLE_TIME_TO_FINISH) && this.state.hasAudioMessage) {
      this.destroy();
      return;
    }
    if (elapsed > maxTime) {
      this.time = timestamp;
      this.state.experienceBeingPlayed = false;
    }

    if (this.renderer && this.scene && this.camera) {
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.requestAnimation.bind(this));
    }
  }

  start() {
    this.time = null;
    this.startedAt = Date.now();
    if (!this.animationFrame) {
      this.animationFrame = requestAnimationFrame(this.requestAnimation.bind(this));
    }
  };

  destroy() {
    if (this.state.userAudio) {
      this.state.userAudio.stop();
      this.state.userAudio.listener.setMasterVolume(0);
    }

    this.time = null;
    this.renderer.clear();
    this.scene = null;
    this.camera = null;
    cancelAnimationFrame(this.animationFrame);
    this.props.onStop();
  };

  executeCreativity(duration, keyword) {
    if (this.state.backgroundMusicLoaded) {
      const backgroundIndex = Math.floor(Math.random() * this.state.backgroundMusic.length);
      this.state.backgroundMusic[backgroundIndex].play();
    }

    const Creativity = this.creativePack[this.sequenceCount];
    const withPadding = () => {
      let kw = keyword;
      while (kw.length <= 10) {
        kw = `${kw} `;
      }
      return { keyword: kw };
    }
    new Creativity({
      duration,
      ...withPadding(),
    }).start();

    setTimeout(() => {
      this.state.words = this.state.words.filter((x) => x.endTime > (new Date() - this.startedAt) / 1000);
      this.state.experienceBeingPlayed = false
    }, duration * 1000);

    // increment animation sequence
    this.sequenceCount++;

    if (this.sequenceCount >= this.creativePack.length) {
      this.sequenceCount = 0;
    }
  }

  animateAudioExperience = () => {
    const { words, experienceBeingPlayed } = this.state;
    if (words.length > 0 && !experienceBeingPlayed) {
      const { startTime, word, keyword, endTime } = words[0];
      // check if start time is null (punctuation)
      if (startTime === null) {
        // remove executed word from array
        this.state.words.shift();
      } else {
        this.state.experienceBeingPlayed = true;

        // play only if it's keyword
        const diff = Math.abs(startTime - (new Date() - this.startedAt) / 1000);
        const canPlayKeyword = Math.floor(diff) === 0 || (words.length === 1 && Math.floor(diff) === 1);

        if (keyword) {
          this.executeCreativity(config.CREATIVE_PLAY_TIME, canPlayKeyword ? word : this.props.occasionText);
        }
        // remove executed word from array
        words.shift();
      }
    } else if (words.length === 0 && ((new Date() - this.startedAt) / 1000) < 3) {
      if (typeof this.sequenceCount === 'undefined') {
        this.sequenceCount = 0;
      }
      this.composeExperience();
    }
  };

  animateTextOnlyExperience = () => {
    if (!this.state.experienceBeingPlayed && this.sequenceCount > -1) {

      //this.state.experienceBeingPlayed = true;

      const currentTime = Date.now() - this.animationStartTime;



      this.captionFrames.forEach(caption => {
        const startTime = parseFloat(caption.startTime);

        if (startTime <= currentTime / 1000) {
          new Keyword({
            scene: this.scene,
            ...this.props
          }).start(
            [
              caption.caption1,
              caption.caption2
            ],
            config.CREATIVE_PLAY_TIME
          );
          this.executeCreativity(config.CREATIVE_PLAY_TIME, ' ');

          this.captionFrames.shift();
          if (this.captionFrames <= 0) {
            this.destroy();
            this.sequenceCount = -1;
          }
        }
      });
    } else {
      if (this.sequenceCount === -1) {
        //this.composeTextOnlyExperience();
      }
    }
  };
}

export { ThreeFramework };
