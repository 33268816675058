import { useSelector } from "react-redux";
import { consumerReducerSelector } from "Store/reducers/consumer";

export const useConsumer = () => {
  const consumerData = useSelector(consumerReducerSelector('consumerData'));
  let giverName = consumerData?.gift_giver_name;
  let recipientName = consumerData?.gift_recipient_name;
  if(!recipientName || recipientName === 'n/a') {
    recipientName = null;
    giverName = null;
  }
  return {
    recipientName,
    giverName,
  }
}