import { handleActions } from 'redux-actions'
import { REQUEST_STATUS } from 'Axios/constants'
import { defineLoopActions, requestLoopHandlers } from 'Axios/state'
import { apiAction } from 'Axios/apiCall'
import { ADD_PROFILE_IMAGE, DELETE_PROFILE_IMAGE } from '../actions/actionTypes'

const initialState = {
  isLoading: false,
  addProfileImageState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  addProfileImageData: {},
  error: {}
}

export const {
  start: addProfileImage,
  success: addProfileImageSuccess,
  fail: addProfileImageFail
} = defineLoopActions(ADD_PROFILE_IMAGE)

export const fetchAddProfileImage = data => {
  const apiUrl = `/authoring/addProfileImage`

  return apiAction({
    url: apiUrl,
    method: 'POST',
    data: data,
    onStart: addProfileImage,
    onSuccess: addProfileImageSuccess,
    onFailure: addProfileImageFail,
    label: ADD_PROFILE_IMAGE
  })
}

export const {
  start: deleteProfileImage,
  success: deleteProfileImageSuccess,
  fail: deleteProfileImageFail
} = defineLoopActions(DELETE_PROFILE_IMAGE)
export const fetchDeleteProfileImage = data => {
  const apiUrl = `/authoring/deleteImage`

  return apiAction({
    url: apiUrl,
    method: 'POST',
    data: data,
    onStart: deleteProfileImage,
    onSuccess: deleteProfileImageSuccess,
    onFailure: deleteProfileImageFail,
    label: DELETE_PROFILE_IMAGE
  })
}
export const addProfileImageReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: ADD_PROFILE_IMAGE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          addProfileImageState: REQUEST_STATUS.SUCCESS,
          addProfileImageData: payload
        }
      },
      onFailure: (state) => {
        return {
          ...state,
          isLoading: false,
          addProfileImageState: REQUEST_STATUS.FAIL
        }
      }
    })
  },
  initialState
)

