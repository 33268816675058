import * as THREE from "three";
import {  GetAssetPath } from "Services/apiService";


export class OccasionSymbol {

  constructor(parent, color, duration, positionVector3, symbol) {


    var dimensions = {};
    switch (symbol) {
      case "gift":
        dimensions = { width: 9.52, height: 9.52 };
        break;

      case "baloon":
        dimensions = { width: 4.12, height: 10.08 };
        break;
      default: break;
    }

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(GetAssetPath(`occasion-${symbol}.png`));

    // create plane 
    const geometry = new THREE.PlaneGeometry(dimensions.width, dimensions.height, 2);
    const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y, positionVector3.z);

    return plane;

  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default OccasionSymbol; // Don’t forget to use export default!