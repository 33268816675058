import { BaseCreative } from "./base-creative";

export class Keyword extends BaseCreative {
  constructor(props) {
    super({
      ...props,
      texture: {},
    })

  }
  getArgs() {
    return [
      0.6,
      0.8,
      400,
      this.secondaryColour,
      this.primaryColour,
      this.font.primaryFont,
      0,
      false
    ];
  }
  start = (keywords, duration) => {
    this.duration = duration;
    keywords.forEach((keyword, i) => {
      this.keyword = keyword;
      const textLine = this.addTextLine(...this.getArgs());
      textLine.setPosition(0, -10, 0.1, {
        y: -3 - i,
        delay: 0
      }, {
        y: 10,
        delay: this.duration
      });
    });
  }
}
