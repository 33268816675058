const CREATIVE_PLAY_TIME = 1.75;
const MAXIMUM_PLAY_TIME = 30;
const MINIMUM_PLAY_TIME = 1;
const CREATIVE_SHUFFLE = true;

const TEST_ONLY = false;

export const POSITION = {
  CENTERED: 'CENTERED',
  CENTER: 'CENTER',
  TOPHALF: 'TOPHALF',
  BOTTOMHALF: 'BOTTOMHALF',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
}

export const TEXTURE = {
  LOGO: 'logo',
  REPEAT: 'repeat',
  SINGLE: 'single',
  OCCASION: 'occasion',
  PHOTOGRAPHY: 'photography',
}

const override = () => {
  const [, qs] = window.location.hash.split('?');
  let playtime;
  let disableShuffle = false;
  let only = false;
  if (qs) {
    qs.split("&").forEach(q => {
      const [key, value] = q.split("=");
      if (key === 'playtime' && !playtime) {
        playtime = value;
      }
      if (key === 'shuffle' && !disableShuffle) {
        disableShuffle = value === 'false' ? false : true;
      }
      if (key === 'only' && !only) {
        only = [
          TEXTURE.LOGO,
          TEXTURE.REPEAT,
          TEXTURE.SINGLE,
          TEXTURE.OCCASION,
          TEXTURE.PHOTOGRAPHY,
        ].includes(value.toLowerCase()) ? value.toLowerCase() : false;
      }
    });
  }

  return {
    MAXIMUM_PLAY_TIME: playtime || MAXIMUM_PLAY_TIME,
    ...(disableShuffle && { CREATIVE_SHUFFLE: false }),
    TEST_ONLY: TEST_ONLY || only,
  }
}

export const config = {
  CREATIVE_PLAY_TIME,
  MAXIMUM_PLAY_TIME,
  MINIMUM_PLAY_TIME,
  CREATIVE_SHUFFLE,
  TEST_ONLY,
  ...override(),
}
