export const getSentences = (text) => {
  // max character per caption
  const maxCharacters = 10;
  let sentence = '';
  const sentences = [];
  // loop through words
  text.split(' ').forEach((word, i, arr) => {
    sentence = `${sentence} ${word}`.trim();
    if (sentence.length >= maxCharacters || i === arr.length - 1) {
      //console.log("sentence", sentence);
      sentences.push(sentence);
      sentence = '';
    }
  });
  return sentences;
};