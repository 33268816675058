import * as actions from "./actionTypes";

export const setProductKey = (productKey) => {
  return {
    type: actions.SET_PRODUCT_KEY,
    payload: productKey,
  };
};

export const setJourney = (journey) => {
  return {
    type: actions.SET_JOURNEY,
    payload: journey,
  };
};

export const setRetailerId = (retailerId) => {
  return {
    type: actions.SET_RETAILER_ID,
    payload: retailerId,
  };
};

export const setCustomerEmail = (customerEmail) => {
  return {
    type: actions.SET_CUSTOMER_EMAIL,
    payload: customerEmail,
  };
};

export const setQrCode = (qrCode) => {
  return {
    type: actions.SET_QR_CODE,
    payload: qrCode,
  };
};

export const setBrandKey = (brandKey) => {
  return {
    type: actions.SET_BRAND_KEY,
    payload: brandKey,
  };
};

export const setSlug = (slug) => {
  return {
    type: actions.ROUTING_SET_SLUG,
    payload: slug,
  };
};

export const setBottleId = (bottleId) => {
  return {
    type: actions.SET_BOTTLE_ID,
    payload: bottleId,
  };
};

export const setSelectedBottles = (data) => {
  return {
    type: actions.SET_SELECTED_BOTTLES,
    payload: data,
  };
};

export const setOccasionData = (occasionData) => {
  return {
    type: actions.SET_OCCASION_DATA,
    payload: occasionData,
  };
};

export const setGiftData = (giftData) => {
  return {
    type: actions.SET_GIFT_DATA,
    payload: giftData,
  };
};

export const setOnlyWrittenMsg = (data) => {
  return {
    type: actions.SET_ONLY_WRITTEN_MESSAGE,
    payload: data,
  };
};

export const clearCommonData = (payload) => {
  return {
    type: actions.CLEAR_COMMON_DATA,
    payload,
  };
};

export const setCommonData = (payload) => {
  return {
    type: actions.SET_COMMON_DATA,
    payload,
  };
};
