import { GetApiDipatchType, INITIAL_STATE } from "../../utils/redux-helper";

export const SET_ORDER = "SET_ORDER";

export const FetchOrderType = GetApiDipatchType(SET_ORDER);

export const setOrder = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FetchOrderType.FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case FetchOrderType.SUCCESS:
    case FetchOrderType.FAILURE:
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };
    default:
      return state;
  }
};
