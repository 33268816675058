import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "Contexts/translation-context";

export const sideBarMenu = (slug, t) => ([
  {
    title: "01",
    description: t("WHOS_IT_FOR"),
    path: '/author/steps/gift-for',
  },
  {
    title: "02",
    description: t("NAME_THE_OCCASSION"),
    path: '/author/steps/occasion',
  },
  {
    title: "03",
    description: slug === 'audio' ? t("RECORD_YOUR_MESSAGE") : t("WRITE_YOUR_MESSAGE"),
    path: '/author/steps/record',
  },
  {
    title: "04",
    description: t("ADD_YOUR_PHOTO"),
    path: '/author/steps/image',
  },
  {
    title: "05",
    description: t("PREVIEW_SUMMARY"),
    path: '/author/steps/preview',
  }
])
export const useSideBar = () => {
  const { slug, giftData, occasionData, audioBlob, writtenTextMesssage } = useSelector(state => state.common, shallowEqual)
  const { t } = useTranslation();
  const menu = sideBarMenu(slug, t);
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(menu.findIndex(item => item.path === pathname));
  const [animate, setAnimate] = useState(false);
  const [mounted, setMounted] = useState(false);
  const history = useHistory();

  const isNavigatable = (step) => {
    if (step < current) {
      return true;
    }
    const gotoStep2 = giftData;
    const gotoStep3 = gotoStep2 && occasionData;
    const gotoStep4 = gotoStep3 && (slug === 'audio' ? audioBlob : writtenTextMesssage);
    switch (step.toString()) {
      case '1': return gotoStep2;
      case '2': return gotoStep3;
      case '3':
      case '4': return gotoStep4;
      default: return false;
    }

  }
  const onChange = index => {
    if (isNavigatable(index)) {
      history.push(menu[index].path);
    }
  };
  useEffect(() => {
    const cur = menu.findIndex(item => item.path === pathname);
    setMounted(true);
    setTimeout(() => {
      if (mounted){
        setCurrent(prev => {
          setAnimate(cur === 0 && prev < 0);
          return cur;
        });}
    }, 1000);

    return () => {
      setAnimate(false);
      setMounted(false);
    }
  }, [pathname]);
  return {
    onChange,
    slug,
    current,
    menu,
    animate,
    isNavigatable
  }
}