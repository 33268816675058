const isProd = process.env.NODE_ENV === "production";

export const buildUrl = (uri) => {
  if (isProd) return `/api${uri}`;
  return `/api${uri}`;
};

export const GetAssetPath = (asset, isAudio = false) => {
  return `${isAudio ? "audio/effects" : "images/animations"}/${asset}`;
};
