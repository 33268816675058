import { BaseCreative } from "./base-creative";

export class Reel extends BaseCreative {

  constructor({ isPlain = false, ...props }) {
    super(props);
    this.isPlain = isPlain;
    this.height = 6;
  }
  addTexture = () => {
    const POS_Y = -30;
    this.dummyMapper(3, i => {
      const [x, z] = [0, 0];
      const occasion = this.addMesh(null, this.imageWidth, this.imageHeight, 2, { map: this.loadAsset().element });
      occasion.setScale(2.2, 2.2, 2)
      occasion.setPosition(
        x,
        i === 0 ? POS_Y : i === 1 ? POS_Y * 2 : POS_Y * 3,
        z,
        { delay: 0.05, y: i === 0 ? -this.height : i === 1 ? 0 : this.height },
        { delay: this.duration, y: 999 }
      );
    });
  }
  addKeyword = () => {
    const pos = 0.9;
    const hieght = 1;
    this.dummyMapper(2, i => {
      this.dummyMapper(3, j => {
        const textLine = this.addTextLine(0.66, hieght, 300, this[this.textColour[0]], undefined, this.font[this.fontName[0]], 20);
        textLine.setRotation(0, 0, Math.PI / 2);
        textLine.setMaterialOffset({ y: 1 }, { delay: 0, y: 0 });
        textLine.setPosition(
          i === 0 ? -pos : pos+0.1,
          j === 0 ? -(pos * 5) : j === 1 ? 0 : pos * 5,
          this.height + hieght,
          null,
          {
            delay: this.duration,
            y: 10,
          });
      });
    });
  }
  start() {
    this.addBackground({ sameBackground: true });
    this.addTexture();
    if (!this.isPlain) {
      this.addKeyword();
    }
  }
}

