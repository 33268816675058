import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadMessage } from "Services/messagesService";
import { messageReducerSelector } from "Store/reducers/message";
import { getImageUrl } from "Utils/common";
import { QrCode } from "Utils/web-storage";

export const useMessage = () => {
  const messageData = useSelector(messageReducerSelector('messageData'));
  console.log("messageData", messageData)
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(messageData).length === 0) {
      const qrCode = QrCode().value;
      dispatch(loadMessage(qrCode));
    }
  }, [messageData]);
  const imgMatchMattern = /\.(jpe?g|png|gif|svg)$/gi;
  const profileImage = imgMatchMattern.test(messageData.profile_image) ? messageData.profile_image : null;
  return {
    occasionImage: getImageUrl(messageData.occasion_image, null),
    occasionName: messageData.occasion_name,
    productImage: getImageUrl(messageData.product_image, null),
    profileImage,
    productName: messageData.product_name,
    consumerId: messageData.consumer_id,
    messageToken: messageData.message_token,
    occasionId: messageData.occasion_id,
  }
}