import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { OFFTRADE_TRANSLATION } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  offTradeTranslationData: null,
  offTradeTranslationState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(OFFTRADE_TRANSLATION);

export const fetchOfftradeTranslations = (code) => {
  const data = { qrCode: code };
  return apiAction({
    url: "/translation/getTranslationsByQr",
    onStart,
    onSuccess,
    onFailure,
    label: OFFTRADE_TRANSLATION,
    data,
    method: "POST",
  });
};

export const offTradeReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: OFFTRADE_TRANSLATION,
      onSuccess: (state, payload) => {
        return {
          ...state,
          offTradeTranslationState: REQUEST_STATUS.SUCCESS,
          offTradeTranslationData: payload,
        };
      },
      stateField: "offTradeTranslationState",
    }),
  },
  initialState
);
