import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { tokenReducerSelector } from "Store/reducers/token";
import { translationReducerSelector } from "Store/reducers/translation";
import { useAuthorable } from "./useAuthorable";

export const useAuthoringComplete = () => {
  const messagesData = useSelector(
    (state) => state.messagesReducer.messagesData
  );
  
  const { redirect } = useAuthorable(true);
  const text = useSelector(translationReducerSelector, shallowEqual);
  const authToken = useSelector(tokenReducerSelector);
  const history = useHistory();
  const [isAuthoringCompleted, setIsAuthoringCompleted] = useState(false);
  
  useEffect(() => {
    if(!text && !authToken) {
      redirect();
    }
  }, [text, authToken]);

  useEffect(() => {
    if (!messagesData || messagesData.length === 0) {
      return;
    }
    if (messagesData.length === 1) {
      setIsAuthoringCompleted(messagesData[0].authoring_complete);
      return;
    }
    const pendingRecording = messagesData.find(({ authoring_complete }) => !authoring_complete);
    setIsAuthoringCompleted(!pendingRecording);
  }, [messagesData]);

  useEffect(() => {
    if (isAuthoringCompleted) {
      history.push('/author/complete')
    }
  }, [isAuthoringCompleted])

}