import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Brush from "../common/Brush.js"
import TextLine from "../common/textLine.js"


export class AnimationBrushSingleton_3 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

    const self = this;

    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const positionVector3 = new THREE.Vector3(-1, -1, 0 );
    var brush = new Brush(this.parent, positionVector3, 0, this.colors[0], this.duration);
    brush.rotation.set(0,0,-Math.PI/8);

    const positionVector3b = new THREE.Vector3(1, -5, 0 );
    var brush2 = new Brush(this.parent, positionVector3b, 0.1, this.colors[0], this.duration);
    brush2.rotation.set(0,0,0);

    const word = new TextLine(this.keyword, 1, 1, 400, this.colors[1], undefined, 'Mackay Regular');
    this.parent.add(word);

    word.position.set(0,0,3.1);
    word.rotation.set(0,0,Math.PI/2);
    word.material.map.offset.set(1,0);

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration});

    gsap.to(word.material.map.offset,{delay:0.1, x:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word.material.map.offset,{delay:this.duration+0.1, x:-1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(brush);
      self.parent.remove(brush2);
      self.parent.remove(word);
      self.parent.remove(plane1);
    }});

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationBrushSingleton_3; // Don’t forget to use export default!