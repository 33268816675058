import { AnimatePresence, motion } from "framer-motion";
import { memo } from "react";
import { useLocation } from "react-router-dom";
import framerConfig from '../framer-config.json';

export const AnimatedContainer = memo(({ children, className = "", animate = true, duration, ...props }) => {
  const transition = {
    duration: animate ? (duration || framerConfig.ANIMATION_DURATION_PAGE) : 0,
    ease: [0.43, 0.13, 0.23, 0.96],
    property: "all"
  };
  const variants = framerConfig.SLIDE_UP_VARIENTS;
  const location = useLocation();
  return (
    <motion.div
      className={className}
      custom={location.key}
      key={location.key}
      variants={variants}
      transition={transition}
      initial="enter"
      animate="center"
      exit="exit">
      {children}
    </motion.div>
  );
});

export const AnimatedContainerWithPresence = memo(({ children, ...props }) => {
  return (
    <AnimatePresence exitBeforeEnter initial>
      <AnimatedContainer {...props}>
        {children}
      </AnimatedContainer>
    </AnimatePresence>
  );
});
