import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { TOKEN } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  tokenData: null,
  tokenState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(TOKEN);

export const fetchToken = (code, isOrder) => {
  console.log("fetchToken")
  const data = { ...(isOrder ? { orderToken: encodeURIComponent(code) } : { QrCode: code }) };
  return apiAction({
    url: `/token/${isOrder ? 'getOrder' : 'getQr'}/`,
    onStart,
    onSuccess,
    onFailure,
    label: TOKEN,
    data
  });
};

export const tokenReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: TOKEN,
      onSuccess: (state, payload) => {
        return {
          ...state,
          tokenState: REQUEST_STATUS.SUCCESS,
          tokenData: payload,
        };
      },
      stateField: "tokenState",
    })

  },
  initialState
);

export const tokenReducerSelector = state => {
  return state.tokenReducer.tokenData;
}
