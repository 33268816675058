import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const showLogoRoutes = [
  '/author/welcome',
  '/author/complete',
  '/author/order/not-found',
  '/author/multi-products',
  '/author/send-confirmation',
]
export const useFooterLogo = () => {
  const { pathname } = useLocation();
  const renderLogo = showLogoRoutes.includes(pathname);
  useEffect(() => {
    const logos = Array.from(document.querySelectorAll('.site-footer__logos'))
    logos.forEach(logo => {
      if (renderLogo) {
        logo.classList.remove("hide-logo");
      } else {
        logo.classList.add("hide-logo");
      }
    })
  }, [renderLogo]);
}
