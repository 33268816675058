import React from 'react'
const RECORD_PREPARE_TIME = 3;

export default function Timer(props) {
	const [seconds, setSeconds] = React.useState(RECORD_PREPARE_TIME);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
			props.recording();
    }
  }, [seconds]);

  return (
    <div className="App">
      <div>
        {seconds}
      </div>
    </div>
  );
}
