import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { CLEAR_MESSAGE, MESSAGE } from "../actions/actionTypes";
import { sanitiseValue } from "Utils/common";

const initialState = {
  isLoading: false,
  messageData: [],
  messageState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: message,
  success: messageSuccess,
  fail: messageFail,
} = defineLoopActions(MESSAGE);

export const fetchMessage = (qrCode) => {
  const data = { qrCode };
  return apiAction({
    method: 'POST',
    url: '/message/load',
    onStart: message,
    onSuccess: messageSuccess,
    onFailure: messageFail,
    label: MESSAGE,
    data
  });
};

export const messageReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: MESSAGE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          messageState: REQUEST_STATUS.SUCCESS,
          messageData: payload,
        };
      },
      stateField: "messageState",
    }),
    [CLEAR_MESSAGE] : (state) => ({
      ...state,
      messageData: null,
    })

  },
  initialState
);

export const messageReducerSelector = (prop) => state => {
  const value = state.messageReducer[prop];

  return sanitiseValue(value);

}
