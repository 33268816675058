import { memo, useEffect, useRef } from "react";
import Button from 'Components/Audio/players/Button';
import gsap from 'gsap';
import './style.scss';

export const PlayPause = memo(({
  playing,
  imageSrc,
  handleCreative,
  label,
  caption
}) => {
  const labelRef = useRef();
  const captionRef = useRef();
  const containerRef = useRef();
  const animationsRef = useRef([]);

  useEffect(() => {
    animatePlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing]);

  const INITIAL_DELAY = 1;

  const getOption = () => {
    const defaults = {
      ease: 'expo.inOut',
      duration: 1,
    }
    return {
      container: {
        ...defaults,
        right: playing ? 'calc(100% - 14rem)' : '2rem',
        ...(playing && { delay: INITIAL_DELAY }),
      },
      other: {
        ...defaults,
        delay: .5,
        opacity: playing ? 0 : 1
      }
    }
  }

  const animatePlayer = () => {
    const { other, container } = getOption();
    animationsRef.current.forEach((e) => e.pause());

    const labelAnimation = gsap.to(labelRef.current, other);
    const captionAnimation = gsap.to(captionRef.current, other);
    const containerAnimation = gsap.to(containerRef.current, container);

    animationsRef.current = [labelAnimation, captionAnimation, containerAnimation];
  };

  return (
    <div className="PlayPause" ref={containerRef}>
      <div className="PlayPause__label" ref={labelRef}>{label}</div>
      <div className="PlayPause__caption" ref={captionRef}>{caption}</div>
      {imageSrc ? (
        <img
          className="PlayPause__image"
          src={imageSrc}
          alt="Sender"
        />
      ) : null}
      <Button
        className="PlayPause__button"
        onClick={handleCreative}
      >
        {playing ? (
          <span className="PlayPause__button--pause"></span>
        ) : (
          <span className="PlayPause__button--play"></span>
        )}
      </Button>
    </div>
  );
});