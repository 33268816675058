import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import WalkingMan from "../common/WalkingMan.js"

export class AnimationWalkingMan_2 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

  	// create plane  2
	  const geometry = new THREE.PlaneGeometry(400 , 800, 2 );
	  const material = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide} );
	  const plane = new THREE.Mesh( geometry, material );
	  this.parent.add( plane );

	// set plane initial state
	  plane.position.set(0, -800,-400);
	  //plane2.scale.set(1,0,1);
    gsap.to(plane.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

    const positionVector3 = new THREE.Vector3(-60, -70, -60 );

    new WalkingMan(this.parent, this.colors[0], this.duration, positionVector3);

    const self=this;
    gsap.to(plane.position,{delay:this.duration+0.3, y:800, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane);
    }});
  }

  destroy(){
  // this.parent.remove(this.plane1);
  }
   
}

export default AnimationWalkingMan_2; // Don’t forget to use export default!