import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
import { useQueryString } from "../../hooks/useQueryString";
import getAuthToken from "../../utils/pair/get-auth";
import { ReactComponent as ErrorIcon } from "../../assets/img/icon-error.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "../../store/actions/pair";
import "./pair.css";
import { useOnlyQrCode } from "Hooks/useQrcode";
import getUserSession from "../../utils/pair/user-session";

const OrderPage = () => {
  const dispatch = useDispatch();
  const auth = getAuthToken();
  const history = useHistory();
  const u_session = getUserSession();

  const [errorMsg, setErrorMessage] = useState("");

  console.log("Auth:", auth);
  if (!auth) {
    console.log("not authenticated");
  }

  // GET THE ERRORS FROM THE STATE
  const errors = useSelector((state) => state.order?.error, shallowEqual);

  const [orderForm, setOrderForm] = useState({
    orderNumber: "",
    skus: [],
  });

  const { qrCode } = useOnlyQrCode();
  console.log("QR Code is:", qrCode);
  if (!qrCode) {
    return <h1>No QrCode</h1>;
  }

  async function handleSubmit() {
    if (!orderForm.orderNumber) return;

    const orderNumber = orderForm.orderNumber;
    const request = {
      orderNumber,
      qrCode,
    };
    console.log("request",request)

    dispatch(fetchOrder(request));

    if(u_session){
      history.push("/pair/pair");
    }
  /*
    // we have it here
    console.log(
      "just before the push the localstorage is, ",
      window.localStorage.getItem("authurData")
    );

    history.push("/pair/pair"); // we lose it here
    */
  }

  function handleInputChange(event) {
    const { value, name } = event.target;
    setOrderForm({ ...orderForm, [name]: value });
  }

  return (
    <section className="center-content section-pair">
      <div className="container sm:w-96 mx-auto">
        <Header />
        <div className=" bg-white">
          <div className="px-6 py-8">
            <p className="text-3xl text-black">Pair a QR code</p>
            <div className="mt-8">
              <label className="block text-sm font-bold text-black">
                Order number
              </label>
              <div className="mt-2">
                <input
                  onChange={handleInputChange}
                  type="text"
                  id="orderNumber"
                  name="orderNumber"
                  required
                  className="w-full border-gray-400 rounded-md shadow-md"
                />
              </div>

              {errors && (
                <div className="flex mt-5 error-flex">
                  <ErrorIcon /> <span className="ml-3 text-sm text-red-600">{errors}</span>
                  <a title="Log in" href="/pair">Please log in again</a>
                </div>
              )}
            </div>
            <div className="flex items-center mt-8">
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn-bg rounded-md px-6 py-3 font-bold text-sm"
              >
                Find order
              </button>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderPage;
