import { Button } from "antd";
import { Footer } from "Components/Layout";
import "./Welcome.scss";
import { ReactComponent as MiabLogo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { useNavigator } from "Hooks/useNavigator";
import { useAuthoringComplete } from "Hooks/useAuthoringComplete";
import { useTranslation } from "Contexts/translation-context";
import { Heading } from "Components/heading";

export default function Welcome() {
  const { handleMsgTypeClick } = useNavigator();
  useAuthoringComplete();
  const { t } = useTranslation();
  return (
    <div className="page-wrap page-wrap--light sway-wrap">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar side-bar--light side-bar--welcome">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container">
            <h1 className="welcome-heading multi-size">
              <Heading keyName="SEND_A" />
              <MiabLogo className="miab-logo" />
            </h1>
            <div className="welcome-content">
              <p>{t("ENCOURAGE_RECORING")}</p>

              <div className="sway-button">
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => handleMsgTypeClick("audio")}
                >
                  {t("ENABLE_MICROPHONE")}
                  <ArrowDown />
                </Button>
              </div>
              <p className="welcome-text-version">{t("ENCOURAGE_TYPING")}</p>
              <Button
                type="secondary"
                shape="round"
                onClick={() => handleMsgTypeClick("text")}
              >
                {t("TYPE_MESSAGE")}
              </Button>
            </div>
          </div>
        </main>
      </section>
      {/* <Footer logos={"show"} /> */}
    </div>
  );
}
