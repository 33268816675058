import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonLogo from "../common/SingletonLogo.js"
import OccasionSymbol from "../common/OccasionSymbol.js"
import TextLine from "../common/textLine.js"


export class AnimationOccasion extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword, occasionID) {
  	super(parent, colors, duration, keyword);
    this.occasionID = occasionID;
  }

  start(){
    
    const self = this;
    var color = this.colors[1];
    switch(this.occasionID){
      case 1261:
        this.animationFathersDay();
        color = this.colors[1];
        break;

      default:
        this.animationBirthDay();
        color = this.colors[0];
        break;
    }

    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: color, side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
   
    }})

  }

  animationFathersDay(){
     const positionVector3 = new THREE.Vector3(10, 0, -1);

      var occasion = new OccasionSymbol(this.parent, this.colors[0], this.duration, positionVector3, "gift");
      gsap.to(occasion.position,{delay:0, x:4, ease: "expo.inOut", duration:this.duration});
      gsap.to(occasion.position,{delay:this.duration, x:10, ease: "expo.inOut", duration:this.duration});

      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[2], undefined, 'Korolev Compressed Bold');
      this.parent.add(word);
      word.rotation.set(0,0,Math.PI/2);
      word.position.set(-2.5,0,0);

      const self = this;
      //word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration, onComplete:function(){
        self.parent.remove(occasion);
         self.parent.remove(word);

      }});
  }

  animationBirthDay(){

         const self = this;
    const baloonContainer = new THREE.Group();
    this.parent.add(baloonContainer);

    baloonContainer.position.set(-9, -18, 0);

    for (var i = 0; i < 4; i++) {

      for (var j = 0; j < 4; j++) {

        const positionVector3 = new THREE.Vector3(i*6, j*12, -24);

        var occasion = new OccasionSymbol(baloonContainer, this.colors[1], this.duration, positionVector3, "baloon");
        occasion.rotation.set(0,0,i-j);
        occasion.position.set(positionVector3.x,positionVector3.y-60,positionVector3.z);

        gsap.to(occasion.position,{delay:0.05*i, y:positionVector3.y, ease: "expo.inOut", duration:this.duration});
        gsap.to(occasion.rotation,{delay:0.05*i, z:0, ease: "expo.inOut", duration:this.duration});

        gsap.to(occasion.position,{delay:this.duration-0.05*i, y:positionVector3.y+60, ease: "expo.inOut", duration:this.duration});

      }

    }
     
    const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[1], undefined, 'Korolev Compressed Bold');
    this.parent.add(word);
    word.rotation.set(0,0,Math.PI/2);
    word.position.set(0,0,0);

    //word.rotation.set(0,0,Math.PI/2);
    word.material.map.offset.y = 1;
    gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word.position,{delay:this.duration, y:15, ease: "expo.inOut", duration:this.duration,onComplete:function(){
        self.parent.remove(word);
        self.parent.remove(baloonContainer);
    }});
    
  }

   
}

export default AnimationOccasion; // Don’t forget to use export default!