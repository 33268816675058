export const ADD_RECORED_VOICE = "ADD_RECORED_VOICE";
export const ADD_TRANSCRIPTION_IF_TIMEOUT = "ADD_TRANSCRIPTION_IF_TIMEOUT";
export const ADD_RECORED_TEXT = "ADD_RECORED_TEXT";
export const ADD_PROFILE_IMAGE = "ADD_PROFILE_IMAGE";
export const DELETE_PROFILE_IMAGE = "DELETE_PROFILE_IMAGE";
export const OCCASION = "OCCASION";
export const ORDER = "ORDER";
export const MESSAGES = "MESSAGES";
export const MESSAGE = "MESSAGE";
export const MESSAGE_EXPERIENCE = "MESSAGE_EXPERIENCE";
export const MESSAGE_USER = "MESSAGE_USER";
export const CONSUMER = "CONSUMER";
export const CONSUMER_UPDATE = "CONSUMER_UPDATE";
export const FINALISE = "FINALISE";
export const ROUTING_SET_SLUG = "ROUTING_SET_SLUG";
export const SET_BOTTLE_ID = "SET_BOTTLE_ID";
export const SET_OCCASION_DATA = "SET_OCCASION_DATA";
export const SET_SELECTED_BOTTLE_COUNTER = "SET_SELECTED_BOTTLE_COUNTER";
export const SET_SELECTED_BOTTLES = "SET_SELECTED_BOTTLES";
export const UPDATE_CONSUMER = "UPDATE_CONSUMER";
export const SET_GIFT_DATA = "SET_GIFT_DATA";
export const SET_ONLY_WRITTEN_MESSAGE = "SET_ONLY_WRITTEN_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_RECORDING = "CLEAR_RECORDING";
export const TOKEN = "TOKEN";
export const FINALIZE = "FINALIZE";
export const POLLING = "POLLING";
export const CLEAR_CONSUMER_UPDATE = "CLEAR_CONSUMER_UPDATE";
export const CLEAR_FINALIZE = "CLEAR_FINALIZE";
export const CLEAR_TEXT_RECORDING = "CLEAR_TEXT_RECORDING";
export const CLEAR_MESSAGE_USER = "CLEAR_MESSAGE_USER";
export const CLEAR_MESSAGE_EXPERIENCE = "CLEAR_MESSAGE_EXPERIENCE";
export const CLEAR_COMMON_DATA = "CLEAR_COMMON_DATA";
export const SET_COMMON_DATA = "SET_COMMON_DATA";
export const SET_TRANSLATION = "SET_TRANSLATION";
export const TRANSLATION = "TRANSLATION";
export const SET_BRAND_KEY = "SET_BRAND_KEY"; // consumer offtrade
export const SET_PRODUCT_KEY = "SET_PRODUCT_KEY"; // consumer offtrade
export const GET_BRANDS = "GET_BRANDS";
export const SET_JOURNEY = "SET_JOURNEY";
export const SET_CUSTOMER_EMAIL = "SET_CUSTOMER_EMAIL";
export const SET_RETAILER_ID = "SET_RETAILER_ID";
export const SET_QR_CODE = "SET_QR_CODE";
export const OFFTRADE_TRANSLATION = "OFFTRADE_TRANSLATION";
