import * as THREE from "three";
import gsap from "gsap";

export class BaseCreative {
  constructor(props) {
    const {
      scene,
      primaryColour,
      secondaryColour,
      tertiaryColour,
      font,
      duration,
      keyword,
      texture,
      creativity
    } = props;
    this.scene = scene;
    this.duration = duration;
    this.keyword = keyword;
    this.primaryColour = primaryColour;
    this.secondaryColour = secondaryColour;
    this.tertiaryColour = tertiaryColour;
    this.imageUrl = texture.imageUrl;
    this.backgroundColour = texture.backgroundColour;
    this.textColour = texture.textColour;
    this.fontName = texture.fontName;
    this.texture = texture;
    this.font = font;
    this.imageHeight = texture.imageHeight;
    this.imageWidth = texture.imageWidth;
    this.imageBackground = 0xffffff;
    this.creativity = creativity;
  }
  loadAsset = () => {
    const element = new THREE.TextureLoader().load(this.imageUrl);

    return {
      element,
      setRepeat: (x, y) => {
        element.wrapS = THREE.RepeatWrapping;
        element.wrapT = THREE.RepeatWrapping;
        element.repeat.set(x, y);
      },
      setOffset: (...args) => {
        this.setOffset(element, ...args);
      }
    }
  }
  addGroup = () => {
    const element = new THREE.Group();
    this.scene.add(element);
    return {
      element,
      add: (item) => {
        element.add(item);
      },
      setPosition: (...args) => {
        this.setPosition(element, ...args);
      },
      setRotation: (...args) => {
        this.setRotation(element, ...args);
      }
    };
  }
  addMesh(color, width, height, widthSegments, additionalMaterialProps, parentEl) {
    const materialProps = {
      color: color || this.imageBackground,
      side: THREE.DoubleSide,
      transparent: true
    };
    const element = new THREE.Mesh(
      new THREE.PlaneGeometry(width, height, widthSegments),
      new THREE.MeshBasicMaterial({ ...materialProps, ...additionalMaterialProps })
    );
    if (!parentEl) {
      this.scene.add(element);
    } else {
      parentEl.add(element)
    }
    return {
      element,
      setPosition: (...args) => {
        this.setPosition(element, ...args);
      },
      setRotation: (...args) => {
        this.setRotation(element, ...args);
      },
      setMaterialOpacity: (...args) => {
        this.setOpacity(element.material, ...args);
      },
      setScale: (...args) => {
        this.setScale(element, ...args);
      }
    };
  }

  setPosition(element, x, y, z, start, end) {
    element.position.set(x, y, z);
    if (start) {
      gsap.to(element.position, {
        ease: "expo.inOut",
        duration: this.duration,
        ...start,
      });
    }
    if (end) {
      gsap.to(element.position, {
        ease: "expo.inOut",
        duration: this.duration,
        onComplete: () => {
          this.scene.remove(element);
          if (end.onFinish) {
            end.onFinish();
          }
        },
        ...end
      });
    }
  }

  setRotation = (element, x, y, z, start) => {
    element.rotation.set(x, y, z);
    if (start) {
      gsap.to(element.rotation, {
        ease: "expo.inOut",
        duration: this.duration,
        ...start
      });
    }
  }

  setOpacity = (element, opacity, start, end) => {
    element.opacity = opacity;
    if (start) {
      gsap.to(element, {
        ease: "expo.inOut",
        ...start
      });
    }
    if (end) {
      gsap.to(element, {
        ease: "expo.inOut",
        ...end
      });
    }
  }

  setScale = (element, x, y, z, start) => {
    element.scale.set(x, y, z);
    if (start) {
      gsap.to(element.scale, {
        ease: "expo.inOut",
        duration: this.duration,
        ...start
      });
    }
  }
  setOffset = (element, offset, start, end) => {
    element.offset = {
      ...element.offset,
      ...offset
    }
    if (start) {
      gsap.to(element.offset, {
        ease: "expo.inOut",
        duration: this.duration,
        ...start
      });
    }
    if (end) {
      gsap.to(element.offset, {
        ease: "expo.inOut",
        duration: this.duration,
        onComplete: () => {
          this.scene.remove(element);
        },
        ...end
      });
    }
  }

  createText = (props) => {

    const {
      text, hPxTxt, fgcolor, bgcolor, font, spacing = 0, proportionalSize = true
    } = props;

    let {
      hWorldTxt, hWorldAll
    } = props;

    if (!text) {
      return new THREE.Mesh();
    }

    var textCopy = text.trim();
    if(textCopy == "")
    {
      textCopy = "   ";
    }
    const txtLength = textCopy.length;
    const averageLength = 8;

    if(proportionalSize){
       if (txtLength > averageLength) {
        hWorldTxt = ((averageLength / txtLength) * hWorldTxt);
        hWorldAll = hWorldTxt;
      }else {

        hWorldTxt = ((2 / txtLength) * hWorldTxt);
        hWorldAll = hWorldTxt;
      }
    }

    const kPxToWorld = hWorldTxt / hPxTxt;

    const hPxAll = Math.ceil(hWorldAll / kPxToWorld);
    const toPixel = number => `${number}px`;
    const usableFont = `${toPixel(hPxTxt)} ${font}`;

    const txtcanvas = document.createElement("canvas");

    document.body.appendChild(txtcanvas);

    txtcanvas.style.letterSpacing = toPixel(spacing);

    const ctx = txtcanvas.getContext("2d");
    ctx.font = usableFont;

    const wPxTxt = ctx.measureText(text).width;
    if(wPxTxt < 1){
      wPxTxt = 1;
    }
    const wWorldTxt = wPxTxt * kPxToWorld;
    const wWorldAll = wWorldTxt + (hWorldAll - hWorldTxt);
    const wPxAll = Math.ceil(wWorldAll / kPxToWorld);

    txtcanvas.width = wPxAll;
    txtcanvas.height = hPxAll;
    const toHex = color => `#${color.toString(16).padStart(6, '0')}`;

    if (typeof bgcolor !== 'undefined') {
      ctx.fillStyle = toHex(bgcolor);
      ctx.fillRect(0, 0, wPxAll, hPxAll);
    } else {
      ctx.fillStyle = "rgba(0,0,0,0)";
      ctx.fillRect(0, 0, wPxAll, hPxAll);
    }
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = toHex(fgcolor);
    ctx.font = usableFont;

    ctx.fillText(textCopy, wPxAll / 2+4, hPxAll / 2+4);

    const texture = new THREE.Texture(txtcanvas);
    texture.minFilter = THREE.LinearFilter;
    texture.needsUpdate = true;

    const mesh = new THREE.Mesh(
      new THREE.PlaneGeometry(wWorldAll, hWorldAll),
      new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, map: texture, transparent: true, opacity: 1.0 })
    );

    document.body.removeChild(txtcanvas);
    return mesh;
  }

  addTextLine(...args) {
    const lastParam = args.at(-1);
    let parentEl = this.scene

    if (lastParam.parentEl) {
      args.pop();
      parentEl = lastParam.parentEl;
    }
    const [hWorldTxt, hWorldAll, hPxTxt, fgcolor, bgcolor, font, spacing = 0, proportionalSize = true] = args;
    
    const element = this.createText({
      text: this.keyword.toUpperCase(),
      hWorldTxt,
      hWorldAll,
      hPxTxt,
      fgcolor,
      bgcolor,
      font,
      spacing,
      proportionalSize,
    });
    parentEl.add(element);

    return {
      element,
      setPosition: (...args) => {
        this.setPosition(element, ...args);
      },
      setRotation: (...args) => {
        this.setRotation(element, ...args);
      },
      setMaterialOffset: (...args) => {
        this.setOffset(element.material.map, ...args);
      },
      setOffset: (...args) => {
        this.setOffset(element, ...args)
      },
      setScale: (...args) => {
        this.setScale(element, ...args);
      }
    };
  }

  dummyMapper(length, callback) {
    return Array.from(Array(length)).map((_, i) => callback(i));
  }

  addBackground = (props) => {
    const {
      TOPHALF = false,
      BOTTOMHALF = false,
      sameBackground = false,
      scaleFactor = null,
    } = props;

    let topBackground = this[this.backgroundColour[0]];
    let bottomBackground = this[this.backgroundColour[sameBackground ? 0 : 1]];

    const anim = (element, multiplier) => {
      element.setPosition(0, -300, -200, { delay: 0, y: 100 * multiplier }, { delay: this.duration, y: 300 });
    }

    const [top, bottom] = [topBackground, bottomBackground].map((color) => this.addMesh(color, 200, 200, 2))

    anim(top, 1);

    anim(bottom, -1);

    if (TOPHALF || BOTTOMHALF) {
      const parentEl = TOPHALF ? top.element : bottom.element;
      const img = this.addMesh(this.imageBackground, this.imageWidth, this.imageHeight, 2, { map: this.loadAsset().element }, parentEl);
      if(scaleFactor) {
        img.setScale(scaleFactor, scaleFactor);
      }
    }
  }

};