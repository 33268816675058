import React from "react";

import { withRouter } from "react-router-dom";

import { Button } from "antd";
import { Footer } from "Components/Layout";

import { ReactComponent as MiabLogo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowRight } from "Assets/img/arrow-right.svg";
import { Heading } from "Components/heading";
import { useTranslation } from "Contexts/translation-context";

export function SendConfirmation() {
  const { t } = useTranslation();
  const onBack = () => {
    window.location.assign(t("STORE_URL"));
  }

  return (
    <div className="page-wrap page-wrap--light sway-wrap">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar side-bar--light">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container">
            <h1 className="multi-size -half-margin">
              <MiabLogo className="miab-logo" />
              <Heading keyName="ON_ITS_WAY" />
            </h1>
            <p className="confirmation-text font-longform">
              {t("ON_ITS_WAY_EMAIL_TRACKING_TEXT")}
            </p>
            <div className="sway-button">
              <Button type="primary" shape="round" onClick={onBack}>
                {t("BACK_TO_WEBSITE")}
                <ArrowRight />
              </Button>
            </div>
          </div>
        </main>
      </section>
      {/* <Footer logos={"show"} /> */}
    </div>
  );
}
export default withRouter(SendConfirmation);
