import { useHistory } from "react-router";
import { useRef } from "react";
import Experience from "Components/legacy-creative/Experience";
import './../RecipientFrame.scss';
import { useMessage } from "Hooks/recipient/useMessage";
import { useConsumer } from "Hooks/recipient/useConsumer";
import { useMessageUser } from "Hooks/useMessageUser";
import { DiageoCreative } from "Components/diageo-creative";
import BasicExperience from "../../../components/basic-creative/BasicExperience";

const PlayMessage = () => {
  const history = useHistory();

  const {
    messageToken,
    occasionId,
    productImage
  } = useMessage();

  const {
    giverName
  } = useConsumer();

  const { isGeneric = false, isLegacy = false, isBasic = false } = useMessageUser(messageToken, occasionId, true);
  const NoMessagePresent = messageToken === "00000000-0000-0000-0000-000000000000";

  const handleOnEnd = () => {
    history.push('/recipient/end');
  };
  let experienceRef = useRef();
  if (!messageToken && !occasionId) {
    history.push('/recipient/home');
  }

  var intViewportWidth = window.innerWidth;
  var intViewportHeight = window.innerHeight;

  if (intViewportWidth > 600) {
    intViewportWidth = 312;
    intViewportHeight = 675;
  }

  let creativeWidth = intViewportWidth;
  let creativeHeight = intViewportHeight;

  if (window.innerWidth < 580) {
    creativeWidth = 315;
    creativeHeight = 675;
  }

  var basicWidth = window.innerWidth;
  var basicHeight = window.innerHeight;

  if (window.innerWidth >= 922) {
    basicWidth = 310;
    basicHeight = 675;
  }

  return (
    <>
      {isBasic && (<div className="recipient__play-message">
        <div className="recipient-frame">
          <div className="recipient-frame__inner recipient-frame__inner--animation">
            <BasicExperience
              isLive
              width={basicWidth}
              height={basicHeight}
              giftGiver={giverName}
              giftAudio={isBasic?.audio_message}
              giftImage={productImage}
              onEnd={handleOnEnd}
            />
          </div>
        </div>
      </div>
      )}
      {isLegacy && (<div className="recipient__play-message">
        <div className="recipient-frame">
          <div className="recipient-frame__inner recipient-frame__inner--animation">
            <Experience
              ref={(cc) => (experienceRef = cc)}
              className="experience"
              messageToken={messageToken}
              occasionID={occasionId}
              source="recipient"
              canvasWidth={intViewportWidth}
              canvasHeight={intViewportHeight}
              onEnd={handleOnEnd}
              senderName={giverName}
              productImage={productImage}
            />

          </div>
        </div>
      </div>
      )}
      {isGeneric && (
        <div className="recipient">
          <div className="container">
            <div className="message-preview__frame">
              <DiageoCreative
                width={creativeWidth}
                height={creativeHeight}
                statusFeedback={(isPlaying) => (!isPlaying && handleOnEnd())}
                productImage={productImage}
                giftGiver={giverName}
                onEnd={handleOnEnd}
              />
            </div>
          </div>
        </div>
      )}
      {NoMessagePresent && ( 
        <div>Message not availble yet!</div>

      )}
    </>
  );

}

export default PlayMessage;