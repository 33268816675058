import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Zero from "../common/Zero.js"
import TextLine from "../common/textLine.js"


export class AnimationZerosSingleton_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const positionVector3 = new THREE.Vector3(-5, 0, 4);
    var zero = new Zero(this.parent, this.colors[3], this.duration, positionVector3);
    
    const word = new TextLine(this.keyword.toUpperCase(), 0.8, 0.8, 400, this.colors[2], undefined, 'Korolev Compressed Bold');
    this.parent.add(word);
    word.position.set(0,2.5,4.1);
    word.material.map.offset.y = 1;
    gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});

    const word2 = new TextLine(this.keyword, 0.8, 0.8, 400, this.colors[2], undefined, "Authenia W05");
    this.parent.add(word2);
    word2.position.set(0,-2.5,4.1);
    word2.material.map.offset.y = 1;
    gsap.to(word2.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word2.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});

    gsap.to(zero.position,{x:-2, delay:0.3, ease: "expo.inOut", duration:this.duration/2});
    gsap.to(zero.position,{x:-5, delay:this.duration, ease: "expo.inOut", duration:this.duration/2});

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(word);
      self.parent.remove(word2);
      self.parent.remove(zero);
    }});

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationZerosSingleton_4; // Don’t forget to use export default!