import { GetGenericAnimationAssetPath } from "./get-path";

export const injectFontFace = (fonts) => {
  const font = {};

  const styleId = "font-style-sheet";

  // Get a reference to the current in-use stylesheet, if there is one.
  const fontStyleSheet = document.getElementById(styleId);
  if (fontStyleSheet) {
    fontStyleSheet.remove();
  }
  // Then define a new stylesheet with an updated @font-face rule:
  const newFontStyleSheet = document.createElement("style");
  newFontStyleSheet.id = styleId;

  // https://stackoverflow.com/questions/51003925/how-can-i-change-font-face-source-with-javascript
  newFontStyleSheet.textContent = fonts.reduce((acc, { type, name, url }) => {
    const fontFamily = name;
    font[type] = fontFamily;
    const urls = url.map(
      (u) =>
        `url('${GetGenericAnimationAssetPath(u)}') format('${
          /(?:\.([^.]+))?$/.exec(u)[1]
        }')`
    );

    return `${acc}@font-face{font-family:'${fontFamily}';src:${urls.toString()};}`;
  }, "");
  // inject the font face to DOM head
  document.head.appendChild(newFontStyleSheet);

  return font;
};
