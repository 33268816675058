
// Talisker set 1
export const AnimationMaskTalisker = require('./animationMaskTalisker').default
export const AnimationMaskTalisker_1 = require('./animationMaskTalisker_1').default
export const AnimationMaskTalisker_2 = require('./animationMaskTalisker_2').default
export const AnimationMaskTalisker_3 = require('./animationMaskTalisker_3').default
export const AnimationMaskTalisker_4 = require('./animationMaskTalisker_4').default

// Talisker set 2
export const AnimationBrandTalisker = require('./animationBrandTalisker').default
export const AnimationBrandTalisker_1 = require('./animationBrandTalisker_1').default
export const AnimationBrandTalisker_2 = require('./animationBrandTalisker_2').default
export const AnimationBrandTalisker_3 = require('./animationBrandTalisker_3').default
export const AnimationBrandTalisker_4 = require('./animationBrandTalisker_4').default

// Talisker set 3
export const AnimationCompassTalisker = require('./animationCompassTalisker').default
export const AnimationCompassTalisker_1 = require('./animationCompassTalisker_1').default
export const AnimationCompassTalisker_2 = require('./animationCompassTalisker_2').default
export const AnimationCompassTalisker_3 = require('./animationCompassTalisker_3').default
export const AnimationCompassTalisker_4 = require('./animationCompassTalisker_4').default

// Talisker set 4
export const AnimationStampTalisker = require('./animationStampTalisker').default
export const AnimationStampTalisker_1 = require('./animationStampTalisker_1').default
export const AnimationStampTalisker_2 = require('./animationStampTalisker_2').default
export const AnimationStampTalisker_3 = require('./animationStampTalisker_3').default
export const AnimationStampTalisker_4 = require('./animationStampTalisker_4').default

// Talisker set 5
export const AnimationCoastlineTalisker = require('./animationCoastlineTalisker').default

// Talisker set 6
export const AnimationTextureTalisker = require('./animationTextureTalisker').default
export const AnimationTextureTalisker_1 = require('./animationTextureTalisker_1').default
export const AnimationTextureTalisker_2 = require('./animationTextureTalisker_2').default
export const AnimationTextureTalisker_3 = require('./animationTextureTalisker_3').default
export const AnimationTextureTalisker_4 = require('./animationTextureTalisker_4').default

export const AnimationOccasionTalisker = require('./animationOccasionTalisker').default

