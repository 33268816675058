import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { MESSAGE_USER, CLEAR_MESSAGE_USER } from "../actions/actionTypes";
import { sanitiseValue } from "Utils/common";

const initialState = {
  isLoading: false,
  data: null,
  messageState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(MESSAGE_USER);

export const fetchMessageUser = (MessageToken, OcassionId) => {
  const data = { MessageToken, OcassionId };
  return apiAction({
    method: 'POST',
    url: '/message/user',
    onStart,
    onSuccess,
    onFailure,
    label: MESSAGE_USER,
    data
  });
};

export const messageUserReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: MESSAGE_USER,
      onSuccess: (state, payload) => {
        return {
          ...state,
          messageState: REQUEST_STATUS.SUCCESS,
          data: payload,
        };
      },
      onFailure: (state) => {
        return {
          ...state,
          isLoading: false,
          messageState: REQUEST_STATUS.FAIL
        };
      },
    }),
    [CLEAR_MESSAGE_USER]: (state) => ({
      ...state,
      data: null,
      error: null,
    }),
  },
  initialState
);


export const clearMessageUser = () => {
  return {
    type: CLEAR_MESSAGE_USER,
  }
}

export const messageUserReducerSelector = (prop) => state => {
  const value = state.messageUserReducer[prop];

  return sanitiseValue(value);

}
