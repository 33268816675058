import {
  AnimationOccasionJW,
  AnimationMask,
  AnimationMask_1,
  AnimationMask_2,
  AnimationMask_3,
  AnimationMask_4,
  AnimationParallelogram,
  AnimationParallelogram_1,
  AnimationParallelogram_2,
  AnimationParallelogram_3,
  AnimationParallelogram_4,
  AnimationWalkingMan,
  AnimationWalkingMan_1,
  AnimationWalkingMan_2,
  AnimationWalkingMan_3,
  AnimationWalkingMan_4,
  AnimationStripe,
  AnimationStripe_1,
  AnimationStripe_2,
  AnimationStripe_3,
  AnimationStripe_4,
  AnimationBottle,
  AnimationBottle_1,
  AnimationBottle_2,
  AnimationBottle_3,
  AnimationBottle_4,
} from 'Components/legacy-creative/johnnie-walker-black';

import {
  AnimationOccasion,
  AnimationMaskSingleton,
  AnimationMaskSingleton_1,
  AnimationMaskSingleton_2,
  AnimationMaskSingleton_3,
  AnimationMaskSingleton_4,
  AnimationBrushSingleton,
  AnimationBrushSingleton_1,
  AnimationBrushSingleton_2,
  AnimationBrushSingleton_3,
  AnimationBrushSingleton_4,
  AnimationZerosSingleton,
  AnimationZerosSingleton_1,
  AnimationZerosSingleton_2,
  AnimationZerosSingleton_3,
  AnimationZerosSingleton_4,
  AnimationSalmonSingleton,
  AnimationSalmonSingleton_1,
  AnimationSalmonSingleton_2,
  AnimationSalmonSingleton_3,
  AnimationSalmonSingleton_4,
  AnimationMapSingleton,
  AnimationMapSingleton_1,
  AnimationMapSingleton_2,
  AnimationMapSingleton_3,
  AnimationMapSingleton_4,
  AnimationBrandSingleton,
  AnimationBrandSingleton_1,
  AnimationBrandSingleton_2,
  AnimationBrandSingleton_3,
  AnimationBrandSingleton_4,
} from 'Components/legacy-creative/singleton';

import {
  AnimationOccasionTalisker,
  AnimationMaskTalisker,
  AnimationMaskTalisker_1,
  AnimationMaskTalisker_2,
  AnimationMaskTalisker_3,
  AnimationMaskTalisker_4,
  AnimationBrandTalisker,
  AnimationBrandTalisker_1,
  AnimationBrandTalisker_2,
  AnimationBrandTalisker_3,
  AnimationBrandTalisker_4,
  AnimationCompassTalisker,
  AnimationCompassTalisker_1,
  AnimationCompassTalisker_2,
  AnimationCompassTalisker_3,
  AnimationCompassTalisker_4,
  AnimationStampTalisker,
  AnimationStampTalisker_1,
  AnimationStampTalisker_2,
  AnimationStampTalisker_3,
  AnimationStampTalisker_4,
  AnimationCoastlineTalisker,
  AnimationTextureTalisker,
  AnimationTextureTalisker_1,
  AnimationTextureTalisker_2,
  AnimationTextureTalisker_3,
  AnimationTextureTalisker_4,
} from 'Components/legacy-creative/talisker';






export const animationTemplates = [
  AnimationOccasionJW,
  AnimationMask,
  AnimationMask_1,
  AnimationMask_2,
  AnimationMask_3,
  AnimationMask_4,
  AnimationParallelogram,
  AnimationParallelogram_1,
  AnimationParallelogram_2,
  AnimationParallelogram_3,
  AnimationParallelogram_4,
  AnimationWalkingMan,
  AnimationWalkingMan_1,
  AnimationWalkingMan_2,
  AnimationWalkingMan_3,
  AnimationWalkingMan_4,
  AnimationStripe,
  AnimationStripe_1,
  AnimationStripe_2,
  AnimationStripe_3,
  AnimationStripe_4,
  AnimationBottle,
  AnimationBottle_1,
  AnimationBottle_2,
  AnimationBottle_3,
  AnimationBottle_4,
  AnimationOccasion,
  AnimationMaskSingleton,
  AnimationMaskSingleton_1,
  AnimationMaskSingleton_2,
  AnimationMaskSingleton_3,
  AnimationMaskSingleton_4,
  AnimationBrushSingleton,
  AnimationBrushSingleton_1,
  AnimationBrushSingleton_2,
  AnimationBrushSingleton_3,
  AnimationBrushSingleton_4,
  AnimationZerosSingleton,
  AnimationZerosSingleton_1,
  AnimationZerosSingleton_2,
  AnimationZerosSingleton_3,
  AnimationZerosSingleton_4,
  AnimationSalmonSingleton,
  AnimationSalmonSingleton_1,
  AnimationSalmonSingleton_2,
  AnimationSalmonSingleton_3,
  AnimationSalmonSingleton_4,
  AnimationMapSingleton,
  AnimationMapSingleton_1,
  AnimationMapSingleton_2,
  AnimationMapSingleton_3,
  AnimationMapSingleton_4,
  AnimationBrandSingleton,
  AnimationBrandSingleton_1,
  AnimationBrandSingleton_2,
  AnimationBrandSingleton_3,
  AnimationBrandSingleton_4,
  AnimationOccasionTalisker,
  AnimationMaskTalisker,
  AnimationMaskTalisker_1,
  AnimationMaskTalisker_2,
  AnimationMaskTalisker_3,
  AnimationMaskTalisker_4,
  AnimationBrandTalisker,
  AnimationBrandTalisker_1,
  AnimationBrandTalisker_2,
  AnimationBrandTalisker_3,
  AnimationBrandTalisker_4,
  AnimationCompassTalisker,
  AnimationCompassTalisker_1,
  AnimationCompassTalisker_2,
  AnimationCompassTalisker_3,
  AnimationCompassTalisker_4,
  AnimationStampTalisker,
  AnimationStampTalisker_1,
  AnimationStampTalisker_2,
  AnimationStampTalisker_3,
  AnimationStampTalisker_4,
  AnimationCoastlineTalisker,
  AnimationTextureTalisker,
  AnimationTextureTalisker_1,
  AnimationTextureTalisker_2,
  AnimationTextureTalisker_3,
  AnimationTextureTalisker_4,
];

export const animationTextures = [
  'bottle.png',
  'brush.png',
  'JwStripe.png',
  'JwStripe-h.png',
  'JwStripe2.png',
  'Black_label-walking-man.png',
  'zero.png',
  'black_label_1.jpg',
  'black_label_2.jpg',
  'black_label_3.jpg',
  'black_label_4.jpg',
  'black_label_5.jpg',
  'singleton_1.png',
  'singleton_2.png',
  'singleton_3.png',
  'singleton_4.png',
  'singleton_5.png',
  'singleton-map.png',
  'singleton-logo.png',
  'singleton-signature.png',
  'occasion-baloon.png',
  'occasion-gift.png',
  'talisker-coordinates.png',
  'talisker-compass.png',
  'talisker-compass-empty.png',
  'talisker-stamp.png',
  'talisker-1.jpg',
  'talisker-2.jpg',
  'talisker-3.jpg',
  'talisker-4.jpg',
  'talisker-5.jpg',
  'talisker-coastline.png',
  'talisker-texture-blue.jpg',
  'talisker-texture-white.jpg',
  'talisker-merlion.png',
];

export const legacyPack = ({
  scene,
  primaryColour,
  secondaryColour,
  tertiaryColour,
  duration,
  keyword,
}) => {
  const [, qs] = window.location.hash.split('?');
  let legacyid;
  if (qs) {
    qs.split("&").forEach(q => {
      const [key, value] = q.split("=");
      if (key === 'legacyid' && !legacyid) {
        legacyid = value;
      }
    });
  }
  if (legacyid && process.env.NODE_ENV !== 'production') {

    const Legacy = animationTemplates[legacyid];
    const legacyAnimation = (...args) => class LegacyRunner extends Legacy {
      constructor() {
        super(...args);
      }
    }
    return [legacyAnimation(
      scene,
      [
        primaryColour,
        secondaryColour,
        tertiaryColour,
        0xeff3e7
      ],
      duration,
      keyword
    )];
  }

  return null;
}
