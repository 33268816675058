import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { loadMessageUser } from "Services/messagesService";

export const useMessageUser = (messageToken, occasionId, forceRefresh = false) => {
  const [ shouldForceRefresh, setForceRefresh ] = useState(forceRefresh)
  const userData = useSelector(state => state.messageUserReducer.data, shallowEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    if ((!userData?.creative && !userData?.legacy && !userData?.basic) || shouldForceRefresh) {
      setForceRefresh(false);
      console.log("messageToken", messageToken)
      if(messageToken !== "00000000-0000-0000-0000-000000000000"){
        dispatch(loadMessageUser(messageToken, occasionId));
      }
    }
  }, [userData]);

  return {
    isGeneric: shouldForceRefresh ? false : userData?.creative,
    isLegacy: shouldForceRefresh ? false : userData?.legacy,
    isBasic: shouldForceRefresh ? false : userData?.basic
  };
};