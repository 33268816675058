import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonLogo from "../common/SingletonLogo.js"
import TextLine from "../common/textLine.js"


export class AnimationBrandSingleton extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const logoContainer = new THREE.Group();
    this.parent.add(logoContainer);
    logoContainer.position.set(2,-5,0);

    for (var i = 0; i < 5; i++) {
      var trgX = -i;
      const positionVector3 = new THREE.Vector3(-10, i*2.5, 0);
      const logo = new SingletonLogo(logoContainer, this.colors[2], this.duration, positionVector3)
      //logo.rotation.set(0,0,Math.PI/4);
      logo.material.opacity = 0;
      gsap.to(logo.position,{delay:0, x:trgX, ease: "expo.inOut", duration:this.duration});
      gsap.to(logo.position,{delay:this.duration, x:trgX+10, ease: "expo.inOut", duration:this.duration});

      //gsap.to(logo.rotation,{delay:this.duration, z:-Math.PI/4, ease: "expo.inOut", duration:this.duration});

      gsap.to(logo.material,{delay:0, opacity:1, ease: "expo.inOut", duration:this.duration});
      gsap.to(logo.material,{delay:this.duration, opacity:0, ease: "expo.inOut", duration:this.duration});
    }
   
    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);
    wordContainer.position.set(0,0,-1);

    const wordPosition = [];
    wordPosition.push(new THREE.Vector3(-2.5, -5, 0));
    wordPosition.push(new THREE.Vector3(-0.5, -7, 0));
    wordPosition.push(new THREE.Vector3(2.5, 6, 0));
    wordPosition.push(new THREE.Vector3(0.5, 8, 0));

    for (var j = 0; j < wordPosition.length; j++) {

      const word = new TextLine(this.keyword.toUpperCase(), 1.8, 1.8, 400, this.colors[0], undefined, 'Korolev Compressed Bold');
      const v = wordPosition[j];
      word.rotation.set(0,0,Math.PI/2);
      word.position.set(v.x, v.y-20, v.z);
      wordContainer.add(word);

      gsap.to(word.position,{delay:0.05*j, y:v.y, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration+0.05*j, y:v.y+20, ease: "expo.inOut", duration:this.duration});

    }

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(logoContainer);
      self.parent.remove(wordContainer);   
    }})

  }
   
}

export default AnimationBrandSingleton; // Don’t forget to use export default!