import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./loading.scss";

const antIcon = <LoadingOutlined style={{ fontSize: 140 }} spin />;

const LoadingScreen = () => {
  return (
    <div className="svg-loader">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default LoadingScreen;
