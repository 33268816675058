import React from 'react';
import './RecipientEnd.scss';
import './../RecipientFrame.scss';
import './Information.scss';
import { withRouter, useHistory } from 'react-router-dom';
import { Row, Col } from 'antd';
import { Footer } from 'Components/Layout';
import { useMessage } from 'Hooks/recipient/useMessage';
import { ReactComponent as CloseIcon } from 'Assets/img/circle-close.svg';
import { ReactComponent as Bottle } from 'Assets/img/miab-bottle-alt.svg';
import { ReactComponent as Arrow } from 'Assets/images/Button-icons/arrow-side-black.svg';
import { useTranslation } from 'Contexts/translation-context';

export const Information = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { productImage } = useMessage();

  const handleCancel = () => {
    history.push('/recipient/end');
  };

  return (
    <div className="page-wrap">
      <section className="information">
        <div className="container">
          <div class="recipient-frame">
            <div class="recipient-frame__inner">
              <div className="information__header">
                <Bottle className="information__bottle-image" />
                <CloseIcon
                  className="information__close-icon"
                  onClick={handleCancel}
                />
              </div>
              <main className="ant-layout">
                <Row justify="center">
                  {productImage && (
                    <img
                      className="information__image"
                      src={productImage}
                      alt={t("PRODUCT")}
                    />
                  )}
                </Row>
                <Row
                  justify="center"
                  align="middle"
                  style={{
                    flexDirection: 'column',
                  }}
                >
                  <Col
                    xs={24}
                    md={16}
                    lg={24}
                    className="information__cta-wrapper"
                  >
                    <a
                      target="_blank"
                      href={t("SERVING_SUGGESTIONS_LINK")}
                      className="ant-btn ant-btn-tertiary ant-btn-round information__link"
                      rel="noreferrer"
                    >
                      <span>{t("SERVING_SUGGESTIONS")}</span>
                      <Arrow />
                    </a>
                  </Col>
                  <Col
                    xs={24}
                    md={16}
                    lg={24}
                    className="information__cta-wrapper"
                  >
                    <a
                      target="_blank"
                      href={t("CREATE_YOUR_OWN_LINK")}
                      className="ant-btn ant-btn-tertiary ant-btn-round information__link"
                      rel="noreferrer"
                    >
                      <span>{t("CREATE_YOUR_OWN_AT")}</span>
                      <Arrow />
                    </a>
                  </Col>
                </Row>
              </main>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default withRouter(Information);
