import axios from "axios";
import { stringify } from "query-string";
import { getApiBase } from "../../utils/pair/get-path";

export const Axios = {
  Request: async ({
    endpoint,
    method,
    endpointParams,
    payload,
    queryString,
    headerAttributes,
  }) => {
    const API_URL = getApiBase();
    console.log("API BASE URL :", API_URL)
    let url = `${API_URL}${endpoint}`;

    endpointParams?.forEach((val, index) => {
      url = url.replace(`{${index}}`, val);
    });
    return new Promise((resolve, reject) => {
      const query = queryString ? `?${stringify({ ...queryString })}` : "";
      axios
        .request({
          url: `${url}${query}`,
          data: payload,
          headers: getHeaders(headerAttributes),
          method: method.toLowerCase(),
        })
        .then((responseJson) => {
          if (responseJson.status >= 200 && responseJson.status < 300) {
            return resolve(responseJson);
          }
        })
        .catch((err) => {
          // TODO: THIS IS THE BUG ON MEGA FESTIVE / MIAB being just "e "
          const { status } = err.response;
          console.log("Error response: ", err.response);
          if (status === 500) {
            reject("Internal Server Error.");
            return;
          }
          if (status === 401) {
            reject("Unauthorised HTTP Request");
            return;
          }
          reject(err.response.message);
        });
    });
  },
};

const storedSettings = JSON.parse(localStorage.getItem("authurData"));
const getHeaders = (props) => ({
  "Content-Type": "application/json",
  ...(storedSettings && {
    Authorization: `Bearer ${storedSettings.access_token}`,
  }),
  ...props,
});
