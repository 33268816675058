import * as THREE from "three";
import { GetAssetPath } from "Services/apiService";

export class Coordinates {

  constructor(parent, color, duration, positionVector3) {

    const imagePath = GetAssetPath('talisker-coordinates.png');
    const texture = new THREE.TextureLoader().load(imagePath);

    // create plane 
    const geometry = new THREE.PlaneGeometry(0.24, 3.88, 2);
    const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y, positionVector3.z);
    return plane;
  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default Coordinates; // Don’t forget to use export default!