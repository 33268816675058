import { withRouter } from "react-router-dom";
import { Layout, Steps } from 'antd';
import './SideBar.scss';

import { ReactComponent as Bottle } from 'Assets/img/miab-bottle.svg';
import { useSideBar } from 'Hooks/useSideBar';
import { AnimatedContainer, AnimatedContainerWithPresence } from "../framer";
import { Children, memo } from "react";

const { Sider } = Layout;
const { Step } = Steps;
const areEqual = (prev, next) => {
  return prev.current === next.current
    && prev.animate === next.animate;
}
const SideBar = memo(() => {
  const {
    onChange,
    current,
    menu,
    animate,
    isNavigatable,
  } = useSideBar();

  if (current < 0) {
    return null;
  }

  return (
    <PureSideBar {...{
      onChange,
      current,
      menu,
      animate,
      isNavigatable,
    }} />
  );
});

const PureSideBar = memo(({ animate, current, onChange, menu, isNavigatable }) => {
  const SideMenu = () => (
    <Sider className="side-bar">
      <AnimatedContainer animate={animate} duration={2}>
        <Bottle className="bottle-image" />
      </AnimatedContainer>
      <AnimatedContainer animate={animate} duration={2.2}>
        <Steps
          current={current}
          onChange={onChange}
          direction="vertical"
        >
          {Children.toArray(menu.map((menu, index) => (
            <Step
              title={menu.title}
              description={menu.description}
              disabled={!isNavigatable(index) && (index !== current)}
              className={isNavigatable(index) || (index === current) ? 'navigateble' : 'navigateble__not'}
            />
          )))}
        </Steps>
      </AnimatedContainer>
    </Sider>
  );

  const AnimatedSideMenu = () => (
    <AnimatedContainerWithPresence key="AnimatedSideMenu">
      <SideMenu />
    </AnimatedContainerWithPresence>
  )
  return animate ? <AnimatedSideMenu /> : <SideMenu />;
}, areEqual)

export default withRouter(SideBar)