import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Parallelogram from "../common/Parallelogram.js"
import TextLine from "../common/textLine.js"

export class AnimationParallelogram_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	  var self = this;
 	     
       // create plane 
      const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
      const material1 = new THREE.MeshBasicMaterial( {color: this.colors[2], side: THREE.DoubleSide,transparent: true} );
      const plane1 = new THREE.Mesh( geometry1, material1 );
      this.parent.add( plane1 );

      // set plane initial state
      plane1.position.set(0,-350,-150);

      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined, "JWCapsSerif-Book", 30);
      this.parent.add(word);
      word.position.set(-6.5,6,-9);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.x = 1;
      gsap.to(word.material.map.offset,{x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration, x:-1, ease: "expo.inOut", duration:this.duration})
      
      gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration})

      this.createParallelogram(-50, this.duration/2, this.colors[0]);

      gsap.to(plane1.position,{delay:this.duration, y:340, ease: "expo.inOut", duration:this.duration, onComplete:function(){
          self.parent.remove(plane1);
          self.parent.remove(word);
      }});

  }

  createParallelogram(yPosition, startDelay, color){

  	 var parallelogram = new Parallelogram(this.parent, yPosition, startDelay, color, this.duration);

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationParallelogram_4; // Don’t forget to use export default!