import React from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
import { UnPair } from "../../store/actions/pair";
import "./pair.css";

const FinishPage = () => {
  const history = useHistory();
  console.log("FINISH")
  const dispatch = useDispatch();
  const state = useSelector((state) => state.order, shallowEqual);

  async function handleUnpairBtnClick(product) {
    const request = {
      MessageToken: product.messageToken,
      QrCode: product.qrCode,
    };

    dispatch(UnPair(request));
    history.push("/pair/unpair");
  }

  return (
    <section className="center-content">
      <div className="container sm:w-96 mx-auto">
        <Header />
        <div className=" bg-white">
          <div className="px-6 py-8">
            <p className="text-3xl text-black">Confirmed</p>
            <div className="mt-8">
              <label
                htmlFor="order-no"
                className="block text-sm font-bold text-black  mb-5"
              >
                Order number:
                <span className="font-normal">{state?.data?.OrderNumber}</span>
              </label>

              {state?.data?.Skus.map((product) => {
                return (
                  <div className="flex justify-between rounded-md border-green-600 border pb-5 pt-3 pl-6 pr-3 shadow-md">
                    <div>
                      <span className="green-btn mlm-15 rounded-md px-3 py-2 text-sm flex text-white mb-6">
                        <img
                          src="/Assets/img/icon-tick.svg"
                          className="mr-2"
                          alt=""
                        />
                        Pairing confirmed
                      </span>
                      <p className="font-bold text-sm mb-1">
                        Brand:{" "}
                        <span className="font-normal">{product.brand}</span>
                      </p>
                      <p className="font-bold text-sm">
                        SKU: <span className="font-normal">{product.name}</span>
                      </p>
                    </div>
                    <div>
                      <button
                        onClick={() => handleUnpairBtnClick(state.product)}
                        className="px-3 py-2 text-sm block text-black mb-5 font-bold underline"
                      >
                        Unpair
                      </button>

                      <img
                        className="w-16"
                        src={product.image}
                        alt={product.name}
                      />
                    </div>
                  </div>
                );
              })}

              <button
                onClick={() => history.push("pair")}
                className="btn-bg rounded-md px-6 py-3 font-bold text-sm inline-block mt-6"
              >
                Done
              </button>
            </div>

            <div className="pt-40 flex justify-between">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FinishPage;
