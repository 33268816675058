import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonLogo from "../common/SingletonLogo.js"
import OccasionSymbol from "../common/OccasionSymbol.js"
import TextLine from "../common/textLine.js"

export class AnimationOccasionTalisker extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword, occasionID) {
  	super(parent, colors, duration, keyword);
    this.occasionID = occasionID;
  }

  start(){
    
    const self = this;
    var color = this.colors[1];
    switch(this.occasionID){
      case 1261:
        this.animationFathersDay();
        color = this.colors[0];
        break;

      default:
        this.animationBirthDay();
        color = this.colors[1];
        break;
    }

    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: color, side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
    }})

  }

  animationFathersDay(){

    const self = this;

    const symbolContainer = new THREE.Group();
    this.parent.add(symbolContainer);
    symbolContainer.position.set(-16,37,0);

    for (var j = 0; j < 4; j++) {
      for (var g = 0; g < 4-j; g++) {
            var x = j*10;
            var y = -g*12;
            const positionVector3 = new THREE.Vector3(x-100, y, -50);
            var occasion = new OccasionSymbol(symbolContainer, this.colors[1], this.duration, positionVector3, "gift");
            gsap.to(occasion.position,{delay:0.05*g, x:x, ease: "expo.inOut", duration:this.duration});
            gsap.to(occasion.position,{delay:this.duration+0.05*j, y:y+100, ease: "expo.inOut", duration:this.duration});
      }
    }

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);
    wordContainer.position.set(0,-1,0);

   for (var i = 0; i < 3; i++) {

      var xPos = 3.2-i*1.6;
      var yPos = -2;
      
      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[1], undefined, 'DIN Condensed W05', -20);
      wordContainer.add(word);
      word.rotation.set(0,0,Math.PI/2);
      word.position.set(xPos,yPos, -1);

      //word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration, y:12, ease: "expo.inOut", duration:this.duration});
    }

    gsap.to(symbolContainer.position,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(symbolContainer);
      self.parent.remove(wordContainer);
    }});

  }

  animationBirthDay(){

    const self = this;
    const baloonContainer = new THREE.Group();
    this.parent.add(baloonContainer);

    baloonContainer.position.set(0, -30, 0);

    for (var j = 0; j < 6; j++) {

      const positionVector3 = new THREE.Vector3(0, j*12, -24);

      var occasion = new OccasionSymbol(baloonContainer, this.colors[0], this.duration, positionVector3, "baloon");
      occasion.rotation.set(0,0,Math.sin()*j/180);
      occasion.position.set(positionVector3.x,positionVector3.y-60,positionVector3.z);

      gsap.to(occasion.position,{delay:0.05*j, y:positionVector3.y, ease: "expo.inOut", duration:this.duration});
      gsap.to(occasion.rotation,{delay:0.05*j, z:0, ease: "expo.inOut", duration:this.duration});

      gsap.to(occasion.position,{delay:this.duration-0.05*j, y:positionVector3.y+60, ease: "expo.inOut", duration:this.duration});

    }

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 6; i++) {

      var xPos = -2;
      var yPos = -3.5+i*4;
      if(i>2){
        xPos = 2;
        yPos = -3.5+(i-3)*4;
      }

      var c = this.colors[0];
      if(i == 1 || i == 4){
        c = this.colors[2];
      }

      const word = new TextLine(this.keyword.toUpperCase(), 0.8, 0.8, 400, c, undefined, 'DIN Condensed W05', -20);
      wordContainer.add(word);
      word.position.set(xPos,yPos, 3);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});
    }
    
    gsap.to(baloonContainer.position,{delay:this.duration, y:1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(baloonContainer);
      self.parent.remove(wordContainer);
    }});

  }

}

export default AnimationOccasionTalisker; // Don’t forget to use export default!