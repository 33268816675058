import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"

export class AnimationMaskSingleton extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('singleton_1.png'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(50.8, 90.2, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		// set plane initial state
		plane1.position.set(0, -250, -90);
		//plane1.rotation.set(0,Math.PI/2,0);
		//plane1.scale.set(0,0,0);

		const tl = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[1], undefined, 'Korolev Compressed Bold');
		this.parent.add(tl);
		tl.position.set(0, -70, -1.5);

		self.plane1 = plane1;

		// animate
		gsap.to(texture.offset, { delay: 0, x: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })

		gsap.to(tl.position, { delay: 0, y: -3, ease: "expo.inOut", duration: this.duration })

		//gsap.to(texture.repeat,{delay:0, x:1, y:1, ease: "expo.inOut", duration:1})
		//gsap.to(plane1.rotation,{delay:0, y:0, ease: "expo.inOut", duration:1})
		//plane1.rotation.set(0,0,-Math.PI/2);
		//gsap.to(plane1.scale,{x:1, y:1, z:1, ease: "expo.inOut", duration:1})
		//gsap.to(plane1.rotation,{z:0, ease: "expo.inOut", duration:1})
		gsap.to(texture.offset, { delay: 0, delay: this.duration, x: 0, y: 1, ease: "expo.inOut", duration: 1 });
		gsap.to(tl.position, { delay: this.duration, y: 150, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, {
			y: 250, delay: this.duration, ease: "expo.inOut", duration: 1, onComplete: function () {
				//self.isAnimating = false;
				self.destroy();
			}
		});
	}

	destroy() {
		this.parent.remove(this.plane1);
	}

}

export default AnimationMaskSingleton; // Don’t forget to use export default!