import { useEffect, useState } from "react";
import framerConfig from '../framer-config.json';

export const useAnimatedText = ({ text = "", startAfter = framerConfig.TEXT_ANIMATION_START_AFTER }) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVisible(text !== "");
    }, 1000 * Number(startAfter));
  }, []);

  const words = text.split('').map(t => {
    if (t === ' ') return '\u00A0';
    return t;
  });
  const speed = framerConfig.ANIMATION_DURATION_TEXT / words.length;
  const charVerients = {
    enter: { y: '100%' },
    center: i => ({
      y: 0,
      transition: {
        delay: (i + 1) * speed,
      }
    }),
    exit: { y: '-100%' },
  }
  return {
    isVisible,
    speed,
    words,
    wrapperVarients: framerConfig.TEXT_WRAPPER_VARIENT,
    charVerients
  }
}