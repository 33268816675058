import * as THREE from "three";
import gsap from "gsap";
import { GetAssetPath } from "Services/apiService";

export class JWStripeHorizontal {

  constructor(parent, color, duration, positionVector3) {


    // load and set texture initial state
    const imagePath = GetAssetPath('JwStripe-h.png');
    const texture = new THREE.TextureLoader().load(imagePath);

    // create container
    const container = new THREE.Group();
    container.position.set(positionVector3.x - 30, positionVector3.y, positionVector3.z);
    parent.add(container);

    for (var i = 0; i < 3; i++) {

      // create plane 
      const geometry = new THREE.PlaneGeometry(25.75, 3.30, 2);
      const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
      const plane = new THREE.Mesh(geometry, material);
      container.add(plane);

      // set plane initial state
      plane.position.set(0, 33.5 * i, -20);

    }

    gsap.to(container.position, { x: positionVector3.x, ease: "expo.inOut", duration: duration });
    gsap.to(container.position, { delay: duration + 0.3, x: positionVector3.x + 300, ease: "expo.inOut", duration: duration });

  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default JWStripeHorizontal; // Don’t forget to use export default!