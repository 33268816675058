import { handleActions } from 'redux-actions';
import { REQUEST_STATUS } from 'Axios/constants';
import { defineLoopActions, requestLoopHandlers } from 'Axios/state';
import { apiAction } from 'Axios/apiCall';
import { ORDER } from '../actions/actionTypes';

const initialState = {
  isLoading: false,
  orderData: {},
  orderState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: order,
  success: orderSuccess,
  fail: orderFail,
} = defineLoopActions(ORDER);

export const fetchOrder = (data) => {
  const apiUrl = `/testOrder/generate`;

  return apiAction({
    url: apiUrl,
    method: 'POST',
    data: data,
    onStart: order,
    onSuccess: orderSuccess,
    onFailure: orderFail,
    label: ORDER,
  });
};

export const orderReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: ORDER,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          orderState: REQUEST_STATUS.SUCCESS,
          orderData: payload,
        };
      },
      stateField: 'orderState',
    }),
  },
  initialState
);
