import { shallowEqual, useSelector } from 'react-redux';
import {
  sanitiseColour,
  toUpperCase,
  sanitiseBackgroundMusic,
  sanitiseTexture,
  sanitiseProfilePic
} from 'Components/diageo-creative/creative-utils';
import { injectFontFace } from 'Components/diageo-creative/creative-utils/inject-font-face';
import testData from './creative.json';
import testDataNew from './creative-test.data.json';

export const useCreativeConfig = ({ isTest, productImage }) => {
  const userData = useSelector(state => state.messageUserReducer.data, shallowEqual);
  const {
    primaryColour,
    secondaryColour,
    tertiaryColour,
    profilePic,
    productSku,
    fonts,
    creative,
    occasionText,
    backgroundMusic,
    audioMessage,
    textMessage,
    words,
    texture,
  } = (isTest ? testDataNew : userData).creative;

  return {
    profilePic: sanitiseProfilePic(profilePic) || productImage,
    productSku,
    primaryColour: sanitiseColour(primaryColour),
    secondaryColour: sanitiseColour(secondaryColour),
    tertiaryColour: sanitiseColour(tertiaryColour),
    font: injectFontFace(fonts),
    creativity: toUpperCase(creative),
    occasionText,
    texture: sanitiseTexture(texture),
    backgroundMusic: sanitiseBackgroundMusic(backgroundMusic),
    audioMessage,
    textMessage,
    words,
  };
}