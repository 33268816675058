import { memo, useEffect } from "react";
import { DiageoCreative } from "Components/diageo-creative";

const TestRenderer = memo(() => {
  // Once QA is done - uncoment the folowings.
  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'production') {
  //     window.location.assign('/');
  //   }
  // }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      height: '778px',
      width: '439px',
      margin: '30px auto 0',
      padding: '2px',
      overflow: 'hidden'
    }}>
      <DiageoCreative width={312} height={675} isTest giftGiver="Haydn" />
    </div>
  );

})

export default TestRenderer;