import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import JWStripe from "../common/JWStripe.js"
import TextLine from "../common/textLine.js"

export class AnimationStripe_2 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    var self = this;
       
    // create plane 
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

        // set plane initial state
    plane1.position.set(0,-350,-150);

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 3; i++) {
      var c = this.colors[0];

      if(i == 1){
        c = this.colors[2];
      }

      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, c, undefined, "JohnnieWalkerWeb-Headline");
      wordContainer.add(word);
      word.position.set(0,-8+i*9,-2);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.x = 1;
      gsap.to(word.material.map.offset,{x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration, x:-1, ease: "expo.inOut", duration:this.duration});
    }
    

    var positionVector3 = new THREE.Vector3(18, -150, 0 );
    new JWStripe(this.parent, this.colors[0], this.duration, positionVector3, 2); 

     positionVector3 = new THREE.Vector3(-18, -150, 0 );
    new JWStripe(this.parent, this.colors[0], this.duration, positionVector3, 2); 

     gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

     gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(wordContainer);
      self.parent.remove(plane1);
    }});

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationStripe_2; // Don’t forget to use export default!