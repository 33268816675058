import { memo } from "react";
import { Switch, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { scrollToTop } from "Utils/scroll-to-top";

export const AnimatedRouter = memo(({ children, subscribeOnExitComplete = false }) => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter initial onExitComplete={() => (subscribeOnExitComplete && scrollToTop())}>
      <Switch location={location} key={location.key} >
        {children}
      </Switch>
    </AnimatePresence>
  );
});