import { memo, useEffect, useRef, useState } from "react";
import { useCreativeConfig } from "Hooks/creative/useCreativeConfig";
import { ThreeFramework } from "./three-framework";
import { PlayPause } from "./play-pause";
import './style.scss';

export const DiageoCreative = memo(({ width, height, statusFeedback, isTest = false, productImage, giftGiver }) => {
  const creativeConfig = useCreativeConfig({ isTest, productImage });
  const mount = useRef(null);
  const [playing, setPlaying] = useState(false);

  const feedback = (flag) => {
    statusFeedback && statusFeedback(flag);
    setPlaying(flag);
  }
  const onStopping = () => {
    feedback(false);
  }
  const threeFrameworkRef = useRef();
  useEffect(() => {
    if (creativeConfig && mount?.current && !threeFrameworkRef?.current) {
      handleCreative();
    }
  }, [creativeConfig, mount]);

  const handleCreative = () => {
    if (playing) {
      threeFrameworkRef.current.destroy();
    } else {
      threeFrameworkRef?.current?.removeCanvas();
      threeFrameworkRef.current = new ThreeFramework();
      threeFrameworkRef.current.init({
        ...creativeConfig,
        mount,
        width,
        height,
        onStop: onStopping
      });
      feedback(true);
    }
  }
  return (
    <div className="DiageoCreative">
      <div className="DiageoCreativeContainer" style={{ width: width }} ref={mount} />
      <PlayPause {...{
        playing,
        imageSrc: creativeConfig?.profilePic,
        handleCreative,
        label: 'MESSAGE SENT BY',
        caption: giftGiver
      }} />
    </div>
  );
});