import React, { Component, createRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./Audio.scss";
import { ReactComponent as PlayIcon } from "Assets/img/play.svg";
import { ReactComponent as PauseIcon } from "Assets/img/pause.svg";
class AudioPlayer extends Component {
  state = {
    isPlaying: false,
    isPaused: false,
    colors: [["#131314", 1]],
  };
  timeout = null;
  audioEl = createRef();

  startAudioPlayer = async () => {
    this.setState((prev) => ({
      ...prev,
      isPlaying: true,
      isPaused: false,
    }));

    this.audioEl.current.play();
    this.timeout = setTimeout(() => {
      if (!this.state.isPaused) {
        this.props.afterPlay();
      }
    }, this.props.duration * 1000);
  };

  pauseAudioPlayer = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setState((prev) => ({
      ...prev,
      isPaused: true,
      isPlaying: false,
    }));
    this.audioEl.current.pause();
  };

  resetAudioPlayer = () => {
    if (this.state.isPlaying === true) {
      this.setState((prev) => ({
        ...prev,
        isPlaying: false,
        isPaused: false,
      }));
    }
  };

  render() {
    const durationSeconds = this.props.duration;
    const { colors, isPaused, isPlaying } = this.state;
    const blobURL = this.props.blobURL;
    const renderTime = (value) => {
      let prefix = "00:";
      if (value <= 10) {
        prefix += "0";
      }

      if (value === durationSeconds) {
        return (
          <div className="timer">
            <button onClick={this.startAudioPlayer}>
              <span className="offset">
                <PlayIcon />
              </span>
            </button>
            <div>
              {prefix}
              {durationSeconds}
            </div>
          </div>
        );
      }

      if (value <= durationSeconds) {
        return (
          <div className="timer">
            {!isPaused && (
              <button onClick={this.pauseAudioPlayer}>
                <span className="offset">
                  <PauseIcon />
                </span>
              </button>
            )}
            {isPaused && (
              <button onClick={this.startAudioPlayer}>
                <span className="offset">
                  <PlayIcon />
                </span>
              </button>
            )}
            <div>
              {prefix}
              {value}
            </div>
          </div>
        );
      }
    };

    return (
      <>
        <CountdownCircleTimer
          isPlaying={isPlaying}
          size={280}
          durationSeconds={durationSeconds}
          colors={colors}
          trailColor={"rgba(0,0,0,0.1"}
          strokeWidth={5}
          renderTime={renderTime}
          onComplete={() => [false]}
        />
        <audio ref={this.audioEl} className="audio-element">
          <source src={blobURL} />
        </audio>
      </>
    );
  }
}

export default AudioPlayer;
