import * as THREE from "three";
import gsap from "gsap";
import { GetAssetPath } from "Services/apiService";

export class JWStripe {

  constructor(parent, color, duration, positionVector3, type) {



    var image = "JwStripe.png";

    if (type == 2) {
      image = "JwStripe2.png";
    }

    const imagePath = GetAssetPath(image);
    const texture = new THREE.TextureLoader().load(imagePath);

    // create container
    const container = new THREE.Group();
    container.position.set(positionVector3.x, positionVector3.y - 110, positionVector3.z);
    parent.add(container);

    for (var i = 0; i < 8; i++) {

      // create plane 
      const geometry = new THREE.PlaneGeometry(11.7, 25.5, 2);
      const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
      const plane = new THREE.Mesh(geometry, material);
      container.add(plane);

      // set plane initial state
      plane.position.set(0, 28 * i, -50);

    }

    gsap.to(container.position, { y: positionVector3.y, ease: "expo.inOut", duration: duration });

    gsap.to(container.position, {
      delay: duration, y: positionVector3.y + 250, ease: "expo.inOut", duration: duration, onComplete: function () {
        parent.remove(container);
      }
    });

  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default JWStripe; // Don’t forget to use export default!