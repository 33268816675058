import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { TRANSLATION } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  translationData: null,
  translationState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(TRANSLATION);

export const fetchTranslation = (code, isOrder) => {
  const data = { ...(isOrder ? { orderToken: code } : { qrCode: code }) };
  return apiAction({
    url: `/translation/${
      isOrder ? "getTranslationsByOrder" : "getTranslationsByQr"
    }`,
    onStart,
    onSuccess,
    onFailure,
    label: TRANSLATION,
    data,
    method: "POST",
  });
};

export const fetchLocalTranslations = () => {
  return apiAction({
    url: `/translation/getTranslations`,
    onStart,
    onSuccess,
    onFailure,
    label: TRANSLATION,
    data:  { cultureCode: navigator?.language || 'en-GB' },
    method: "POST",
  });
};

export const translationReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: TRANSLATION,
      onSuccess: (state, payload) => {
        return {
          ...state,
          translationState: REQUEST_STATUS.SUCCESS,
          translationData: payload,
        };
      },
      stateField: "translationState",
    }),
  },
  initialState
);

export const translationReducerSelector = (state) => {
  return state.translationReducer.translationData;
};
