import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as actions from "Store/actions/index";

export const useNavigatorOfftrade = () => {
  const { mediaDevices, permissions } = navigator;
  const canRecord = mediaDevices ?? false;
  const dispatch = useDispatch();
  const history = useHistory();

  const navigate = (msgType) => {
    dispatch(actions.setSlug(msgType));
    history.push("/consumer/select-brand/");
  };
  const handleMsgTypeClick = (msgType) => {
    const newType = msgType === "audio" && canRecord ? "audio" : "text";

    dispatch(actions.setOnlyWrittenMsg(false));
    navigate(newType);

    if (newType === "audio") {
      // mediaDevices
      //   .getUserMedia({ audio: true })
      //   .then((stream) => {
      //     // alert(`You will even be prompted to leave a voice message.`);
      //     dispatch(actions.setOnlyWrittenMsg(false));
      //     navigate(newType);
      //     /*
      //     permissions.query({ name: 'microphone' })
      //     .then(result => {
      //       if (result.state === 'granted') {
             
      //         dispatch(actions.setOnlyWrittenMsg(false))
      //         navigate(newType);
      //       }
      //       result.onchange = () => {
      //         dispatch(actions.setOnlyWrittenMsg(false))
      //         navigate(newType)
      //       }
      //     })
      //      */
      //   })
      //   .catch((err) => {
      //     alert(
      //       `Microphone is not available to record the message. Please continue so that you can send a written message.`
      //     );
      //     dispatch(actions.setOnlyWrittenMsg(true));
      //     navigate("text");
      //   });
    } else {
      // dispatch(actions.setOnlyWrittenMsg(false));
      // navigate(newType);
    }
  };
  return { canRecord, handleMsgTypeClick };
};
