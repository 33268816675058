import * as actions from "../actions/actionTypes";

const initialState = {
  slug: "audio",
  bottleId: -1,
  customerEmail: '',
  selectedBottles: [],
  occasionData: null,
  giftData: null,
  onlyWrittenMsg: false,
  audioBlob: null,
  writtenTextMesssage: null,
  image: null,
  productKey: null,
  brandKey: null,
  offtrade: false,
  retailerId: null,
  qrCode: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ROUTING_SET_SLUG:
      return setSlug(state, action);
    case actions.SET_CUSTOMER_EMAIL:
      return setCustomerEmail(state, action);
    case actions.SET_JOURNEY:
      return setJourney(state, action);
    case actions.SET_QR_CODE:
      return setQrCode(state, action);
    case actions.SET_RETAILER_ID:
      return setRetailerId(state, action);
    case actions.SET_BOTTLE_ID:
      return setBottleId(state, action);
    case actions.SET_SELECTED_BOTTLES:
      return setSelectedBottles(state, action);
    case actions.SET_OCCASION_DATA:
      return setOccasionData(state, action);
    case actions.SET_GIFT_DATA:
      return setGiftData(state, action);
    case actions.SET_ONLY_WRITTEN_MESSAGE:
      return setOnlyWrittenMsg(state, action);
    case actions.CLEAR_COMMON_DATA:
    case actions.SET_COMMON_DATA:
      return setCommonData(state, action);
    case actions.SET_BRAND_KEY:
      return setBrandKey(state, action);
    case actions.SET_PRODUCT_KEY:
      return setProductKey(state, action);
    default:
      return state;
  }
};
const setCommonData = (state, action) => {
  return {
    ...state,
    ...action.payload,
  };
};

const setSlug = (state, action) => {
  return {
    ...state,
    slug: action.payload,
  };
};

const setCustomerEmail = (state, action) => {
  return {
    ...state,
    customerEmail: action.payload,
  };
};

const setJourney = (state, action) => {
  return {
    ...state,
    offtrade: action.payload,
  };
};

const setQrCode = (state, action) => {
  return {
    ...state,
    qrCode: action.payload,
  };
};

const setRetailerId = (state, action) => {
  return {
    ...state,
    retailerId: action.payload,
  };
};

const setBrandKey = (state, action) => {
  return {
    ...state,
    brandKey: action.payload,
  };
};

const setProductKey = (state, action) => {
  return {
    ...state,
    productKey: action.payload,
  };
};

const setBottleId = (state, action) => {
  return {
    ...state,
    bottleId: action.payload,
  };
};

const setSelectedBottles = (state, action) => {
  return {
    ...state,
    selectedBottles: action.payload,
  };
};

const setOccasionData = (state, action) => {
  return {
    ...state,
    occasionData: action.payload,
  };
};

const setGiftData = (state, action) => {
  return {
    ...state,
    giftData: action.payload,
  };
};

const setOnlyWrittenMsg = (state, action) => {
  return {
    ...state,
    onlyWrittenMsg: action.payload,
  };
};

export default reducer;
