import { LayoutGroupContext } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Logo } from "../../assets/img/logo-wordmark-white.svg";

const Header = () => {
  function logout(event) {
    console.log("logout");
    localStorage.removeItem("authurData")
    //setUserIsAuthenticated(false);
    //window.location.reload(false);
    window.location.href = "/pair";
  }

  return (
    <div className="flex justify-between px-5 py-4 bg-black">
      <div>
        <Logo className="logo" />
      </div>
      <div>
        <button onClick={logout} className="rounded-md px-6 py-3 font-bold text-sm text-white border-white border block logout-btn">Logout</button>
      </div>
    </div>
  );
};

export default Header;
