import * as THREE from "three";
import gsap from "gsap";
import { GetAssetPath } from "Services/apiService";

export class Bottle {

  constructor(parent, color, duration, positionVector3) {

    const imagePath = GetAssetPath('bottle.png');

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(imagePath);

    // create plane 
    const geometry = new THREE.PlaneGeometry(4.80, 18.10, 2);
    const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y - 30, positionVector3.z);

    gsap.to(plane.position, { y: positionVector3.y, ease: "expo.inOut", duration: duration });
    gsap.to(plane.position, {
      delay: duration, y: positionVector3.y + 60, ease: "expo.inOut", duration: duration, onComplete: function () {
        parent.remove(plane);
      }
    });

  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default Bottle; // Don’t forget to use export default!