import React, { useContext, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQueryString } from 'Hooks/useQueryString';
import { getTranslation } from 'Services/translationService';
import { tokenReducerSelector } from 'Store/reducers/token';
import { translationReducerSelector } from 'Store/reducers/translation';

export const TranslationContext = React.createContext({});

export const TranslationProvider = ({ children }) => {
  const { isOrder, codeOrToken } = useQueryString();
  const text = useSelector(translationReducerSelector, shallowEqual);
  const authToken = useSelector(tokenReducerSelector);
  const t = (key = "", params = []) => {
    if (!key || !text) return key;
    let temp = key.split(":");
    if (temp.length === 1) {
      temp = ["translation", ...temp];
    }
    const [root, k] = temp;
    let str = text[root][k];
    (params || []).forEach((p, i) => {
      str = str.replace(`{${i}}`, p);
    });
    if (/<\/?[^>]*>/.test(str)) {
      return (
        <span dangerouslySetInnerHTML={{ __html: str }} />
      );
    }
    return str;
  }
  const dispatch = useDispatch();
  useEffect(() => {
    if (!text && authToken) {
      dispatch(getTranslation(codeOrToken, isOrder));
    }
  }, [text, authToken])
  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
}

export const useTranslation = () => {
  const context = useContext(TranslationContext);

  return context;
}
