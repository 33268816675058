import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"
import TaliskerLogo from "../common/TaliskerLogo.js"

export class AnimationBrandTalisker_3 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	var self = this;

	// create plane  2
	const geometry2 = new THREE.PlaneGeometry(200 , 400, 2 );
	const material2 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
	const plane2 = new THREE.Mesh( geometry2, material2 );
	this.parent.add( plane2 );

	// set plane initial state
	plane2.position.set(0, -300,-200);
	plane2.scale.set(1,0,1);

	const word = new TextLine(this.keyword.toUpperCase(), 1.7, 1.7, 400, this.colors[0], undefined, 'DIN Condensed W05', -20);
	this.parent.add(word);
	word.position.set(0,-3,-1);
	word.material.map.offset.y = 1;
	gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration})
	gsap.to(word.material.map.offset,{delay:this.duration, y:-1, ease: "expo.inOut", duration:this.duration})

	const logoContainer = new THREE.Group();
	this.parent.add(logoContainer);

	logoContainer.position.set(6.5,3,0);

	for (var i = 0; i < 3; i++) {
		var xTrg = -i*6;
		const positionVector3 = new THREE.Vector3(xTrg-20, 0, -7);
		const logo = new TaliskerLogo(logoContainer, this.colors[0], this.duration, positionVector3);
		gsap.to(logo.position,{delay:0.05*i, x:xTrg, ease: "expo.inOut", duration:this.duration});
		gsap.to(logo.position,{delay:this.duration+0.05*i, x:xTrg+20, ease: "expo.inOut", duration:this.duration});
		//gsap.to(word.material.map.offset,{delay:this.duration+0.05*i, y:-1, ease: "expo.inOut", duration:this.duration})
	}

	// animate
	gsap.to(plane2.scale,{y:1, ease: "expo.inOut", duration:this.duration})
	gsap.to(plane2.position, {y: 0, delay:0, ease: "expo.inOut", duration:this.duration});
	gsap.to(plane2.position, {y: 400, delay:this.duration, ease: "expo.inOut", duration:this.duration, onComplete:function(){
		//self.isAnimating = false;
		self.parent.remove(word);
		self.parent.remove(logoContainer);
		
		self.parent.remove(plane2);
	
	}});

  }

  destroy(){

  }
   
}

export default AnimationBrandTalisker_3; // Don’t forget to use export default!