// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/cursor.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".timer button{display:block;margin-top:2rem;border:0;background-color:rgba(0,0,0,0);color:#131314;cursor:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),auto}.timer button svg{display:block;margin:0 auto 2rem auto;padding:1rem;height:54px;width:54px;border:1px solid #131314;border-radius:50%;fill:#131314;transition:background-color .3s ease-in-out,fill .3s ease-in-out}.timer:hover button svg{background-color:#131314;fill:#fff}", "",{"version":3,"sources":["webpack://./src/components/Audio/Audio.scss","webpack://./src/assets/sass/_colors.scss"],"names":[],"mappings":"AAGI,cACI,aAAA,CACA,eAAA,CACA,QAAA,CACA,8BAAA,CACA,aCNG,CDOH,mDAAA,CAEA,kBACI,aAAA,CACA,uBAAA,CACA,YAAA,CACA,WAAA,CACA,UAAA,CACA,wBAAA,CACA,iBAAA,CACA,YCjBD,CDkBC,gEAAA,CAKJ,wBACI,wBCxBD,CDyBC,SC1BJ","sourcesContent":["@import '../../assets/sass/colors';\r\n\r\n.timer {\r\n    button {\r\n        display: block;\r\n        margin-top: 2rem;\r\n        border: 0;\r\n        background-color: transparent;\r\n        color: $offblack;\r\n        cursor: url('../../assets/img/cursor.svg'), auto;\r\n\r\n        svg {\r\n            display: block;\r\n            margin: 0 auto 2rem auto;\r\n            padding: 1rem;\r\n            height: 54px;\r\n            width: 54px;\r\n            border: 1px solid $offblack;\r\n            border-radius: 50%;\r\n            fill: $offblack;\r\n            transition: background-color 0.3s ease-in-out, fill 0.3s ease-in-out;\r\n        }\r\n    }\r\n\r\n    &:hover {\r\n        button svg {\r\n            background-color: $offblack;\r\n            fill: $white;\r\n        }\r\n    }\r\n}","$black: #000000;\r\n$white: #ffffff;\r\n$offblack: #131314;\r\n$darkgrey: #353535;\r\n\r\n$yellow: #ffd84b;\r\n$red: #e90334;"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
