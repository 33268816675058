var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import BasicPlayer from './basic-player/BasicPlayer';
import { useTranslation } from 'Contexts/translation-context';
import { classlist } from 'easy-class';
import TextFitter from 'Components/Layout/TextFitter';
import { ReactComponent as BottleStacked } from 'Assets/img/miab-bottle-stacked-greek.svg';
import './BasicExperience.scss';
var BasicExperience = function (_a) {
    var statusFeedback = _a.statusFeedback, onEnd = _a.onEnd, giftAudio = _a.giftAudio, width = _a.width, height = _a.height, _b = _a.isPreview, isPreview = _b === void 0 ? false : _b, _c = _a.isLive, isLive = _c === void 0 ? false : _c;
    var t = useTranslation().t;
    var _d = useState('PLAY'), playString = _d[0], setPlayString = _d[1];
    var _e = useState('MESSAGE'), messageString = _e[0], setMessageString = _e[1];
    var changeStatus = function (value) {
        if (statusFeedback)
            statusFeedback(value);
    };
    useEffect(function () {
        var playSplit = t('PLAY_MESSAGE').split(' ');
        setPlayString(playSplit[0]);
        setMessageString(playSplit[1]);
    }, [t]);
    return (_jsxs("div", __assign({ className: classlist('BasicExperience', isPreview && 'isPreview', isLive && 'isLive'), style: { height: height, width: width } }, { children: [_jsx("div", __assign({ className: "BasicExperience__header" }, { children: _jsx(BottleStacked, {}) })), _jsxs("div", __assign({ className: "BasicExperience__body" }, { children: [_jsx(TextFitter, __assign({ allowGrow: true, matchParent: true, style: { transformOrigin: '50% 100%' } }, { children: _jsx("p", __assign({ className: "BasicExperience__body__primary" }, { children: playString })) })), _jsx(TextFitter, __assign({ allowGrow: true, matchParent: true, style: { transformOrigin: '50% 0' } }, { children: _jsx("p", __assign({ className: "BasicExperience__body__secondary" }, { children: messageString })) }))] })), _jsx("span", __assign({ className: "BasicExperience__player" }, { children: _jsx(BasicPlayer, { audioFile: giftAudio, onEnd: onEnd, statusFeedback: changeStatus }) }))] })));
};
export default BasicExperience;
