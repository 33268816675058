import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Parallelogram from "../common/Parallelogram.js"

export class AnimationParallelogram extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	var self = this;
    this.createParallelogram(30, 0.2, this.colors[2]);
 	  this.createParallelogram(-30, 0, this.colors[2]);
 
  }

  createParallelogram(yPosition, startDelay, color){

  	 var parallelogram = new Parallelogram(this.parent, yPosition, startDelay, color, this.duration);

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationParallelogram; // Don’t forget to use export default!