import * as THREE from "three";
import gsap from "gsap";

export class Parallelogram {
	
  constructor(parent, yPosition, startDelay, color, duration) {

      // create container
      const parallelogram = new THREE.Group();
      parallelogram.position.set(-50,yPosition,-100);
      parent.add(parallelogram);


      const self = this;
      // create stripes
      for (var i = 0; i < 50; i++) {

        // create plane 
        const geometry = new THREE.PlaneGeometry(1-(0.1+(i*0.015)), 50, 2 );
        const material = new THREE.MeshBasicMaterial( {color: color, side: THREE.DoubleSide} );
        const plane = new THREE.Mesh( geometry, material );
        parallelogram.add( plane );

        // set plane initial state
        plane.position.set(2*i,0.5*i,0);
        plane.scale.set(1,0,1);
        plane.n= i;
        // animate
        gsap.to(plane.scale,{y:1, ease: "expo.inOut", delay:startDelay+0.005*i, duration:duration/2});
        gsap.to(plane.scale,{y:0, delay:startDelay+duration/2, ease: "expo.inOut", duration:duration/2});
      }

       gsap.to(parallelogram.position,{y:yPosition+10, delay:startDelay+duration/2, ease: "expo.inOut", duration:duration/2, onComplete:function(){
          parent.remove(parallelogram);
       }});
  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default Parallelogram; // Don’t forget to use export default!