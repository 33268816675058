import { Axios } from "../axios/pair";

export const PairService = {
  async GetOrder(payload) {
    try {
      return Axios.Request({
        endpoint: "fulfilment/order",
        method: "POST",
        payload,
      });
    } catch (err) {
      console.error(err);
    }
  },
  async Pair(payload) {
    return Axios.Request({
      endpoint: "fulfilment/pair",
      method: "POST",
      payload,
    });
  },
  async UnPair(payload) {
    return Axios.Request({
      endpoint: "fulfilment/unpair",
      method: "POST",
      payload,
    });
  },
};
