import React, { useEffect, useState } from "react";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as MiabLogo } from "Assets/img/miab-logo.svg";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { ReactComponent as Microphone } from "Assets/img/microphone.svg";
import { useTranslation } from "../../contexts/translation-context";
import { Heading } from "../../components/heading/heading";
import { useNavigatorOfftrade } from "../../hooks/consumerOfftrade/useNavigator";
import "./Start.scss";
import "./Consumer.scss";
import { Button } from "antd";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { useQrCode } from "../../hooks/useQrcode";
import useAllowTextJourney from "../../hooks/useAllowTextJourney";
import { useDispatch, useSelector } from "react-redux";

import {
  setJourney,
  setQrCode,
  setRetailerId,
} from "../../store/actions/common";
import { fetchOfftradeTranslations } from "../../store/reducers/offtrade";

const StartPage = () => {
  const { qrCode, isOffTrade, retailerId } = useQrCode();
  const { handleMsgTypeClick } = useNavigatorOfftrade();
  const dispatch = useDispatch();

  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const translation = offTradeTranslationData?.consumerOffTrade;
  const allowTextJourney = useAllowTextJourney();

  useEffect(() => {
    if (qrCode && qrCode.length > 0) {
      dispatch(setQrCode(qrCode));
    }
  }, [dispatch, qrCode]);

  useEffect(() => {
    if (retailerId && retailerId.length > 0) {
      dispatch(setRetailerId(retailerId));
    }
  }, [dispatch, retailerId]);

  useEffect(() => {
    if (isOffTrade) {
      dispatch(setJourney(isOffTrade));
    }
  }, [dispatch, isOffTrade]);

  useEffect(() => {
    if (offTradeTranslationData) return;
    console.log("Translation to fetch is using the qrCode: ", qrCode);
    dispatch(fetchOfftradeTranslations(qrCode));
  }, [dispatch, offTradeTranslationData, qrCode]);

  return (
    <div className="page-wrap page-wrap--dark sway-wrap invert-wrap">
      <div className="consumer-navigation consumer-navigation--start">
        <Bottle className="consumer-navigation__bottle" />
        <div className="nav-menu">
          <div className="nav-menu">
            <Link to="/consumer/faq" className="help-link">
              {translation?.NeedHelp}
            </Link>
          </div>
        </div>
      </div>
      <section className="ant-layout">
        <main className="ant-layout">
          <div className="container">
            <div className="consumer-heading consumer-heading">
              <h1 className="consumer-heading__title-wrapper consumer-heading__title-wrapper--start">
                <span className="consumer-heading__title consumer-heading__title--start">
                  {translation?.MainHeading}
                </span>
                <span className="font-tight-x-small">
                  {translation?.SubHeading}
                </span>
              </h1>
            </div>
            <p className="consumer-heading__intro font-spatial-xx-small">
              {translation?.Heading3}
            </p>
            <h1 className="consumer-heading__bottle multi-size">
              <Heading keyName="SEND_A" />
              <MiabLogo className="miab-logo" />
            </h1>
            <div className="welcome-content">
              <p className="consumer-heading__body">{translation?.Intro}</p>
              <div className="sway-button-invert">
                <Button
                  type="primary"
                  shape="round"
                  onClick={() => handleMsgTypeClick("audio")}
                >
                  {translation?.CTatext}
                  <Microphone />
                </Button>
              </div>
              {allowTextJourney && (
                <>
                  <p className="consumer-welcome-text">
                    {translation?.TypeIntro}
                  </p>
                  <Button
                    type="tertiary"
                    shape="round"
                    onClick={() => handleMsgTypeClick("text")}
                  >
                    {translation?.SecondaryCta}
                  </Button>
                </>
              )}
            </div>
          </div>
        </main>
      </section>
    </div>
  );
};

export default StartPage;
