import axios from 'axios';
import { apiUrl } from 'Utils/common';

const instance = axios.create({
  baseURL: apiUrl(),
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
});

export default instance;
