import React from "react";
import { Button } from "react-router-dom";
import { ReactComponent as Logo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowLeft } from "Assets/img/arrow-left.svg";
import { ReactComponent as ArrowRight } from "Assets/img/arrow-right.svg";
import { useHistory, Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./Expiry.scss";
import "./Consumer.scss";
import { useSelector } from "react-redux";

const ExpiryPage = () => {
  const history = useHistory();
  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const translation = offTradeTranslationData?.consumerOffTrade;
  const expirationTranslation = translation?.MessageExpirationInfo;
  const cta = expirationTranslation?.Cta;

  return (
    <div className="guide age-wrap page-wrap--light">
      <div className="consumer-navigation">
        <Logo className="consumer-navigation__logo" />
        <Bottle className="consumer-navigation__bottle" />
        <div className="nav-menu">
          <Link to="/consumer/faq" className="help-link">
            {translation?.NeedHelp}
          </Link>
        </div>
      </div>
      <section className="ant-layout">
        <main className="ant-layout">
          <div className="container">
            <div className="expiry__title-wrapper">
              <h1 class="font-spatial-normal font-spatial-normal--alt">
                {expirationTranslation?.Title},
              </h1>
              <button
                className="consumer__back-btn"
                onClick={() => history.goBack()}
              >
                <ArrowLeft />
                <span>{translation?.Back}</span>
              </button>
            </div>
            <Row justify="center" align="middle">
              <Col xs={24} md={20} lg={20}>
                <h2 className="expiry__subtitle font-tight-x-large font-tight-x-large--alt">
                  {expirationTranslation?.SubTitle}
                </h2>
                <div className="expiry__intro">
                  <p className="font-longform">
                    {expirationTranslation?.IntroText}
                  </p>
                </div>
                <p className="expiry__body">
                  {expirationTranslation?.AditionalInfo}
                </p>
                <button
                  type="button"
                  className="ant-btn ant-btn-primary ant-btn-round"
                  onClick={() => history.push(cta?.Url)}
                >
                  <span>{cta?.Text}</span>
                  <ArrowRight />
                </button>
              </Col>
            </Row>
          </div>
        </main>
      </section>
    </div>
  );
};

export default ExpiryPage;
