import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthorable } from "../useAuthorable";
import { loadMessages } from "Services/messagesService";
import { singleMessagesSelector } from "Store/reducers/messages";
import { getImageUrl } from "Utils/common";
import { OrderToken } from "Utils/web-storage";

export const useSingleMessage = () => {
  const bottleId = useSelector(state => state.common.bottleId);
  const messageData = useSelector(singleMessagesSelector(bottleId));
  const dispatch = useDispatch();
  useAuthorable();
  useEffect(() => {
    if (messageData.length === 0) {
      const orderToken = OrderToken().value;
      dispatch(loadMessages(orderToken));
    }
  }, [messageData]);
  return {
    productImg: getImageUrl(messageData.product_image),
    productName: messageData.product_name || '',
    messageToken: messageData.message_token,
    messageDataLength: messageData.length,
    consumerId: messageData.consumer_id,
  }
}