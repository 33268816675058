
// Black Label set 1
export const AnimationMask = require('./animationMask').default
export const AnimationMask_1 = require('./animationMask_1').default
export const AnimationMask_2 = require('./animationMask_2').default
export const AnimationMask_3 = require('./animationMask_3').default
export const AnimationMask_4 = require('./animationMask_4').default

// Black Label set 2
export const AnimationParallelogram = require('./animationParallelogram').default
export const AnimationParallelogram_1 = require('./animationParallelogram_1').default
export const AnimationParallelogram_2 = require('./animationParallelogram_2').default
export const AnimationParallelogram_3 = require('./animationParallelogram_3').default
export const AnimationParallelogram_4 = require('./animationParallelogram_4').default

// Black Label set 3
export const AnimationWalkingMan = require('./animationWalkingMan').default
export const AnimationWalkingMan_1 = require('./animationWalkingMan_1').default
export const AnimationWalkingMan_2 = require('./animationWalkingMan_2').default
export const AnimationWalkingMan_3 = require('./animationWalkingMan_3').default
export const AnimationWalkingMan_4 = require('./animationWalkingMan_4').default

// Black Label set 4
export const AnimationStripe = require('./animationStripe').default
export const AnimationStripe_1 = require('./animationStripe_1').default
export const AnimationStripe_2 = require('./animationStripe_2').default
export const AnimationStripe_3 = require('./animationStripe_3').default
export const AnimationStripe_4 = require('./animationStripe_4').default

// Black Label set 5
export const AnimationBottle = require('./animationBottle').default
export const AnimationBottle_1 = require('./animationBottle_1').default
export const AnimationBottle_2 = require('./animationBottle_2').default
export const AnimationBottle_3 = require('./animationBottle_3').default
export const AnimationBottle_4 = require('./animationBottle_4').default

export const AnimationOccasionJW = require('./animationOccasionJW').default

