import { PairService } from "../../services/pairService";
import { FetchOrderType } from "../reducers/pair";

export const fetchOrder = (payload) => async (dispatch) => {
  console.log("fetchOrder-payload",payload)

  dispatch({
    type: FetchOrderType.FETCHING,
  });

  try {
    const result = await PairService.GetOrder(payload);
    dispatch({
      type: FetchOrderType.SUCCESS,
      payload: {
        data: result.data,
        errors: null,
      },
    });
  } catch (error) {
    console.log("fetchOrder error:", error)
    dispatch({
      type: FetchOrderType.FAILURE,
      payload: { error },
    });
  }
};

export const Pair = (payload) => async (dispatch) => {
  console.log("Pair-payload",payload)
  dispatch({
    type: FetchOrderType.FETCHING,
  });

  try {
    const result = await PairService.Pair(payload);
    console.log("Pair", result)
    dispatch({
      type: FetchOrderType.SUCCESS,
      payload: {
        data: result.data,
        errors: null,
      },
    });
  } catch (error) {
    dispatch({
      type: FetchOrderType.FAILURE,
      payload: { error },
    });
  }
};

export const UnPair = (payload) => async (dispatch) => {
  console.log("UnPair-payload",payload)

  dispatch({
    type: FetchOrderType.FETCHING,
  });

  try {
    const result = await PairService.UnPair(payload);
    dispatch({
      type: FetchOrderType.SUCCESS,
      payload: {
        data: result.data,
        errors: null,
      },
    });
  } catch (error) {
    dispatch({
      type: FetchOrderType.FAILURE,
      payload: { error },
    });
  }
};
