import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Modal, Button } from "antd";
import ReactAvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import {
  fetchAddProfileImage,
  fetchDeleteProfileImage,
} from "Store/reducers/image";

import "./Image.scss";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { ReactComponent as PlusIcon } from "Assets/img/plus.svg";
import { ReactComponent as CloseIcon } from "Assets/img/close.svg";
import { ReactComponent as ConfirmIcon } from "Assets/img/confirm.svg";
import { ReactComponent as CameraIcon } from "Assets/img/camera.svg";
import { ReactComponent as ImageIllustration } from "Assets/img/image-illustration.svg";
import { getPayload } from "Utils/get-payload";
import { clearRecording } from "Store/reducers/record";
import { clearTextRecording } from "Store/reducers/record-text";
import { TranslationContext } from "Contexts/translation-context";
import { Heading } from "Components/heading";
import TextFitter from 'Components/Layout/TextFitter';

export class Image extends React.Component {
  static contextType = TranslationContext;
  state = {
    image: "",
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 160,
    width: 320,
    height: 320,
    isModalVisible: false,
    uploadedImage: "",
    alertImage: false,
  };
  componentDidMount() {
    const { messagesData } = this.props;

    if (messagesData.length === 0) {
      this.props.history.push("/author/main-steps");
      return;
    }
  }
  componentWillUnmount() {
    this.props.clearRecording();
    this.props.clearTextRecording();
  }

  componentDidUpdate(prevProps) {
    if (this.props.addProfileImageState !== prevProps.addProfileImageState) {
      if (this.props.addProfileImageState === "request_fail") {
        this.setState({ alertImage: true });
      }
    }
  }
  handleNewImage = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  base64ToArrayBuffer = (base64) => {
    const chars = [
      ..."ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      ..."abcdefghijklmnopqrstuvwxyz",
      ..."0123456789+/",
      ..."+/",
    ];
    const buffer = [];

    for (let i = 0; i < base64.length / 4; i++) {
      // get the binary string
      const binary = [...base64.slice(4 * i, 4 * i + 4)]
        .map((x) => chars.indexOf(x).toString(2).padStart(6, 0))
        .join("");
      // convert the binary to bytes (24 bits or 3 bites)
      const bytes = binary.match(/.{1,8}/g).map((x) => +("0b" + x));
      // cover the edge case where base 64 ends with = sign
      buffer.push(
        ...bytes.slice(
          0,
          3 - (base64[4 * i + 2] === "=") - (base64[4 * i + 3] === "=")
        )
      );
    }
    return buffer;
  };

  handleSave = () => {
    const img = this.editor.getImageScaledToCanvas().toDataURL();
    this.setState({
      uploadedImage: img,
      isModalVisible: false,
    });
    const data = {
      ...getPayload(this.props),
      ProfileImage: this.base64ToArrayBuffer(img.substr(img.indexOf(",") + 1)),
    };
    this.props.fetchAddProfileImage(data);
  };

  handleScale = (e) => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };

  logCallback = (e) => { };

  setEditorRef = (editor) => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = (position) => {
    this.setState({ position });
  };

  uploadImage = () => {
    this.setState({ isModalVisible: true });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  handleRemove = () => {
    const { messagesData, bottleId } = this.props;
    this.setState({ image: "" });
    let messageToken = "";

    if (messagesData.length === 0) messageToken = "";
    if (messagesData.length === 1) {
      messageToken = messagesData[0].message_token;
    } else {
      const message = messagesData.find((data) => data.id === bottleId);
      messageToken = message.message_token;
    }
    this.props.fetchDeleteProfileImage({ MessageToken: messageToken });
  };

  handleCreateMessage = () => {
    this.props.history.push("/author/steps/preview");
  };

  render() {
    const { t } = this.context;
    return (
      <div className="page-wrap page-wrap--light">
        <div className="container">
          {!this.state.image && (
            <h1 className="multi-size">
              <TextFitter style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Heading keyName="ADD_YOUR_PHOTO" />
              </TextFitter>
            </h1>
          )}
          {this.state.image && (
            <h1 className="multi-size">
              <TextFitter style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Heading keyName="LOOKING_GOOD" />
              </TextFitter>
            </h1>
          )}
          <div className="add-image">
            {!this.state.image && (
              <div className="add-image__description">
                <p>{t("ENCOURAGE_ADD_PHOTO")}</p>
                <p>{t("IMAGE_RESTRICTION_HEADING")}</p>
                <ul>
                  <li>{t("IMAGE_RESTRICTION_UNDER_18")}</li>
                  <li>{t("IMAGE_RESTRICTION_PREGNANT_WOMEN")}</li>
                  <li>{t("IMAGE_RESTRICTION_DRINK_AND_DRIVE")}</li>
                  <li>{t("IMAGE_RESTRICTION_DRUNK_PEOPLE")}</li>
                  <li>{t("IMAGE_RESTRICTION_COPYRIGHTED")}</li>
                  <li>{t("IMAGE_RESTRICTION_FULL_LIST")}</li>
                </ul>
              </div>
            )}
            {this.state.image && (
              <div className="add-image__description">
                <p>{t("FACNY_REVIEW")}</p>
                <Button
                  onClick={this.handleCreateMessage}
                  type="primary"
                  shape="round"
                  className="create-button"
                >
                  {t("REVIEW_MY_MESSAGE")}

                  <ArrowDown />
                </Button>
              </div>
            )}
            <div className="-align-center">
              {!this.state.uploadedImage && (
                <>
                  <div className="image-preview">
                    <ImageIllustration />
                  </div>
                  {this.state.alertImage && (
                    <div className="image-error">
                      {t("PROCESS_IMAGE_ERROR")}
                    </div>
                  )}
                  <Button
                    onClick={this.uploadImage}
                    type="primary"
                    shape="round"
                  >
                    {t("UPLOAD_IMAGE")}
                    <PlusIcon />
                  </Button>
                  <div className="skip-link">
                    <Link
                      to="/author/steps/preview"
                      className="underlined underlined--dark"
                    >
                      {t("SKIP")}
                    </Link>
                  </div>
                </>
              )}
              {this.state.uploadedImage && (
                <>
                  <div className="image-preview">
                    <img src={this.state.uploadedImage} alt={t("PREVIEW")} />
                  </div>
                  <Link
                    to="#"
                    onClick={this.uploadImage}
                    className="underlined underlined--dark"
                  >
                    {t("EDIT_OR_DELETE_IMAGE")}
                  </Link>
                  <Button
                    onClick={this.handleCreateMessage}
                    type="primary"
                    shape="round"
                    className="create-button-inline"
                  >
                    {t("CREATE_MY_MESSAGE")}
                    <ArrowDown />
                  </Button>
                </>
              )}
              <Modal
                visible={this.state.isModalVisible}
                footer={null}
                onCancel={this.handleCancel}
                width={400}
                closeIcon={<CloseIcon />}
              >
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    this.setState({ image: acceptedFiles[0] });
                  }}
                  noClick={this.state.image === "" ? false : true}
                  multiple={false}
                  style={{
                    width: this.state.width,
                    height: this.state.height,
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="image-upload" {...getRootProps()}>
                      {!this.state.image && (
                        <div className="image-upload__overlay">
                          <CameraIcon />
                          <p>
                            {t("DROP_YOUR_IMAGE")}{" "}
                            <span className="underlined underlined-dark">
                              {t("BROWSE")}{" "}
                            </span>
                          </p>
                          <p>{t("IMAGE_FORMAT")}</p>
                        </div>
                      )}
                      <ReactAvatarEditor
                        ref={this.setEditorRef}
                        scale={parseFloat(this.state.scale)}
                        width={this.state.width}
                        height={this.state.height}
                        border={6}
                        color={[255, 255, 255, 1]}
                        position={this.state.position}
                        onPositionChange={this.handlePositionChange}
                        borderRadius={this.state.borderRadius}
                        onLoadFailure={this.logCallback.bind(
                          this,
                          "onLoadFailed"
                        )}
                        onLoadSuccess={this.logCallback.bind(
                          this,
                          "onLoadSuccess"
                        )}
                        onImageReady={this.logCallback.bind(
                          this,
                          "onImageReady"
                        )}
                        image={this.state.image}
                        className="editor-canvas"
                      />
                      <input
                        name="newImage"
                        type="file"
                        onChange={this.handleNewImage}
                        {...getInputProps()}
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png"
                      />
                    </div>
                  )}
                </Dropzone>
                {this.state.image && (
                  <>
                    <Link
                      to="#"
                      className="underlined underlined--light"
                      onClick={this.handleRemove}
                    >
                      {t("DELETE_IMAGE")}
                    </Link>
                    <div className="crop-scale">
                      <input
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min={this.state.allowZoomOut ? "0.1" : "1"}
                        max="2"
                        step="0.01"
                        defaultValue="1"
                        style={{ backgroundColor: "transparent" }}
                      />
                    </div>
                    <Button
                      type="primary"
                      shape="round"
                      onClick={this.handleSave}
                    >
                      {t("DONE")}
                      <ConfirmIcon />
                    </Button>
                  </>
                )}
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.addProfileImageReducer.isLoading,
  messagesData: state.messagesReducer.messagesData,
  bottleId: state.common.bottleId,
  addProfileImageState: state.addProfileImageReducer.addProfileImageState,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAddProfileImage: (data) => dispatch(fetchAddProfileImage(data)),
    fetchDeleteProfileImage: (data) => dispatch(fetchDeleteProfileImage(data)),
    clearRecording: () => dispatch(clearRecording()),
    clearTextRecording: () => dispatch(clearTextRecording()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Image));
