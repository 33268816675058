import axios from "./axios-instance";
import { API } from "./apiCall";
import { AuthToken } from "Utils/web-storage";

const apiMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type !== API) return;

    const {
      url,
      method,
      data,
      // accessToken,
      onStart,
      onSuccess,
      onFailure,
      label,
      headers,
    } = action.payload;

    const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
    const accessToken = getState().tokenReducer.tokenData || AuthToken().value;
    // axios default configs
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Authorization"] = `${
      accessToken ? "Bearer " : ""
    }${accessToken}`;

    if (label) {
      dispatch(onStart(label));
    }
    axios
      .request({
        url,
        method,
        headers,
        [dataOrParams]: data,
      })
      .then((response) => {
        const data = response.data || response;
        dispatch(onSuccess(data));
      })
      .catch((error) => {
        console.log("Middleware error: ", error);
        dispatch(onFailure(error.response));
      });
  };

export default apiMiddleware;
