import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"
import Stamp from "../common/Stamp.js"

export class AnimationTextureTalisker_3 extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;

		const texture = new THREE.TextureLoader().load(this.getAssetPath('talisker-texture-blue.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(7.50, 13.34, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		var sc = 1.5;
		plane1.scale.set(sc, sc, sc);

		// set plane initial state
		plane1.position.set(0, -20, -4);

		const positionVector3 = new THREE.Vector3(-5, 0.7, 6);
		const logo = new Stamp(this.parent, this.colors[2], this.duration, positionVector3);
		logo.rotation.set(0, 0, Math.PI / 2);
		gsap.to(logo.position, { delay: 0, y: 0.7, x: -0.5, ease: "expo.inOut", duration: this.duration });
		gsap.to(logo.rotation, { delay: 0, z: 0, ease: "expo.inOut", duration: this.duration });
		gsap.to(logo.position, { delay: this.duration, y: 3, ease: "expo.inOut", duration: this.duration });
		gsap.to(logo.rotation, { delay: this.duration, z: -Math.PI / 2, ease: "expo.inOut", duration: this.duration });

		const positionVector3b = new THREE.Vector3(-3, -10, 0);
		const coord = new Coordinates(this.parent, this.colors[1], this.duration, positionVector3b);
		gsap.to(coord.position, { delay: 0, y: -3.8, ease: "expo.inOut", duration: this.duration });
		gsap.to(coord.position, { delay: this.duration, y: 20, ease: "expo.inOut", duration: this.duration });


		// animate
		gsap.to(plane1.position, { y: 0, delay: 0, ease: "expo.inOut", duration: this.duration });
		gsap.to(plane1.position, {
			y: 400, delay: this.duration, ease: "expo.inOut", duration: this.duration, onComplete: function () {

				self.parent.remove(logo);
				self.parent.remove(plane1);
				self.parent.remove(coord);

			}
		});

	}

	destroy() {

	}

}

export default AnimationTextureTalisker_3; // Don’t forget to use export default!