import React from 'react';
import { Route, Switch } from "react-router-dom";
import { PageNotFound } from 'Components/Layout/PageNotFound';

import GiftFor from './GiftFor';
import Image from './Image';
import Occasion from './Occasion';
import Preview from './Preview';
import Record from './Record';

export function Steps() {
  return (
    <main>
      <Switch >
        <Route path="/author/steps/gift-for">
          <GiftFor />
        </Route>
        <Route path="/author/steps/occasion">
          <Occasion />
        </Route>
        <Route path="/author/steps/record">
          <Record />
        </Route>
        <Route path="/author/steps/image">
          <Image />
        </Route>
        <Route path="/author/steps/preview">
          <Preview />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </main>
  )
}

export default Steps