
export const getPayload = ({ bottleId, messagesData, occasionData } ) => {
  let MessageToken = '';

  if (messagesData.length === 0) MessageToken = '';
  if (messagesData.length === 1) {
    MessageToken = messagesData[0].message_token;
  } else {
    const message = messagesData.find(data => data.id === bottleId)
    MessageToken = message.message_token;
  }

  return {
    ...(occasionData ? { OcassionId: occasionData.occasionId } : {}),
    MessageToken,
  };
}