import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { CONSUMER, CONSUMER_UPDATE, CLEAR_CONSUMER_UPDATE } from "../actions/actionTypes";
import { sanitiseValue } from "Utils/common";

const initialState = {
  isLoading: false,
  consumerData: null,
  consumerState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  consumerUpdateData: null,
  error: {},
};

export const {
  start: consumer,
  success: consumerSuccess,
  fail: consumerFail,
} = defineLoopActions(CONSUMER);

export const fetchConsumer = (ConsumerId) => {
  return apiAction({
    method: "POST",
    url: "consumer/getConsumer",
    onStart: consumer,
    onSuccess: consumerSuccess,
    onFailure: consumerFail,
    label: CONSUMER,
    data: {
      ConsumerId,
    },
  });
};

  export const consumerReducer = handleActions(
    {
      ...requestLoopHandlers({
        action: CONSUMER,
        onSuccess: (state, payload) => {
          return {
            ...state,
            isLoading: true,
            consumerState: REQUEST_STATUS.SUCCESS,
            consumerData: payload,
          };
        },
        stateField: "consumerState",
      }),
    },
    initialState
  );

export const consumerReducerSelector = (prop) => (state) => {
  if (prop) {
    const value = state.consumerReducer[prop];
    return sanitiseValue(value);
  }
  return state.consumerReducer;
};

export const {
  start: consumerUpdateStart,
  success: consumerUpdateSuccess,
  fail: consumerUpdateFail,
} = defineLoopActions(CONSUMER_UPDATE);

export const consumerUpdate = data => {
  return apiAction({
    method: "POST",
    url: "consumer/consumerUpdate",
    onStart: consumerUpdateStart,
    onSuccess: consumerUpdateSuccess,
    onFailure: consumerUpdateFail,
    label: CONSUMER_UPDATE,
    data,
  });
}

export const consumerUpdateReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: CONSUMER_UPDATE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          consumerUpdateState: REQUEST_STATUS.SUCCESS,
          consumerUpdateData: payload
        };
      },
      onFailure: (state) => ({
        ...state,
        consumerUpdateState: REQUEST_STATUS.FAIL,
        consumerUpdateData: null,
        error: true,
      }),
      stateField: "consumerUpdateState",
    }),
    [CLEAR_CONSUMER_UPDATE]: (state) => ({
      ...state,
      consumerUpdateData: null,
      error: null,
    }),
  },
  initialState
);

export const clearConsumerUpdate = () => {
  return {
    type: CLEAR_CONSUMER_UPDATE,
  }
}

export const consumerUpdateReducerSelector = (prop) => (state) => {
  if (prop) {
    const value = state.consumerUpdateReducer[prop];
    return sanitiseValue(value);
  }
  return state.consumerUpdateReducer;
};
