import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as actions from "Store/actions/index";

export const useNavigator = () => {
  const { mediaDevices } = navigator;
  const canRecord = mediaDevices ?? false;

  console.log("Can record: ", canRecord);

  const dispatch = useDispatch();
  const history = useHistory();

  const handleMsgTypeClick = (msgType) => {
    dispatch(actions.setOnlyWrittenMsg(false)); // Todo: See where this is used
    dispatch(actions.setSlug(msgType));
    history.push("/author/main-steps/");
  };

  return { canRecord, handleMsgTypeClick };
};
