import React, { useState } from "react";

import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { withRouter, useHistory } from "react-router-dom";

import { Form, Button, Select } from "antd";
import { Footer } from "Components/Layout";

import "./Occasion.scss";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { ReactComponent as DropdownIcon } from "Assets/img/dropdown.svg";
import * as actions from "Store/actions/index";
import { useAuthorable } from "Hooks/useAuthorable";
import { useTranslation } from "Contexts/translation-context";
import { Heading } from "Components/heading";
import TextFitter from 'Components/Layout/TextFitter';

const { Option } = Select;

export function Occasion() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const occasionStateData = useSelector(state => state?.common?.occasionData);
  const [occasionData, setOccasionData] = useState(occasionStateData || {});
  const messagesData = useSelector(
    (state) => state.messagesReducer.messagesData,
    shallowEqual
  );
  const { occasionData: occasionDataRedux } = useSelector(
    (state) => state.common,
    shallowEqual
  );

  const slug = useSelector((state) => state.common.slug, shallowEqual);
  const bottleId = useSelector((state) => state.common.bottleId, shallowEqual);
  useAuthorable();
  const onOccasionSelect = (value) => {
    let occasions = [];
    let occasion = [];
    if (messagesData.length === 0) return null;
    if (messagesData.length === 1) {
      occasions = messagesData[0].occasions;
      occasion = occasions.find((o) => o.id === value);
    } else {
      const message = messagesData.find((data) => data.id === bottleId);
      occasions = message.occasions;
      occasion = occasions.find((o) => o.id === value);
    }
    setOccasionData({
      occasionId: occasion.id,
      occasionName: occasion.name,
    });
  };

  const onFinish = () => {
    dispatch(actions.setOccasionData(occasionData));
    history.push("/author/steps/record");
  };

  const occasionOptions = () => {
    let occasions = [];

    if (messagesData.length === 0) return null;
    if (messagesData.length === 1) {
      occasions = messagesData[0].occasions;
    } else {
      const message = messagesData.find((data) => data.id === bottleId);
      occasions = message.occasions;
    }
    return occasions.map((occasion, index) => {
      return (
        <Option value={occasion.id} key={index}>
          {occasion.name}
        </Option>
      );
    });
  };
  console.log(occasionDataRedux);
  return (
    <div className="page-wrap">
      <div className="container">
        <h1 className="multi-size">
          <TextFitter style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Heading keyName="NAME_THE_OCCASSION" />
          </TextFitter>
        </h1>
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          requiredMark={"optional"}
          className="occasion-form"
          initialValues={{
            occasion: occasionDataRedux?.occasionId,
          }}
        >
          <Form.Item
            name="occasion"
            label={t("SELECT_WHAT_BEST_FITS")}
            rules={[
              {
                required: true,
                message: t("VALIDATION_OCCASSION"),
              },
            ]}
          >
            <Select
              placeholder={t("SELECT")}
              className="font-tight-small"
              onSelect={onOccasionSelect}
              dropdownAlign={{ offset: [0, 0] }}
              allowClear
              suffixIcon={<DropdownIcon />}
            >
              {occasionOptions()}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" shape="round" htmlType="submit">
              {slug === "text"
                ? t("WRITE_YOUR_MESSAGE")
                : t("RECORD_YOUR_MESSAGE")}
              <ArrowDown />
            </Button>
          </Form.Item>
        </Form>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default withRouter(Occasion);
