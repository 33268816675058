import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import WalkingMan from "../common/WalkingMan.js"
import TextLine from "../common/textLine.js"

export class AnimationWalkingMan_1 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    const positionVector3 = new THREE.Vector3(-50, -150, -150 );
    new WalkingMan(this.parent, this.colors[3], this.duration, positionVector3);

    const word = new TextLine(this.keyword.toUpperCase(), 1.5, 1.5, 400, this.colors[3], undefined, "JohnnieWalkerWeb-Headline");
    this.parent.add(word);
    word.position.set(0,5.5,0);
    word.material.map.offset.y = 1;
    gsap.to(word.material.map.offset,{y:0, ease: "expo.inOut", duration:this.duration});
    
    const self = this;
    gsap.to(word.material.map.offset,{delay:this.duration, y:-1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(word);
    }})

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationWalkingMan_1; // Don’t forget to use export default!