import React, { Component } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./Audio.scss";
import { ReactComponent as StopIcon } from "Assets/img/stop.svg";
import { TranslationContext } from "Contexts/translation-context";

class AudioRecorder extends Component {
  static contextType = TranslationContext;
  state = {
    isRecording: true,
    colors: [["#131314", 0.75], ["#131314", 0.1], ["#e90334"]],
  };

  stopAudioRecorder = () => {
    if (this.state.isRecording === true) {
      this.setState({
        isRecording: false,
        colors: [["#131314", 0.75], ["#131314", 0.1], ["#e90334"]],
      });
      this.props.stop();
    }
  };

  resetAudioRecorder = () => {
    this.props.reset();
  };

  componentDidMount() {
  }

  render() {
    const { t } = this.context;
    const isRecording = this.state.isRecording;
    const DURATION_SECONDS = this.props.duration ?? 0;
    const colors = this.state.colors;
    const renderTime = (value) => {
      if (value === 0) {
        setTimeout(() => {
          this.stopAudioRecorder();
        }, 1500);
        return (
          <div className="timer">
            <button onClick={this.stopAudioRecorder}>
              <span className="offset">
                <StopIcon />
                {t("SUBMITTING_RECORDING")}
              </span>
            </button>
          </div>
        );
      }

      if (value <= DURATION_SECONDS) {
        let prefix = "00:";
        if (value <= 10) {
          prefix += "0";
        }
        return (
          <div className="timer">
            <span>
              {prefix}
              {value - 1}
            </span>
            <button onClick={this.stopAudioRecorder}>
              <span>
                <StopIcon />
                {t("STOP_RECORDING")}
              </span>
            </button>
          </div>
        );
      }
    };

    return (
      <CountdownCircleTimer
        isPlaying={isRecording}
        size={280}
        durationSeconds={DURATION_SECONDS}
        colors={colors}
        trailColor={"rgba(0,0,0,0.1"}
        strokeWidth={5}
        renderTime={renderTime}
        onComplete={() => [false]}
      />
    );
  }
}

export default AudioRecorder;
