import { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { withRouter, useHistory } from "react-router-dom";

import Experience from "Components/legacy-creative/Experience";

import { Checkbox, Button } from "antd";
import "./Preview.scss";
import { ReactComponent as PauseIcon } from "Assets/img/pause.svg";
import { ReactComponent as PlayIcon } from "Assets/img/play.svg";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import SpinnerGif from "Assets/img/spinner.gif";
import { postConsumer } from "Services/consumerService";
import { postFinalise } from "Services/finaliseService";
import { useSingleMessage } from "Hooks/authoring/useSingleMessage";
import { clearRecording } from "Store/reducers/record";
import { EventManager, EVENTNAME } from "Utils/event-manager";
import {
  clearConsumerUpdate,
  consumerUpdateReducerSelector,
} from "Store/reducers/consumer";
import {
  clearFinalize,
  finalizeReducerSelector,
} from "Store/reducers/finalize";
import { clearTextRecording } from "Store/reducers/record-text";
import { clearCommonData, setCommonData } from "Store/actions/common";
import { getImageUrl } from "Utils/common";
import { useTranslation } from "Contexts/translation-context";
import { Heading } from "Components/heading";
import { DiageoCreative } from "Components/diageo-creative";
import { useMessageUser } from "Hooks/useMessageUser";
import configureStore from 'Store/configureStore'
import BasicExperience from "../../../components/basic-creative/BasicExperience";
import TextFitter from 'Components/Layout/TextFitter';

export function Preview() {
  const isOfftrade = useSelector((state) => state.common.offtrade);
  const customerEmail = useSelector((state) => state.common.customerEmail);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [acceptTnC, setAcceptTnC] = useState(false);
  const [acceptConsent, setAcceptConsent] = useState(false);
  const [acceptTnCError, setAcceptTnCError] = useState(false);
  const [acceptConsentError, setAcceptConsentError] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [creativeKey, setCreativeKey] = useState(0);
  const [experienceKey, setExperienceKey] = useState('initial');
  const [sendBtnDisabled, setSendBtnDisabled] = useState(false);
  const {
    productImg,
    productName,
    messageToken,
    messageDataLength,
    consumerId,
  } = useSingleMessage();

  const { consumerUpdateError, consumerUpdateData } = useSelector(
    consumerUpdateReducerSelector(),
    shallowEqual
  );
  const { finalizeError, finalizeData } = useSelector(
    finalizeReducerSelector(),
    shallowEqual
  );
  useEffect(() => {
    if (consumerUpdateData && !finalizeData) {
      dispatch(postFinalise(messageToken, sendEmail));
    }

    if (finalizeData) {
      if (
        messageDataLength === 1 ||
        messageDataLength === selectedBottles.length
      )
        history.push("/author/send-confirmation");
      else {
        history.push("/author/multi-products");
      }
    }

    return () => {
      dispatch(clearRecording());
      dispatch(clearTextRecording());
      dispatch(clearConsumerUpdate());
      dispatch(clearFinalize());
    };
  }, [consumerUpdateData, finalizeData]);

  const occasionData = useSelector(
    (state) => state.common.occasionData,
    shallowEqual
  );
  let selectedBottles = useSelector(
    (state) => state.common.selectedBottles,
    shallowEqual
  );
  const giftData = useSelector((state) => state.common.giftData, shallowEqual);
  const eventManager = new EventManager({
    eventName: EVENTNAME.PLAY_MESSAGE_IN_A_BOTTLE,
  });
  const { isGeneric = false, isLegacy = false, isBasic = false } = useMessageUser(
    messageToken,
    occasionData?.occasionId,
    true
  );

  const handleOnPlay = () => {
    if (!playing) {
      eventManager.dispatch();
      setCreativeKey((prev) => prev + 1);
    }
  };

  const handleOnSend = () => {
    if (acceptTnC && acceptConsent) {
      setSendBtnDisabled(true);

      dispatch(
        postConsumer({
          MessageToken: messageToken,
          ConsumerId: consumerId,
          GiftGiverName: giftData?.gift_giver_name,
          GiftRecipientName: giftData?.gift_recipient_name,
          GiftGiverEmail: customerEmail
        })
      );

      if (messageDataLength > 1) {
        dispatch(
          clearCommonData({
            giftData: {
              ...giftData,
              gift_recipient_name: null,
            },
          })
        );
      }

      // Once we have sent the message we clean the store.
      const { persistor } = configureStore();
      persistor.purge().then(() => {
        console.log('Purged.')
      });
    } else {
      setAcceptConsentError(!acceptConsent);
      setAcceptTnCError(!acceptTnC);
      if (!acceptTnC && !acceptConsent) {
        setError(t("ACCEPT_ERROR_T_N_C_CONSENT"));
        return;
      }
      if (!acceptTnC) {
        setError(t("ACCEPT_ERROR_T_N_C"));
        return;
      }
      if (!acceptConsent) {
        setError(t("ACCEPT_ERROR_CONSENT"));
      }
    }
  };

  const handleOnEdit = () => {
    dispatch(
      setCommonData({
        writtenTextMesssage: ''
      })
    );

    history.push("/author/steps/gift-for");
  };

  const handleOnEnd = () => { };

  const handleOnSendEmail = (e) => {
    setSendEmail(e.target.checked);
  };
  const handleOnCheckTerms = (e) => {
    setAcceptTnC(e.target.checked);
    setAcceptTnCError(!e.target.checked);
  };

  const handleOnCheckConsent = (e) => {
    setAcceptConsent(e.target.checked);
    setAcceptConsentError(!e.target.checked);
  };
  return (
    <div className="page-wrap page-wrap--light">
      <div className="container">
        <div className="message-preview">
          <div className="message-preview__details">
            <h1 className="multi-size">
              <TextFitter>
                <Heading keyName="PREVIEW_YOUR_MESSAGE" />
              </TextFitter>
            </h1>
            <div className="message-preview__table">
              <img
                src={
                  productImg.indexOf("http") > -1
                    ? productImg
                    : getImageUrl(productImg)
                }
                alt={t("PLACEHOLDER")}
              />
              <div>
                <p>
                  <span className="message-preview__label">{t("TO")}</span>
                  <span className="message-preview__value">
                    {giftData?.gift_recipient_name}
                  </span>
                </p>
                <p>
                  <span className="message-preview__label">
                    {t("OCCASION")}
                  </span>
                  <span className="message-preview__value">
                    {occasionData?.occasionName}
                  </span>
                </p>
                <p>
                  <span className="message-preview__label">{t("BOTTLE")}</span>
                  <span className="message-preview__value">{productName}</span>
                </p>
                <p>
                  <span className="message-preview__label">{t("FROM")}</span>
                  <span className="message-preview__value">
                    {giftData?.gift_giver_name}
                  </span>
                </p>
              </div>
            </div>
            <Button onClick={handleOnPlay} type="tertiary" shape="round">
              {playing ? t("PLAYING_MESSAGE") : t("PLAY_MESSAGE")}
              {playing ? <PauseIcon /> : <PlayIcon />}
            </Button>
            <Button onClick={handleOnEdit} type="tertiary" shape="round">
              {t("START_AGAIN")}
            </Button>
          </div>

          <div className="message-preview__frame">
            {isBasic && <BasicExperience
              isPreview
              width={312}
              height={675}
              giftGiver={giftData?.gift_giver_name}
              giftAudio={isBasic?.audio_message}
              giftImage={isBasic?.profile_pic}
              statusFeedback={setPlaying}
            ></BasicExperience>}

            {isLegacy && (
              <Experience
                key={experienceKey}
                className="experience"
                messageToken={messageToken}
                occasionID={occasionData?.occasionId}
                source="gift-giver"
                canvasWidth={312}
                canvasHeight={675}
                playAction={handleOnPlay}
                onEnd={handleOnEnd}
                senderName={giftData?.gift_giver_name}
                experienceUpdated={(key) => setExperienceKey(key)}
                statusFeedback={setPlaying}
                productImage={
                  productImg.indexOf("http") > -1
                    ? productImg
                    : getImageUrl(productImg)
                }
              />
            )}
            {isGeneric && (
              <DiageoCreative
                key={creativeKey}
                width={312}
                height={675}
                statusFeedback={setPlaying}
                productImage={
                  productImg.indexOf("http") > -1
                    ? productImg
                    : getImageUrl(productImg)
                }
                giftGiver={giftData?.gift_giver_name}
              />
            )}
          </div>
        </div>
        <div className="message-send">
          <p className="font-longform">
            {t("WHAT_HAPPENS_NOW", [
              giftData?.gift_recipient_name,
              productName,
            ])}
          </p>
          <Checkbox onChange={handleOnSendEmail}>
            {t("SEND_ME_EMAIL_COPY")}
          </Checkbox>
          <Checkbox
            onChange={handleOnCheckConsent}
            className={acceptConsentError ? "not-accepted" : ""}
          >
            {t("CONSENT_TEXT")}
          </Checkbox>
          <Checkbox
            onChange={handleOnCheckTerms}
            className={acceptTnCError ? "not-accepted" : ""}
          >
            {t("T_N_C_TEXT")}
          </Checkbox>
          {error && (
            <div className="ant-form">
              <div
                className="ant-form-item-explain ant-form-item-explain-error"
                role="alert"
              >
                {error}
              </div>
            </div>
          )}
          <Button
            onClick={handleOnSend}
            type="primary"
            shape="round"
            disabled={sendBtnDisabled}
          >
            {t("SEND")}
            <ArrowDown />
          </Button>
          {sendBtnDisabled && (
            <img
              src={SpinnerGif}
              alt="Loading"
              className="message-send__spinner"
            />
          )}
          {(consumerUpdateError || finalizeError) && (
            <p className="api-error">{t("SEND_ERROR")}</p>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default withRouter(memo(Preview));
