import * as THREE from "three";

export class TextLine {
	
  constructor(text, hWorldTxt, hWorldAll, hPxTxt, fgcolor, bgcolor, font, spacing, proportionalSize) {
  	
  	if(proportionalSize != false){
  		proportionalSize = true;
  	}
  	if(!text){
  		return;
  	}

  	if(!spacing){
  		spacing = 0;
  	}

 	var mesh = this.dcText(text, hWorldTxt, hWorldAll, hPxTxt, fgcolor, bgcolor, font, spacing, proportionalSize); // text #1, Hello, world
  	return mesh;

  }

  dcText(txt, hWorldTxt, hWorldAll, hPxTxt, fgcolor, bgcolor, font, spacing, proportionalSize) { // the routine

  	  const txtLength = txt.length;
      const averageLength = 8;

	  if(txtLength > averageLength && proportionalSize){
	  	 // averageLength :  hPxTxt = txtLength: x
     	 hWorldTxt = ((averageLength/txtLength)*hWorldTxt);
	  	 hWorldAll = hWorldTxt;
	  }



  	  // font = "140em sans-serif";
	  // txt is the text.
	  // hWorldTxt is world height of text in the plane.
	  // hWorldAll is world height of whole rectangle containing the text.
	  // hPxTxt is px height of text in the texture canvas; larger gives sharper text.
	  // The plane and texture canvas are created wide enough to hold the text.
	  // And wider if hWorldAll/hWorldTxt > 1 which indicates padding is desired.
	  var kPxToWorld = hWorldTxt/hPxTxt;                // Px to World multplication factor
	  // hWorldTxt, hWorldAll, and hPxTxt are given; get hPxAll
	  var hPxAll = Math.ceil(hWorldAll/kPxToWorld);     // hPxAll: height of the whole texture canvas

	  // create the canvas for the texture
	  var txtcanvas = document.createElement("canvas"); // create the canvas for the texture

	  document.body.appendChild(txtcanvas);
	 // txtcanvas.style.letterSpacing = '200px';

	  txtcanvas.style.letterSpacing = spacing+'px';

	  var ctx = txtcanvas.getContext("2d");
	  //ctx.canvas.style.letterSpacing = "5000px";
	  ctx.font = hPxTxt + "px "+font;        
	  // now get the widths
	  var wPxTxt = ctx.measureText(txt).width;         // wPxTxt: width of the text in the texture canvas

	  var wWorldTxt = wPxTxt*kPxToWorld;               // wWorldTxt: world width of text in the plane
	  var wWorldAll = wWorldTxt+(hWorldAll-hWorldTxt); // wWorldAll: world width of the whole plane
	  var wPxAll = Math.ceil(wWorldAll/kPxToWorld);    // wPxAll: width of the whole texture canvas
	  // next, resize the texture canvas and fill the text
	  txtcanvas.width =  wPxAll;
	  txtcanvas.height = hPxAll;

	  if (bgcolor != undefined) { // fill background if desired (transparent if none)
	    ctx.fillStyle = "#" + bgcolor.toString(16).padStart(6, '0');
	    ctx.fillRect( 0,0, wPxAll,hPxAll);
	  } else{
	  	ctx.fillStyle = "rgba(0,0,0,0)";
	  	ctx.fillRect( 0,0, wPxAll,hPxAll);
	  }
	  ctx.textAlign = "center";
	  ctx.textBaseline = "middle"; 
	  ctx.fillStyle = "#" + fgcolor.toString(16).padStart(6, '0'); // fgcolor
	  ctx.font = hPxTxt + "px "+font;   // needed after resize

	  //this.fillTextWithSpacing(ctx, txt, 0, hPxAll/2, 10);

	  ctx.fillText(txt, wPxAll/2, hPxAll/2); // the deed is done
	  
	  // next, make the texture
	  var texture = new THREE.Texture(txtcanvas); // now make texture
	  texture.minFilter = THREE.LinearFilter;     // eliminate console message
	  texture.needsUpdate = true;                 // duh

	  // and make the world plane with the texture
	  var geometry = new THREE.PlaneGeometry(wWorldAll, hWorldAll);
	  var material = new THREE.MeshBasicMaterial({ side:THREE.DoubleSide, map:texture, transparent:true, opacity:1.0 } );
	  // and finally, the mesh
	  var mesh = new THREE.Mesh(geometry, material);
	  mesh.wWorldTxt = wWorldTxt; // return the width of the text in the plane
	  mesh.wWorldAll = wWorldAll; //    and the width of the whole plane
	  mesh.wPxTxt = wPxTxt;       //    and the width of the text in the texture canvas
	                              // (the heights of the above items are known)
	  mesh.wPxAll = wPxAll;       //    and the width of the whole texture canvas
	  mesh.hPxAll = hPxAll;       //    and the height of the whole texture canvas
	  mesh.ctx = ctx;             //    and the 2d texture context, for any glitter


	  document.body.removeChild(txtcanvas);

	  return mesh;
	}


  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default TextLine; // Don’t forget to use export default! 

