import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { MESSAGE_EXPERIENCE, CLEAR_MESSAGE_EXPERIENCE } from "../actions/actionTypes";
import { sanitiseValue } from "Utils/common";

const initialState = {
  isLoading: false,
  data: null,
  messageState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(MESSAGE_EXPERIENCE);

export const fetchMessageExperience = (MessageToken) => {
  const data = { MessageToken };
  return apiAction({
    method: 'POST',
    url: '/message/experience',
    onStart,
    onSuccess,
    onFailure,
    label: MESSAGE_EXPERIENCE,
    data
  });
};

export const messageExperienceReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: MESSAGE_EXPERIENCE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          messageState: REQUEST_STATUS.SUCCESS,
          data: payload,
        };
      },
      onFailure: (state) => {
        return {
          ...state,
          isLoading: false,
          messageState: REQUEST_STATUS.FAIL
        };
      }
    }),
    [CLEAR_MESSAGE_EXPERIENCE]: (state) => ({
      ...state,
      data: null,
      error: null,
    }),
  },
  initialState
);


export const clearMessageExperience = () => {
  return {
    type: CLEAR_MESSAGE_EXPERIENCE,
  }
}
export const messageExperienceReducerSelector = (prop) => state => {
  const value = state.messageExperienceReducer[prop];

  return sanitiseValue(value);

}
