import React from "react";
import { useHistory } from "react-router";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
import "./pair.css";

const UnPairPage = () => {
  const history = useHistory();

  return (
    <section className="center-content">
      <div className="container sm:w-96 mx-auto">
        <Header />
        <div className=" bg-white">
          <div className="px-6 py-8">
            <p className="text-3xl text-black">
              This product has been unpaired.{" "}
            </p>
            <p className="text-sm text-black mt-8">
              To pair it with a another QR code, please scan another code
              through the camera app.
            </p>
            <button
              onClick={() => navigate("home")}
              className="btn-bg rounded-md px-6 py-3 font-bold text-sm inline-block mt-6"
            >
              Back to home
            </button>
            <div className="pt-72 flex justify-between">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnPairPage;
