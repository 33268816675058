import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Bottle from "../common/Bottle.js"
import TextLine from "../common/textLine.js"

export class AnimationBottle_4 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    var self = this;
       
    // create plane 
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[2], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

        // set plane initial state
    plane1.position.set(0,-350,-150);

    const wordTop = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined, "JohnnieWalkerWeb-Headline");
    this.parent.add(wordTop);
    wordTop.position.set(-2,-7,0);

    const wordBottom = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined, "JohnnieWalkerWeb-Headline");
    this.parent.add(wordBottom);
    wordBottom.position.set(2,-7,0);

    var positionVector3 = new THREE.Vector3(0, 0, -15 );
    new Bottle(this.parent, this.colors[0], this.duration, positionVector3); 

    gsap.to(wordTop.position,{delay:0, y:5.4, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordBottom.position,{delay:0, y:-5.5, ease: "expo.inOut", duration:this.duration});

    gsap.to(wordTop.position,{delay:this.duration, y:14, ease: "expo.inOut", duration:this.duration});
    gsap.to(wordBottom.position,{delay:this.duration, y:14,ease: "expo.inOut", duration:this.duration});


    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration,onComplete:function(){
      self.parent.remove(wordTop);
      self.parent.remove(wordBottom);
      self.parent.remove(plane1);
    }});

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationBottle_4; // Don’t forget to use export default!