import React, { useEffect } from "react";
import "./Footer.scss";
import { ReactComponent as MaltsLogo } from "Assets/img/malts-logo.svg";
import { ReactComponent as Drinkaware } from "Assets/img/drinkiq-drinkaware.svg";
import { injectScript } from "Utils/injectScript";

export function Footer() {
  useEffect(() => {
    const injectScriptIfProduction = async () => {
      if (process.env.NODE_ENV === "production") {
        if (!window.jQuery) {
          // jquery required for diageohorizon script
          await injectScript("https://code.jquery.com/jquery-3.6.0.min.js", {
            id: "jqueryScript",
            forceInject: false,
          });
          await injectScript(
            "https://code.jquery.com/jquery-migrate-3.3.2.js",
            { id: "jqueryMigrateScript", forceInject: false }
          );
          await Promise.resolve(() => {
            if (window.jQuery) {
              const $j = window.jQuery;
              window.jQuery.fn.load = function (callback) {
                $j(window).trigger("load", callback);
              };
              window.jQuery.fn.bind = function (callback) {
                $j(window).trigger("on", callback);
              };
              window.jQuery.fn.unbind = function (callback) {
                $j(window).trigger("off", callback);
              };
            }
          });
          await injectScript("//footer.diageohorizon.com/dfs/master.js", {
            id: "horizonScript",
            forceInject: false,
          });
        }
      }
    };
    injectScriptIfProduction();
  }, []);
  return (
    <footer className="site-footer">
      <div className="site-footer__logos">
        <MaltsLogo className="malts-logo" />
      </div>

      <div id="footer" className="horizon-footer"></div>

      <div className="site-footer__logos">
        <Drinkaware className="drinkaware-logo" />
      </div>
    </footer>
  );
}