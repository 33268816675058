import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { ADD_RECORED_VOICE, CLEAR_RECORDING } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  addRecorededVoiceState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  addRecorededVoiceData: null,
  error: false,
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(ADD_RECORED_VOICE);

// This is the initial request to upload to S3, Transcribe, Download the Trancribe from S3 and a parsed JSON response.
export const fetchAddRecoredVoice = (data) => {
  const apiUrl = `/authoring/createExpirience`;

  return apiAction({
    url: apiUrl,
    method: "POST",
    data: data,
    onStart,
    onSuccess,
    onFailure,
    label: ADD_RECORED_VOICE,
  });
};

export const addRecoredVoiceReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: ADD_RECORED_VOICE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          addRecorededVoiceState: REQUEST_STATUS.SUCCESS,
          addRecorededVoiceData: payload,
          error: null,
        };
      },
      onFailure: (state, payload) => {
        return {
          ...state,
          addRecorededVoiceState: REQUEST_STATUS.FAIL,
          addRecorededVoiceData: null,
          error: true,
        };
      },
    }),
    [CLEAR_RECORDING]: (state) => {
      return {
        ...state,
        addRecorededVoiceData: null,
        error: null,
      };
    },
  },
  initialState
);

export const clearRecording = () => {
  return {
    type: CLEAR_RECORDING,
  };
};
