import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Brush from "../common/Brush.js"
import TextLine from "../common/textLine.js"


export class AnimationBrushSingleton_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

     const self = this;

    const positionVector3 = new THREE.Vector3(-1, -1, 0 );
    var brush = new Brush(this.parent, positionVector3, 0, this.colors[3], this.duration);
    brush.rotation.set(0,0,-Math.PI/2);

    const positionVector3b = new THREE.Vector3(1, 0, 0 );
    var brush2 = new Brush(this.parent, positionVector3b, 0.1, this.colors[3], this.duration);
    brush2.rotation.set(0,0,-Math.PI/2.1);

     const positionVector3c = new THREE.Vector3(1, 1, 0 );
    var brush3 = new Brush(this.parent, positionVector3c, 0.1, this.colors[3], this.duration);
    brush3.rotation.set(0,0,-Math.PI/2.3);

    const word = new TextLine(this.keyword, 0.6, 0.6, 400, this.colors[2], undefined, 'Mackay Regular');
    this.parent.add(word);

    word.position.set(0,0,3.1);
    word.material.map.offset.set(0,1);


    gsap.to(word.material.map.offset,{delay:0.1, y:0, ease: "expo.inOut", duration:this.duration});
  

    gsap.to(word.material.map.offset,{delay:this.duration, y:-1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(brush);
      self.parent.remove(brush2);
      self.parent.remove(brush3);
      self.parent.remove(word);
    }});
   
  }

 
  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationBrushSingleton_4; // Don’t forget to use export default!