import getAuthToken from "./get-auth";
import { useHistory, useLocation  } from "react-router";

export default function getUserSession() {
    const auth = JSON.parse(getAuthToken());

    console.log(auth);
    if (!auth) {
      console.log("not authenticated");
    }else{
      const expires_at = auth.expires_at;
      const now_is = Date.now();
  
      /*
      console.log("expires_at", expires_at);
      console.log("now is", now_is)
      */
  
      console.log(new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(expires_at));
      console.log(new Intl.DateTimeFormat('en-GB', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(now_is));
  
  
      const t_diff = now_is-expires_at;
      console.log("t_diff is", t_diff)
  
      const location = useLocation();
      console.log("location.pathname",location.pathname)
      console.log("location.search",location.search)
  
      /*
      history.push({
        pathname: "/pair/",
        search: ""
      });
  */
      
  
  
      if(t_diff>0){
        console.log("token expired")
        //history.replace("/pair/finish")
        return false;
      }else{
        console.log("token still valid")
        return true;
        //history.replace("/finish");
        //history.push("/")
      }
    }
}