import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonLogo from "../common/SingletonLogo.js"
import SingletonSignature from "../common/SingletonSignature.js"
import TextLine from "../common/textLine.js"


export class AnimationBrandSingleton_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const logoContainer = new THREE.Group();
    this.parent.add(logoContainer);
    logoContainer.position.set(0,-5,0);

    for (var i = 0; i < 5; i++) {
      var trgX = 0;
      const positionVector3 = new THREE.Vector3(-10, i*2.5, 0);
      var c = this.colors[2];
      var logoClass = SingletonLogo;

      if(i == 1 || i == 3){
        logoClass = SingletonSignature;
        c = this.colors[0];
      }

      const logo = new logoClass(logoContainer, c, this.duration, positionVector3)
      //logo.rotation.set(0,0,Math.PI/4);
      logo.material.opacity = 0;
      gsap.to(logo.position,{delay:0.05*i, x:trgX, ease: "expo.inOut", duration:this.duration});
      gsap.to(logo.position,{delay:this.duration, x:trgX+10, ease: "expo.inOut", duration:this.duration});

      //gsap.to(logo.rotation,{delay:this.duration, z:-Math.PI/4, ease: "expo.inOut", duration:this.duration});

      gsap.to(logo.material,{delay:0, opacity:1, ease: "expo.inOut", duration:this.duration});
      gsap.to(logo.material,{delay:this.duration, opacity:0, ease: "expo.inOut", duration:this.duration});
    }

   

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(logoContainer);
    
   
    }})

  }
   
}

export default AnimationBrandSingleton_4; // Don’t forget to use export default!