import { config, POSITION } from "../configuration";
import {
  TEXTURE,
  withExtendedTile,
  withExtendedVerticalHalf,
  withExtendedPhotography,
  withExtendedBaseCreative,
  withExtendedReel,
  withPhotography
} from "./enhancer";
import { LogoSteps } from "./logo-steps";

const creativityFor = {
  [TEXTURE.LOGO]: [
    LogoSteps,
    withExtendedReel({ imageWidth: 2, imageHeight: 1 }),
    withExtendedPhotography({ imagePosition: POSITION.CENTER, isStretched: true, scaleFactor: 30, imageWidth: 2, imageHeight: 1 }),
    withExtendedPhotography({ imagePosition: POSITION.TOPHALF, isVertical: true, scaleFactor: 50, imageWidth: 2, imageHeight: 1 }),
    withExtendedReel({ isPlain: true, imageWidth: 2, imageHeight: 1  }),
  ],
  [TEXTURE.REPEAT]: [
    withExtendedTile(),
    withExtendedVerticalHalf({ imagePosition: POSITION.RIGHT, scaleFactor: 7 }),
    withExtendedVerticalHalf({ imagePosition: POSITION.RIGHT, scaleFactor: 7 }),
    withExtendedTile({ noKeywords: true }),
    withExtendedVerticalHalf({ imagePosition: POSITION.LEFT, scaleFactor: 7, isHorizontalText: true }),
  ],
  [TEXTURE.SINGLE]: [
    withExtendedPhotography({ imagePosition: POSITION.TOPHALF, imageWidth: 110, imageHeight: 135, lines: 1 }),
    withExtendedPhotography({ imagePosition: POSITION.BOTTOMHALF, imageWidth: 110, imageHeight: 135, lines: 3 }),
    withExtendedPhotography({ imagePosition: POSITION.CENTER, isStretched: true, scaleFactor: 40, imageWidth: 1.1, imageHeight: 1.35 }),
    withExtendedReel({ isPlain: true, imageWidth: 1.1, imageHeight: 1.35 }),
    withExtendedReel({ imageWidth: 1.1, imageHeight: 1.35 }),
  ],
  [TEXTURE.OCCASION]: {
    BIRTHDAY: withExtendedTile({ isOccasion: true }),
    OTHER: withExtendedVerticalHalf({ imagePosition: POSITION.RIGHT, isOccasion: true, scaleFactor: 5  }),
  },
  [TEXTURE.PHOTOGRAPHY]: [withPhotography()],
}

const mapperOne2one = (value, props, index) =>
  props.texture[value].map((texture, i) => {
    const Creative = creativityFor[value][typeof index !== 'undefined' ? index : i];
    return withExtendedBaseCreative({ ...props, texture }, Creative);
  });

const getOccasionCreative = (props) => {
  const value = props.creativity === 'BIRTHDAY' ? props.creativity : 'OTHER';
  const Creative = creativityFor[TEXTURE.OCCASION][value];
  return props.texture[TEXTURE.OCCASION].map(texture => withExtendedBaseCreative({ ...props, texture }, Creative));
};

export const CreativePack = (props) => {

  const logo = mapperOne2one(TEXTURE.LOGO, props);
  const repeat = mapperOne2one(TEXTURE.REPEAT, props);
  const single = mapperOne2one(TEXTURE.SINGLE, props);
  const photography = mapperOne2one(TEXTURE.PHOTOGRAPHY, props, 0);
  const occasion = getOccasionCreative(props);

  

  if (TEST_ONLY) {
    
    return testOnlyPack({
      logo,
      repeat,
      single,
      photography,
      occasion
    });
  }

  var shuffled = [
    ...logo,
    ...repeat,
    ...single,
    ...photography
  ];

  if (props.isTextOnly) {
    var selectedRepeat = [repeat[0], repeat[3]];
   shuffled = [
      ...logo,
      ...selectedRepeat,
      ...single,
      ...photography
    ];
  }


  if (config.CREATIVE_SHUFFLE) {
    shuffled = shuffled.sort(() => 0.5 - Math.random())
  }
  return [...occasion, ...shuffled];
}
export * from './keyword';

const TEST_ONLY = config.TEST_ONLY;
const testOnlyPack = props => props[TEST_ONLY];
