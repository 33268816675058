import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import logger from "redux-logger";
import rootReducer from "./reducers";
import apiMiddleware from "Axios/middleware";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancers = [];

const middlewares = [thunk, apiMiddleware];

if (process.env.NODE_ENV !== "production") {
  enhancers.push(logger);
}

const composeWithReduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeWithReduxDevTools(
  applyMiddleware(...middlewares, ...enhancers)
);

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line no-underscore-dangle
  const devToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({
      trace: true,
    });

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension);
  }
}

const store = createStore(persistedReducer, composedEnhancers);

const persistor = persistStore(store);

persistor.purge().then(() => {});

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./reducers/index", () => {
      const nextRootReducer = require("./reducers/index").default;
      store.replaceReducer(nextRootReducer);
    });
  }
}

export default () => ({ store, persistor });
