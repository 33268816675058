import { Children } from "react"
import { useTranslation } from "Contexts/translation-context";
import { AnimatedText } from "../framer";

export const Heading = ({keyName = ''}) => {
  const { t } = useTranslation() || {t: (k) => k};
  const texts = t(`heading:${keyName}`);
  if(!Array.isArray(texts)) return null;
  return Children.toArray(texts.map(heading =>
    <AnimatedText className={heading.cssClass} text={heading.text} wrapAs="span" />
  ));
}