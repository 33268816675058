import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Salmon from "../common/Salmon.js"
import TextLine from "../common/textLine.js"


export class AnimationSalmonSingleton_3 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const positionVector3 = new THREE.Vector3(2, -0.5, 0);

    const salmon = new Salmon(this.parent, this.colors[0], this.duration, positionVector3);
    salmon.material.opacity = 0;
    salmon.rotation.set(0,0,Math.PI/4);
    gsap.to(salmon.rotation,{delay:0, z:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(salmon.rotation,{delay:this.duration, z:-Math.PI/4, ease: "expo.inOut", duration:this.duration});

    gsap.to(salmon.material,{delay:0, opacity:1, ease: "expo.inOut", duration:this.duration});
    gsap.to(salmon.material,{delay:this.duration, opacity:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(salmon);
    }})

  }
   
}

export default AnimationSalmonSingleton_3; // Don’t forget to use export default!