import React from "react";
import "./RecipientEnd.scss";
import "./SocialMedia.scss";
import "./../RecipientFrame.scss";
import { withRouter, useHistory } from "react-router-dom";
import { Footer } from "Components/Layout";
import { ReactComponent as CloseIcon } from "Assets/img/circle-close.svg";
import { ReactComponent as Facebook } from "Assets/img/social-facebook.svg";
import { ReactComponent as Twitter } from "Assets/img/social-twitter.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle-alt.svg";
import { useTranslation } from "Contexts/translation-context";

export const SocialMedia = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCancel = () => {
    history.push("/recipient/end");
  };
  const shareLink = "https://messageinabottle.diageo.com"; // Todo: DB: Paul you need to load this in from redux state... This.state.common.  (look)
  return (
    <div className="page-wrap">
      <section className="recipient-end">
        <div className="container">
          <div className="recipient-frame">
            <div className="recipient-frame__inner">
              <div className="recipient-end__header recipient-end__header--spacer">
                <Bottle className="recipient-end__bottle-image" />
                <CloseIcon
                  className="recipient-end__close-icon"
                  onClick={handleCancel}
                />
              </div>

              <main className="ant-layout">
                <h1 className="social-media__heading font-tight-x-large">
                  {t("SHARE_TO_SOCIAL_MEDIA")}
                </h1>

                <div className="social-media__block">
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}`}
                    rel="noreferrer"
                  >
                    <Facebook />
                  </a>
                  <a
                    target="_blank"
                    href={`https://twitter.com/intent/tweet?url=${shareLink}&text=`}
                    rel="noreferrer"
                  >
                    <Twitter />
                  </a>
                </div>
              </main>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default withRouter(SocialMedia);
