import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"

export class AnimationTextureTalisker_1 extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('talisker-texture-white.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(7.50, 13.34, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		var sc = 1.5;
		plane1.scale.set(sc, sc, sc);

		// set plane initial state
		plane1.position.set(0, -20, -4);


		const wordContainer = new THREE.Group();
		this.parent.add(wordContainer);
		wordContainer.rotation.set(0, 0, Math.PI / 2);
		wordContainer.position.set(4, 0, 1);

		for (var i = 0; i < 5; i++) {

			const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined, 'DIN Condensed W05', -20);
			wordContainer.add(word);
			word.position.set(1.5, 6 - i * 1.5, -3);
			word.material.map.offset.y = 1;
			gsap.to(word.material.map.offset, { delay: 0.05 * i, y: 0, ease: "expo.inOut", duration: this.duration });
			gsap.to(word.material.map.offset, { delay: this.duration + 0.05 * i, y: -1, ease: "expo.inOut", duration: this.duration });

		}

		const positionVector3 = new THREE.Vector3(-3, -10, 0);
		const coord = new Coordinates(this.parent, this.colors[2], this.duration, positionVector3);

		gsap.to(coord.position, { delay: 0, y: -3.8, ease: "expo.inOut", duration: this.duration });
		gsap.to(coord.position, { delay: this.duration, y: 20, ease: "expo.inOut", duration: this.duration });

		// animate
		//gsap.to(texture.offset,{delay:0, x:0, y:0, ease: "expo.inOut", duration:this.duration})
		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		//gsap.to(texture.offset,{delay:0, delay:this.duration, x:0, y:1, ease: "expo.inOut", duration:this.duration});
		gsap.to(plane1.position, {
			y: 20, delay: this.duration, ease: "expo.inOut", duration: this.duration, onComplete: function () {
				//self.isAnimating = false;
				self.parent.remove(wordContainer);
				self.parent.remove(coord);
				self.parent.remove(plane1);
			}
		});
	}

	destroy() {

	}

}

export default AnimationTextureTalisker_1; // Don’t forget to use export default!