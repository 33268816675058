import { useEffect } from "react"
import { EventManager, EVENTNAME } from "Utils/event-manager";

export const useOrientation = (mode = 'portrait') => {
  const toggleAdvise = (display) => {
    let orientationAdvise = document.querySelector('#miab-orientation-advise');
    if (!orientationAdvise) {
      orientationAdvise = document.createElement('div');
      orientationAdvise.id = 'miab-orientation-advise';
      orientationAdvise.setAttribute('style', `
        position: 'absolute';
        left: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        display: none;
        padding: 20px;
        overflow: hidden;
      `);
      orientationAdvise.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" style="margin: 0 auto;" viewBox="0 0 109 109"><g fill="#FFF" fill-rule="evenodd"><g transform="translate(53.372)"><path d="M47.07 0c4.726 0 8.558 3.877 8.558 8.66v90.928c0 4.783-3.832 8.66-8.559 8.66H8.56c-4.727 0-8.559-3.877-8.559-8.66V8.66C0 3.877 3.832 0 8.558 0H47.07zm0 3H8.557C5.57 3 3.12 5.408 3.004 8.437L3 8.66v90.928c0 3.06 2.38 5.54 5.34 5.656l.218.004H47.07c2.989 0 5.44-2.407 5.554-5.437l.005-.223V8.66c0-3.059-2.38-5.54-5.34-5.656L47.068 3z" fill-rule="nonzero"/><path d="M34.45 99.17a1.5 1.5 0 01.145 2.994l-.144.007H21.395a1.5 1.5 0 01-.144-2.993l.144-.007h13.056z" fill-rule="nonzero"/><ellipse cx="26.917" cy="10.825" rx="1.794" ry="1.804"/></g><g opacity=".2" transform="rotate(90 27.438 80.81)"><path d="M47.07 0c4.726 0 8.558 3.877 8.558 8.66v90.928c0 4.783-3.832 8.66-8.559 8.66H8.56c-4.727 0-8.559-3.877-8.559-8.66V8.66C0 3.877 3.832 0 8.558 0H47.07zm0 3H8.557C5.57 3 3.12 5.408 3.004 8.437L3 8.66v90.928c0 3.06 2.38 5.54 5.34 5.656l.218.004H47.07c2.989 0 5.44-2.407 5.554-5.437l.005-.223V8.66c0-3.059-2.38-5.54-5.34-5.656L47.068 3z" fill-rule="nonzero"/><path d="M34.45 99.17a1.5 1.5 0 01.145 2.994l-.144.007H21.395a1.5 1.5 0 01-.144-2.993l.144-.007h13.056z" fill-rule="nonzero"/><ellipse cx="26.917" cy="10.825" rx="1.794" ry="1.804"/></g><g fill-rule="nonzero"><path d="M30.84 10.511a1.5 1.5 0 012.014-.097l.107.097 7.292 7.292a1.5 1.5 0 01.097 2.014l-.097.107-7.293 7.293a1.5 1.5 0 01-2.218-2.015l.097-.107 6.23-6.23-6.23-6.233a1.5 1.5 0 01-.097-2.014l.097-.107z"/><path d="M17.495 23.556a23.311 23.311 0 0122.057-6.164 1.5 1.5 0 01-.712 2.914 20.312 20.312 0 00-19.224 5.371 20.234 20.234 0 00-5.91 13.058 1.5 1.5 0 01-2.993-.192 23.234 23.234 0 016.782-14.987z"/></g></g></svg>';

      document.body.append(orientationAdvise);
    }

    orientationAdvise.style.display = display ? 'flex' : 'none';
  }
  const toggleRoot = (display) => {
    const root = document.querySelector('#root');
    root.style.display = display ? 'block' : 'none';
    toggleAdvise(!display)
  }
  const handleOrientationChange = () => {
    const orientation = window.orientation;
    switch (orientation) {
      case -90: 
      case 90:
        /* Device is in landscape mode */
        toggleRoot(mode !== 'portrait');
        break;
      default:
        /* Device is in portrait mode */
        toggleRoot(mode === 'portrait');
        break
    }
  }
  handleOrientationChange();
  const eventManager = new EventManager({ eventName: EVENTNAME.ORIENTATION_CHANGE, handler: handleOrientationChange })
  useEffect(() => {
    eventManager.subscribe();
    return eventManager.unsubscribe;
  }, []);
}