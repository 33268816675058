
// Singleton set 1
export const AnimationMaskSingleton = require('./animationMaskSingleton').default
export const AnimationMaskSingleton_1 = require('./animationMaskSingleton_1').default
export const AnimationMaskSingleton_2 = require('./animationMaskSingleton_2').default
export const AnimationMaskSingleton_3 = require('./animationMaskSingleton_3').default
export const AnimationMaskSingleton_4 = require('./animationMaskSingleton_4').default

// Singleton set 2
export const AnimationBrushSingleton = require('./animationBrushSingleton').default
export const AnimationBrushSingleton_1 = require('./animationBrushSingleton_1').default
export const AnimationBrushSingleton_2 = require('./animationBrushSingleton_2').default
export const AnimationBrushSingleton_3 = require('./animationBrushSingleton_3').default
export const AnimationBrushSingleton_4 = require('./animationBrushSingleton_4').default

// Singleton set 3
export const AnimationZerosSingleton = require('./animationZerosSingleton').default
export const AnimationZerosSingleton_1 = require('./animationZerosSingleton_1').default
export const AnimationZerosSingleton_2 = require('./animationZerosSingleton_2').default
export const AnimationZerosSingleton_3 = require('./animationZerosSingleton_3').default
export const AnimationZerosSingleton_4 = require('./animationZerosSingleton_4').default

// Singleton set 4
export const AnimationSalmonSingleton = require('./animationSalmonSingleton').default
export const AnimationSalmonSingleton_1 = require('./animationSalmonSingleton_1').default
export const AnimationSalmonSingleton_2 = require('./animationSalmonSingleton_2').default
export const AnimationSalmonSingleton_3 = require('./animationSalmonSingleton_3').default
export const AnimationSalmonSingleton_4 = require('./animationSalmonSingleton_4').default

// Singleton set 5
export const AnimationMapSingleton = require('./animationMapSingleton').default
export const AnimationMapSingleton_1 = require('./animationMapSingleton_1').default
export const AnimationMapSingleton_2 = require('./animationMapSingleton_2').default
export const AnimationMapSingleton_3 = require('./animationMapSingleton_3').default
export const AnimationMapSingleton_4 = require('./animationMapSingleton_4').default

// Singleton set 6
export const AnimationBrandSingleton = require('./animationBrandSingleton').default
export const AnimationBrandSingleton_1 = require('./animationBrandSingleton_1').default
export const AnimationBrandSingleton_2 = require('./animationBrandSingleton_2').default
export const AnimationBrandSingleton_3 = require('./animationBrandSingleton_3').default
export const AnimationBrandSingleton_4 = require('./animationBrandSingleton_4').default

// Singleton occasions
export const AnimationOccasion = require('./animationOccasion').default


