import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Brush from "../common/Brush.js"
import TextLine from "../common/textLine.js"


export class AnimationBrushSingleton extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

    const self = this;
    const positionVector3 = new THREE.Vector3(1, -1, 0 );
    var brush = new Brush(this.parent, positionVector3, 0, this.colors[1], this.duration);
    brush.rotation.set(0,0,Math.PI/8);

    const positionVector3b = new THREE.Vector3(-1, -5, 0 );
    var brush2 = new Brush(this.parent, positionVector3b, 0.1, this.colors[1], this.duration);
    brush2.rotation.set(0,0,0);

    const word = new TextLine(this.keyword, 1.5, 1.5, 400, this.colors[0], undefined, "Authenia W05");
    this.parent.add(word);

    word.position.set(0,0,4);
    word.rotation.set(0,0,Math.PI/2);

    word.material.map.offset.set(1,0);
    gsap.to(word.material.map.offset,{delay:0.1, x:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word.material.map.offset,{delay:this.duration+0.1, x:-1, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(brush);
      self.parent.remove(brush2);
      self.parent.remove(word);
    }});


  }

 
  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationBrushSingleton; // Don’t forget to use export default!