import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Parallelogram from "../common/Parallelogram.js"
import TextLine from "../common/textLine.js"


export class AnimationParallelogram_2 extends AnimationTemplate {
	
  constructor(parent, colors, duration, keyword) {
  	
    super(parent, colors, duration, keyword);

  }

  start(){

  	var self = this;
 	     
    // create plane 
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 4; i++) {
      const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined,  "JWCapsSerif-Book", 20);
      wordContainer.add(word);

      var x = -8;
      if (i % 2 === 0) {  
        x = 8;   
      }
      word.position.set(x,-5-i*1.7,-9);
      //word.material.map.offset.y = 1;
      gsap.to(word.position,{delay:0.05*i, x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.position,{delay:this.duration+0.05*i, y:15, ease: "expo.inOut", duration:this.duration})
    }

    // set plane initial state
    plane1.position.set(0,-350,-150);

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

    this.createParallelogram( 0, this.duration / 2, this.colors[0] );

    gsap.to(plane1.position,{delay:this.duration, y:340, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(wordContainer);
    }});

  }

  createParallelogram( yPosition, startDelay, color ){

  	var parallelogram = new Parallelogram( this.parent, yPosition, startDelay, color, this.duration );

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationParallelogram_2; // Don’t forget to use export default!