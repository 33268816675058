import { toUpperCase } from ".";
import { GetAssetPath, GetGenericAnimationAssetPath } from "./get-path";

const sanitise = (prop) =>
  prop.map(({ imageUrl, imagePosition, ...rest }) => ({
    imageUrl: GetGenericAnimationAssetPath(imageUrl),
    imagePosition: toUpperCase(imagePosition),
    ...rest,
  }));

export const sanitiseTexture = ({
  logo,
  repeat,
  singleItem,
  occasion,
  photography,
}) => ({
  logo: sanitise(logo),
  repeat: sanitise(repeat),
  single: sanitise(singleItem),
  occasion: sanitise(occasion),
  photography: sanitise(photography),
});

export const sanitiseBackgroundMusic = (backgroundMusic) =>
  backgroundMusic.map((music) => GetGenericAnimationAssetPath(music));

export const sanitiseColour = (colour) => parseInt(colour.replace("#", "0x"));

export const sanitiseProfilePic = (profilePic) => {
  const imgMatchMattern = /\.(jpe?g|png|gif|svg)$/gi;
  return imgMatchMattern.test(profilePic) ? profilePic : null;
};
