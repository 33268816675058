export class EventManager {
  constructor(props) {
    const { el = window, eventName = null, handler = null } = props || {};
    this.element = el;
    this.eventName = eventName;
    this.handler = handler;
    this.subscribe = this.subscribe.bind(this);
    this.unsubscribe = this.unsubscribe.bind(this);
  }

  subscribe() {
    if (this.eventName && this.handler) {
      this.element.addEventListener(this.eventName, this.handler);
    }
  }

  unsubscribe() {
    if (this.eventName && this.handler) {
      this.element.removeEventListener(this.eventName, this.handler);
    }
  }

  dispatch(detail = {}) {
    if (this.eventName) {
      this.element.dispatchEvent(new CustomEvent(this.eventName, { detail }));
    }
  }
}

export const EVENTNAME = {
  PLAY_MESSAGE_IN_A_BOTTLE: 'PLAY_MESSAGE_IN_A_BOTTLE',
  ORIENTATION_CHANGE: "orientationchange"
}