import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Salmon from "../common/Salmon.js"
import TextLine from "../common/textLine.js"


export class AnimationSalmonSingleton_1 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;

    const positionVector3 = new THREE.Vector3(2, -3, 0);

    const salmon = new Salmon(this.parent, this.colors[4], this.duration, positionVector3)
    salmon.rotation.set(0,0,Math.PI/4);
    salmon.material.opacity = 0;
    gsap.to(salmon.rotation,{delay:0, z:0, ease: "expo.inOut", duration:this.duration});
   
    gsap.to(salmon.material,{delay:0, opacity:1, ease: "expo.inOut", duration:this.duration});
    gsap.to(salmon.material,{delay:this.duration, opacity:0, ease: "expo.inOut", duration:this.duration});

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);
    for (var i = 0; i < 3; i++) {
        const word = new TextLine(this.keyword.toUpperCase(), 1.5, 1.5, 400, this.colors[4], undefined, 'Korolev Compressed Bold');
        wordContainer.add(word);
        word.position.set(0,5.5-i*1.2,0);
        word.material.map.offset.y = 1;
        gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
        gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});
    }

     gsap.to(salmon.rotation,{delay:this.duration, z:-Math.PI/4, ease: "expo.inOut", duration:this.duration,onComplete:function(){
      self.parent.remove(wordContainer);
      self.parent.remove(salmon);
    }})
   
  }
   
}

export default AnimationSalmonSingleton_1; // Don’t forget to use export default!