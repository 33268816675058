import { useEffect } from "react";
import { useSelector } from "react-redux";
import { OrderToken } from "Utils/web-storage";

export const useAuthorable = (redirectLater = false) => {
  const messagesData = useSelector(
    (state) => state.messagesReducer.messagesData
  );
  const redirect = () => {
    if (!messagesData || messagesData.length === 0) {
      const isProd = process.env.NODE_ENV === 'production';
      const orderToken = OrderToken().value;
      const url = orderToken ? `${isProd ? '/order' : ''}/?orderToken=${orderToken}` : '/author/order/not-found';
      window.location.assign(url);
      return;
    }
  }
  useEffect(() => {
    if (!redirectLater) {
      redirect();
    }
  }, [messagesData]);

  return { redirect };
}