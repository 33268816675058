import * as THREE from "three";
import gsap from "gsap";
import { GetAssetPath } from "Services/apiService";

export class Brush {

  constructor(parent, positionVector3D, startDelay, color, duration) {


    const imagePath = GetAssetPath('brush.png');

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(imagePath);

    // create plane 
    const geometry = new THREE.PlaneGeometry(3, 12, 32);
    const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });

    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    texture.offset.set(0, -1);

    plane.position.set(positionVector3D.x, positionVector3D.y, positionVector3D.z);

    gsap.to(texture.offset, { y: 0, ease: "expo.inOut", delay: startDelay, duration: duration });
    gsap.to(texture.offset, { y: 1, ease: "expo.inOut", delay: startDelay + duration, duration: duration });


    return plane;
  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default Brush; // Don’t forget to use export default!