import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"

export class AnimationMaskTalisker extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('talisker-1.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(50.8, 90.2, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		// set plane initial state
		plane1.position.set(0, -250, -90);
		//plane1.rotation.set(0,Math.PI/2,0);
		//plane1.scale.set(0,0,0);

		const tl = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[1], undefined, 'DIN Condensed W05', -20);
		this.parent.add(tl);
		tl.position.set(0, -70, -2);

		const positionVector3 = new THREE.Vector3(-3, -10, 0);
		const coord = new Coordinates(this.parent, this.colors[1], this.duration, positionVector3);

		gsap.to(coord.position, { delay: 0, y: -3.8, ease: "expo.inOut", duration: this.duration });
		gsap.to(coord.position, { delay: this.duration, y: 20, ease: "expo.inOut", duration: this.duration });

		// animate
		gsap.to(texture.offset, { delay: 0, x: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })

		gsap.to(tl.position, { delay: 0, y: 5, ease: "expo.inOut", duration: this.duration })

		gsap.to(texture.offset, { delay: 0, delay: this.duration, x: 0, y: 1, ease: "expo.inOut", duration: 1 });
		gsap.to(tl.position, { delay: this.duration, y: 150, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, {
			y: 250, delay: this.duration, ease: "expo.inOut", duration: 1, onComplete: function () {
				//self.isAnimating = false;
				self.parent.remove(plane1);
				self.parent.remove(tl);
				self.parent.remove(coord);

			}
		});
	}

	destroy() {

	}

}

export default AnimationMaskTalisker; // Don’t forget to use export default!