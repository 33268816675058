import * as THREE from "three";
import { GetAssetPath } from "Services/apiService";


export class TaliskerSea {

  constructor(parent, color, duration, positionVector3) {

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(GetAssetPath('talisker-sea.png'));

    // create plane 
    const geometry = new THREE.PlaneGeometry(0.18, 3.62, 2);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y, positionVector3.z);

    return plane;
  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default TaliskerSea; // Don’t forget to use export default!