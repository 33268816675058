import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "Assets/img/miab-logo.svg";
import { ReactComponent as ArrowLeft } from "Assets/img/arrow-left.svg";
import { ReactComponent as ArrowRight } from "Assets/img/arrow-right.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { Row, Col } from "antd";
import { loadMessages } from "Services/messagesService";
import { getTranslation } from "Services/translationService";
import { useHistory, Link } from "react-router-dom";
import "./BrandSelect.scss";
import axios from "axios";
import { setBrandKey, setProductKey, setCustomerEmail } from "../../store/actions";
import { apiUrl } from "Utils/common";
import { translationReducerSelector } from "Store/reducers/translation";

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const BrandSelectPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const translation = offTradeTranslationData?.consumerOffTrade;
  const productSelectTranslations = translation?.ProductSelectionPage;

  const qrCode = useSelector((state) => state.common.qrCode);
  const productKey = useSelector((state) => state.common.productKey);
  const RetailerKey = useSelector((state) => state.common.retailerId);
  const text = useSelector(translationReducerSelector, shallowEqual);
  const customerEmail = useSelector((state) => state.common.customerEmail);

  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [isProductSelected, setIsProductSelected] = useState(false);

  const [selectedBrandKey, setBrandKeySelection] = useState("");
  const [selectedProductKey, setProducKeySelection] = useState("");
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [email, setEmail] = useState(customerEmail);
  const [emailError, setEmailError] = useState("");

  // Initially load in the brands
  useEffect(() => {
    var bodyFormData = new FormData();
    bodyFormData.append("RetailerKey", RetailerKey);

    axios({
      method: "POST",
      baseURL: apiUrl(),
      url: "offtrade/brands", // Todo: Change to use .env
      data: { RetailerKey: RetailerKey },
    })
      .then(function (response) {
        const brandsData = response.data.map((obj) => {
          return {
            brand: obj.brand,
            image: obj.image,
            key: obj.key,
            isSelected: false,
          };
        });

        setBrands(brandsData);
      })
      .catch(function (response) {
        console.error(response);
      });
  }, [RetailerKey]);

  // dispatch(fetchBrands({ RetailerKey: RetailerKey }));

  const onSelectBrand = (key) => {
    dispatch(setBrandKey(key));

    // Updated the is selected
    brands.forEach(function (brand) {
      brand.isSelected = brand.key === key;
    });

    axios({
      method: "POST",
      baseURL: apiUrl(),
      url: "offtrade/products",
      data: { RetailerKey: RetailerKey, BrandKey: key },
    })
      .then(function (response) {
        const productsData = response.data.map((obj) => {
          return {
            product: obj.product,
            sku: obj.sku,
            image: obj.image,
            key: obj.key,
            isSelected: false,
          };
        });

        setProducts(productsData);
        setBrandKeySelection(key);
        setIsBrandSelected(true);
      })
      .catch(function (response) {
        console.error(response);
      });
  };

  const onSelectProduct = (key) => {
    // Updated the is selected
    products.forEach(function (product) {
      product.isSelected = product.key === key;
    });

    dispatch(setProductKey(key));
    setProducKeySelection(key);
    setIsProductSelected(true);
  };

  const onFinish = () => {
    console.log("Email is: ", email);

    if (email.length === 0) {
      setEmailError("You need to enter an email address."); // todo: Use translations
      console.log("EMAIL INVALID - EMPTY");
      return;
    }

    if (REGEX_EMAIL.test(email) || email.length === 0) {
      console.log("email is valid");
    } else {
      setEmailError("Invalid Email"); // Todo. Missing From response
      return;
    }

    dispatch(setCustomerEmail(email));

    axios({
      method: "POST",
      baseURL: apiUrl(),
      url: "offtrade/setProduct", // todo: Prod or dev
      data: { QrCode: qrCode, ProductKey: productKey },
    })
      .then(function (response) {
        console.log(response);
        getMessage(response.data.OrderToken);
      })
      .catch(function (response) {
        console.error(response);
      });
  };

  const getMessage = async (orderToken) => {
    await dispatch(loadMessages(orderToken));
    await dispatch(getTranslation(orderToken, true));
  };

  useEffect(() => {
    if (text) {
      history.push("/author/steps/gift-for");
    }
  }, [text]);

  const handleEmailOnChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="page-wrap page-wrap--dark sway-wrap">
      <div className="consumer-navigation">
        <Logo className="consumer-navigation__logo" />
        <Bottle className="consumer-navigation__bottle" />
        <div className="nav-menu">
          <Link to="/consumer/faq" className="help-link">
            {translation?.NeedHelp}
          </Link>
        </div>
      </div>
      <section className="page-wrap page-wrap--light">
        <main className="ant-layout">
          <div className="container">
            <div className="consumer__title-wrapper consumer__title-wrapper--bottle-select">
              <h1>
                <span className="font-spatial-x-small">
                  {productSelectTranslations?.MainHeading}
                </span>
                <span className="font-tight-x-large font-tight-x-large--alt">
                  {productSelectTranslations?.SubHeading}
                </span>
              </h1>
              <button
                className="consumer__back-btn"
                onClick={() => history.goBack()}
              >
                <ArrowLeft />
                <span> {translation?.Back}</span>
              </button>
            </div>
            <Row justify="center" align="middle">
              <Col xs={24} md={20} lg={20}>
                <div className="brand-select__email-block">
                  <p className="brand-select__email-label">
                    {productSelectTranslations?.EmailLabel}
                  </p>
                  <Input
                    onChange={handleEmailOnChange}
                    value={email}
                    type="text"
                    placeholder={productSelectTranslations?.EmailPlaceholder}
                    className="brand-select__email-input font-tight-small"
                  />
                  <div className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">{emailError}</div>
                  </div>
                </div>

                <div className="brand-select">
                  <p>{productSelectTranslations?.BrandLabel}:</p>
                  <div className="brand-select__container">
                    <ul className="brand-select__list">
                      {brands.map((brandItem) => {
                        const { key, brand, image, isSelected } = brandItem;

                        return (
                          <li
                            data-brand={key}
                            className={
                              isSelected
                                ? "brand-select__brand--selected"
                                : "brand-select__brand"
                            }
                            key={key}
                            onClick={() => onSelectBrand(key)}
                          >
                            <img
                              data-brand={key}
                              className="brand-select__image"
                              alt={brand}
                              src={image}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {isBrandSelected && (
                  <div className="bottle-select">
                    <div className="bottle-select__container">
                      <p> {productSelectTranslations?.ProductLabel}</p>
                      <ul className="bottle-select__list">
                        {products.map((productResult) => {
                          const { key, product, image, isSelected } =
                            productResult;
                          return (
                            <li
                              data-product={key}
                              key={key}
                              onClick={() => onSelectProduct(key)}
                              className={
                                isSelected
                                  ? "bottle-select__product--selected"
                                  : "bottle-select__product"
                              }
                            >
                              <img
                                data-product={key}
                                src={image}
                                alt={product}
                                className="bottle-select__product-image"
                              />
                              <p className="bottle-select__product-name">
                                {product}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    {isProductSelected && (
                      <Button
                        type="primary"
                        shape="round"
                        onClick={() => onFinish()}
                      >
                        Next
                        <ArrowRight />
                      </Button>
                    )}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </main>
      </section>
    </div>
  );
};

export default BrandSelectPage;
