import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"
import Stamp from "../common/Stamp.js"

export class AnimationStampTalisker_2 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	var self = this;

	// create plane  2
	const geometry2 = new THREE.PlaneGeometry(200 , 400, 2 );
	const material2 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
	const plane2 = new THREE.Mesh( geometry2, material2 );
	this.parent.add( plane2 );

	// set plane initial state
	plane2.position.set(0, -300,-200);
	plane2.scale.set(1,0,1);

	const positionVector3 = new THREE.Vector3(0.5, -2.7, 6);
	const logo = new Stamp(this.parent, this.colors[2], this.duration, positionVector3);
	logo.rotation.set(0,0,Math.PI/2);
	gsap.to(logo.position,{delay:0, y:-0.7, ease:"expo.inOut", duration:this.duration});
	gsap.to(logo.rotation,{delay:0, z:0, ease:"expo.inOut", duration:this.duration});
	gsap.to(logo.position,{delay:this.duration, y:3, ease:"expo.inOut", duration:this.duration});
	gsap.to(logo.rotation,{delay:this.duration, z:-Math.PI/2, ease:"expo.inOut", duration:this.duration});

	const positionVector3b = new THREE.Vector3(-3, -10, 0 );
	const coord = new Coordinates(this.parent, this.colors[2], this.duration, positionVector3b);	
	gsap.to(coord.position,{delay:0, y:-2.8, ease: "expo.inOut", duration:this.duration});
	gsap.to(coord.position,{delay:this.duration, y:20, ease: "expo.inOut", duration:this.duration});

	const wordTop = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[0], undefined, 'DIN Condensed W05', -20);
	this.parent.add(wordTop);
	wordTop.position.set(0,-10,-4);

	gsap.to(wordTop.position,{delay:0, y:5.7, ease: "expo.inOut", duration:this.duration});
	gsap.to(wordTop.position,{delay:this.duration, y:14, ease: "expo.inOut", duration:this.duration});

	// animate
	gsap.to(plane2.scale,{y:1, ease: "expo.inOut", duration:this.duration})
	gsap.to(plane2.position, {y: 0, delay:0, ease: "expo.inOut", duration:this.duration});
	gsap.to(plane2.position, {y: 400, delay:this.duration, ease: "expo.inOut", duration:this.duration, onComplete:function(){

		self.parent.remove(logo);
		self.parent.remove(plane2);
		self.parent.remove(wordTop);
		self.parent.remove(coord);
	
	}});

  }

  destroy(){

  }
   
}

export default AnimationStampTalisker_2; // Don’t forget to use export default!