var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState, useRef } from "react";
import { classlist } from 'easy-class';
import { EventManager, EVENTNAME } from "Utils/event-manager";
import './BasicPlayer.scss';
var BasicPlayer = function (_a) {
    var statusFeedback = _a.statusFeedback, _b = _a.sampleSize, sampleSize = _b === void 0 ? 4 : _b, _c = _a.sampleRate, sampleRate = _c === void 0 ? 75 : _c, audioFile = _a.audioFile, onEnd = _a.onEnd;
    var audioRef = useRef(null);
    var _d = useState(Array(sampleSize).fill(0)), samplePoints = _d[0], setSamplePoints = _d[1];
    var _e = useState(false), playing = _e[0], setPlaying = _e[1];
    var changeStatus = function (value) {
        if (statusFeedback)
            statusFeedback(value);
        setPlaying(value);
    };
    var getAudioSamples = function () {
        setSamplePoints(function (prev) {
            var newPoints = [];
            prev.forEach(function (i) { return newPoints.push(
            // At the moment the sample is random - but this can be made to
            // pull from the audio source with some extra time.
            Math.max(0, Math.min(100, Math.random() * 100))); });
            return newPoints;
        });
    };
    var resetSamples = function () {
        setSamplePoints(function (prev) {
            var newPoints = Array(sampleSize).fill(0);
            return newPoints;
        });
    };
    useEffect(function () {
        if (!playing) {
            playAudioSource(false);
            resetSamples();
            return;
        }
        ;
        // Get audio samples for the spectrum for a specified interval.
        var timer = setInterval(function () { return getAudioSamples(); }, sampleRate);
        playAudioSource(true);
        return function () { return clearInterval(timer); };
    }, [playing]);
    useEffect(function () {
        if (!audioRef.current || !audioFile) {
            return;
        }
        if (!audioRef.current.src) {
            audioRef.current.addEventListener('ended', function () {
                setPlaying(false);
                if (onEnd)
                    onEnd();
            });
            audioRef.current.addEventListener('error', function () { return setPlaying(false); });
        }
        ;
        var eventManager = new EventManager({
            eventName: EVENTNAME.PLAY_MESSAGE_IN_A_BOTTLE,
            handler: function () { return changeStatus(!playing); },
        });
        eventManager.subscribe();
        audioRef.current.src = audioFile;
        return function () { return eventManager.unsubscribe(); };
    }, [!!audioRef.current]);
    var playAudioSource = function (play) {
        if (!audioRef)
            return;
        if (play)
            audioRef.current.play();
        else
            audioRef.current.pause();
    };
    var renderAudioSpectrum = useCallback(function () {
        return _jsx("div", __assign({ className: "BasicPlayer__spectrum" }, { children: samplePoints.map(function (x, i) { return (_jsx("span", { className: "BasicPlayerSample", style: {
                    height: "".concat(samplePoints[i], "%")
                } }, i)); }) }));
    }, [samplePoints]);
    return (_jsxs("div", __assign({ className: classlist("BasicPlayer", playing && "isPlaying") }, { children: [_jsx("audio", { style: { display: "none" }, ref: audioRef }), renderAudioSpectrum(), _jsx("button", { className: "BasicPlayer__button", onClick: function () { return changeStatus(!playing); } })] })));
};
export default BasicPlayer;
