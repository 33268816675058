import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { POLLING } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  pollingState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  data: null,
  error: false,
};

export const {
  start: onStart,
  success: onSuccess,
  fail: onFailure,
} = defineLoopActions(POLLING);

export const fetchPolling = (data) => {
  console.log('REQUEST TRANSCRIBE')
  return apiAction({
    url: "/authoring/requestTranscribe",
    method: "POST",
    data,
    onStart,
    onSuccess,
    onFailure,
    label: POLLING,
  });
};

export const pollingReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: POLLING,
      onSuccess: (state, payload) => {
        
        return {
          ...state,
          isLoading: true,
          pollingState: REQUEST_STATUS.SUCCESS,
          data: payload,
          error: null,
        };
      },
      onFailure: (state) => ({
        ...state,
        pollingState: REQUEST_STATUS.FAIL,
        data: null,
        error: true,
      }),
    }),
  },
  initialState
);
