import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Bottle from "../common/Bottle.js"
import TextLine from "../common/textLine.js"

export class AnimationBottle_1 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    var self = this;
       
    // create plane 
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[0], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

        // set plane initial state
    plane1.position.set(0,-350,-150);

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 2; i++) {
      const word = new TextLine(this.keyword, 2, 2, 400, this.colors[3], undefined, "JWCapsSerif-Book", 20);
      wordContainer.add(word);
      word.position.set(-3.5+i*7.2,-5+i*10,-2);
      word.rotation.set(0,0,Math.PI/2);
      word.material.map.offset.x = 1;
      gsap.to(word.material.map.offset,{x:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration, x:-1, ease: "expo.inOut", duration:this.duration})
    }
  
    var positionVector3 = new THREE.Vector3(0, 0, -15 );
    new Bottle(this.parent, this.colors[3], this.duration, positionVector3); 

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration,onComplete:function(){
      self.parent.remove(wordContainer);
      self.parent.remove(plane1);
    }});

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationBottle_1; // Don’t forget to use export default!