import React from 'react';
import './EmailCopy.scss';
import './RecipientEnd.scss';
import './../RecipientFrame.scss';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Form, Input, Checkbox } from 'antd';
import { Footer } from 'Components/Layout';
import { ReactComponent as CloseIcon } from 'Assets/img/circle-close.svg';
import { ReactComponent as Bottle } from 'Assets/img/miab-bottle-alt.svg';
import { useTranslation } from 'Contexts/translation-context';

export const EmailCopy = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSend = () => {
  };
  const handleCancel = () => {
    history.push('/recipient/end');
  };

  return (
    <div className="page-wrap">
      <section className="recipient-end">
        <div className="container">
          <div className="recipient-frame">
            <div className="recipient-frame__inner">
              <div className="recipient-end__header recipient-end__header--spacer">
                <Bottle className="recipient-end__bottle-image" />
                <CloseIcon
                  className="recipient-end__close-icon"
                  onClick={handleCancel}
                />
              </div>
              <main className="ant-layout">
                <h1 className="email-copy__heading font-tight-x-large">
                  {t("EMAIL_ME_A_COPY")}
                </h1>
                <Form
                  className="email-copy__form"
                  name="nest-messages"
                  onFinish={onSend}
                >
                  <Form.Item
                    style={{ justifyContent: 'center' }}
                    name={'email'}
                    rules={[
                      {
                        type: 'email',
                        message: t("VALIDATION_EMAIL_INVALID"),
                      },
                      {
                        required: true,
                        message: t("VALIDATION_EMAIL_EMPTY"),
                      },
                    ]}
                  >
                    <Input
                      className="email-copy__input"
                      placeholder="recipient@email.com"
                      type="email"
                    />
                  </Form.Item>

                  <div className="email-copy__terms">
                    <Checkbox
                      style={{ display: 'flex', alignItems: 'flex-start' }}
                      className="checkbox-light"
                      onChange=""
                    >
                      <p> {t("TO_RECEIVE_T_N_C")} </p>
                    </Checkbox>
                  </div>
                  <Form.Item>
                    <Button
                      className="email-copy__send-cta"
                      type="primary"
                      shape="round"
                      htmlType="submit"
                    >
                      {t("SEND")}
                    </Button>
                  </Form.Item>
                </Form>
              </main>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};
export default withRouter(EmailCopy);
