import React, { PureComponent, useEffect, useMemo } from "react";
import axios from "axios";
import { TranslationContext } from "Contexts/translation-context";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import getTranscribeJobStatus from "../../../utils/poll-transcribe";
import Timer from "Components/Audio/Timer";
import getBlobDuration from "get-blob-duration";
import MicRecorder from "mic-recorder-to-mp3";
import AudioPlayer from "Components/Audio/AudioPlayer";
import AudioRecorder from "Components/Audio/AudioRecorder";

import { clearRecording, fetchAddRecoredVoice } from "Store/reducers/record";
import * as actions from "Store/actions/index";

import { Button, Spin, Input, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as MicrophoneIcon } from "Assets/img/microphone.svg";
import { ReactComponent as ArrowDown } from "Assets/img/arrow-down.svg";
import { fetchAddText } from "Store/reducers/record-text";
import { getPayload } from "Utils/get-payload";
import { clearCommonData, setCommonData } from "Store/actions/common";
import { Heading } from "Components/heading";
import { allowedTextCultures } from "../../../hooks/useAllowTextJourney";

import "./Record.scss";
import { apiUrl } from "../../../utils/common";
import TextFitter from "Components/Layout/TextFitter";

// Initialises the microphone
const MAX_RECORD_DURATION_SECONDS = 30; // Todo: This should be dynamic from the CMS in maintainance.
const LOADING_TIP_INTERVAL_SECONDS = 6; // Todo: This should be dynamic from the CMS in maintainance.
const recorder = new MicRecorder({
  bitRate: 128,
});

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const NonTranscribeCultures = ["el-GR"];

class Record extends PureComponent {
  recorderRef = React.createRef();

  static contextType = TranslationContext;

  state = {
    audioURL: "",
    recordStatus: "isReady",
    file: null,
    duration: 0,
    counter: 0,
    canRecord: navigator.mediaDevices ?? false,
    completedPlaybackKey: 0,
    has_profanity: false,
    has_error: false,
    has_transcribe_error: false,
    recordingTime: 0,
    jobName: null,
    error_message: null,
    allowTextJourney: false,
  };

  init() {
    this.jobName = this.props.addVoiceResponse;
  }

  constructor(props) {
    super(props);
    this.init();
  }

  componentDidMount() {
    const { messagesData } = this.props;

    if (messagesData.length === 0) {
      this.props.history.push("/author/main-steps");
      return;
    }

    this.timeout = setInterval(() => {
      let curCount = this.state.counter;
      this.setState({ counter: curCount + 1 });
    }, LOADING_TIP_INTERVAL_SECONDS * 1000);
    this.managePageHeight();

    this.setState((prev) => ({
      ...prev,
      allowTextJourney: allowedTextCultures.includes(this.props.languageCode),
    }));
  }
  areEqual(curr, prev) {
    const prps = [
      "slug",
      "addTextResponse",
      "addVoiceResponse",
      "addVoiceError",
    ];
    const [prevProps, currProps] = [prev, curr].map((a) =>
      prps.reduce((acc, key) => {
        acc[key] = a[key];
        return acc;
      }, {})
    );
    return JSON.stringify(prevProps) === JSON.stringify(currProps);
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  componentDidUpdate(prevProps) {
    if (this.areEqual(this.props, prevProps)) {
      return;
    }
    const { slug, addTextResponse, addVoiceResponse, addVoiceError } =
      this.props;

    const isTextClear =
      slug === "text" &&
      addTextResponse &&
      (Array.isArray(addTextResponse.HasProfanity)
        ? addTextResponse.HasProfanity.length === 0
        : !addTextResponse.HasProfanity);
    if (isTextClear) {
      this.addImage();
      return;
    }

    if (addVoiceError) {
      const hasProfanities = addVoiceError.message.HasProfanity;
      console.warn("ADD VOICE ERROR!");
      console.warn(hasProfanities);
      if (hasProfanities) {
        this.setState({
          recordStatus: "isReady",
          has_profanity: true,
        });
      } else {
        this.setState({
          recordStatus: "isReady",
          has_profanity: false,
          has_error: true,
        });
      }
    }

    // TODO: Check for transcribe errors here and reset the state

    if (slug === "audio" && addVoiceResponse && !addVoiceError) {
      if (!addVoiceResponse.has_profanity) {
        this.setState((prev) => ({ ...prev, recordStatus: "completed" }));
      } else {
        this.setState((prev) => ({
          ...prev,
          recordStatus: "isReady",
          pollingMaxed: false,
        }));
      }
    }
    this.managePageHeight();
  }

  // componentWillUnmount() {
  //   clearInterval(this.timeout);
  // }

  start = async () => {
    // clear state
    this.props.clearRecording();

    this.setState({
      audioURL: "",
      recordStatus: "getStarted",
      pollingMaxed: false,
    });

    // sets state which shows countdown <Timer> (3,2,1), which in it has a timeout that triggers the recording() method
    setTimeout(() => {
      this.setState({
        recordStatus: "starting",
      });
    }, 1000);
  };

  recording = async () => {
    if (this.state.recordStatus === "recording") return;

    recorder
      .start()
      .then(() => {
        this.setState({ recordStatus: "recording" });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  stop = async () => {
    recorder
      .stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });

        console.log("file", file);

        let fileBuffer = await file.arrayBuffer();
        let fileBufferArray = Array.from(new Uint8Array(fileBuffer));

        this.props.setCommonData({
          writtenTextMesssage: null,
          audioBlob: file,
        });

        getBlobDuration(file).then((duration) => {
          this.setState({
            duration: Math.round(duration),
            audioURL: URL.createObjectURL(file),
          });
        });

        const data = {
          ...getPayload(this.props),
          Audio: fileBufferArray,
        };

        // this.props.fetchAddRecoredVoice(data);

        // Todo: Move to hook Return the Job Number
        let MessageToken = "";
        var messagesData = this.props.messagesData;

        if (messagesData.length === 1) {
          MessageToken = messagesData[0].message_token;
        } else {
          const message = messagesData.find(
            (data) => data.id === this.props.bottleId
          );
          MessageToken = message.message_token;
        }

        const payload = {
          Audio: fileBufferArray,
          MessageToken: MessageToken,
          ProductKey: this.props.productKey,
        };

        const occasionId = this.props.occasionData.occasionId;
        console.log("occasionID: ", occasionId);
        console.info("Sending payload to AddVoice API Endpoint:", payload);

        axios({
          method: "POST",
          baseURL: apiUrl(),
          url: "authoring/addVoice", // Todo: Change to use .env
          data: payload,
        })
          .then((response) => {
            const { job_name, created } = response.data;

            if (NonTranscribeCultures.includes()) {
              return;
            }
            // this.setState((prev) => ({ ...prev, recordStatus: "completed" }));

            // Now we poll the transcribe SDK API call until we get back a job status of "COMPLETED.".
            getTranscribeJobStatus(job_name).then((response) => {
              console.log("TRANSCRIBE RESPONSE!!", response);
              // This then hooks into the original redux method. It will call the backend endpoint createExpirience
              // which will then dispatch if Profanities is true or false to the state and UI and pick up in the existing/original journey.
              const payload = {
                MessageToken,
                OcassionId: occasionId,
                TranscribeFile: job_name,
              };
              this.props.fetchAddRecoredVoice(payload);
            });
          })
          .catch(function (response) {
            console.error(response);
          });
      });

    this.setState({
      recordStatus: "pending",
    });
  };

  reRecord = (start = true) => {
    this.props.clearRecording();
    this.setState({ recordStatus: "isReady" });
    if (start) {
      this.start();
    }
  };

  reset = () => {
    this.setState({ recordStatus: "starting" });
  };

  addImage = () => {
    this.props.history.push("/author/steps/image");
  };

  onChange = (e) => {};
  clearAudioOrTextCommonData(slug = "audio") {
    this.props.clearCommonData({
      ...(slug === "text" ? { audioBlob: null } : {}),
      ...(slug === "audio" ? { writtenTextMesssage: null } : {}),
    });
  }

  selectMsgType = (msgType) => {
    const { onlyWrittenMsg } = this.props;
    const type = onlyWrittenMsg ? "text" : msgType;
    this.clearAudioOrTextCommonData(type);
    this.props.setSlug(type);
  };

  onSendMessage = ({ message: Message }) => {
    this.props.setCommonData({
      writtenTextMesssage: Message,
      audioBlob: null,
    });
    this.props.fetchAddText({ ...getPayload(this.props), Message });
  };

  onLoadingTips = () => {
    const { bottleId, messagesData } = this.props;

    let loadingTips = [];

    if (messagesData.length === 0) return null;
    if (messagesData.length === 1) loadingTips = messagesData[0].loading_tips;
    else {
      const message = messagesData.find((data) => data.id === bottleId);
      loadingTips = message.loading_tips;
    }
    return loadingTips[this.state.counter % loadingTips.length].name;
  };

  managePageHeight = () => {
    const section = document.querySelector(".ant-layout.ant-layout-has-sider");
    if (section) {
      if (this.state.recordStatus === "completed") {
        section.classList.add("with-min-height");
      } else {
        section.classList.remove("with-min-height");
      }
    }
  };

  render() {
    const { t } = this.context;
    const {
      recordStatus,
      audioURL,
      duration,
      has_error,
      has_profanity,
      has_transcribe_error,
      error_message,
    } = this.state;

    const { slug, addTextResponse, addVoiceResponse, addVoiceError } =
      this.props;

    return (
      <div className="page-wrap page-wrap--light page-wrap--audio-recorder">
        <div className="container">
          {slug === "audio" && (
            <>
              <h1 className="multi-size -half-margin">
                <TextFitter
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Heading keyName="RECORD_YOUR_MESSAGE" />
                </TextFitter>
              </h1>
              <div className="record-message">
                <div className="record-message__description">
                  <p>{t("KEEP_IT_CLEAN_NOTE")}</p>
                  <div>
                    <p>
                      {t("TOP_TIP")}↘
                      <br />
                      {t("RECORDING_TIP", [MAX_RECORD_DURATION_SECONDS])}
                    </p>
                    {this.state.allowTextJourney && (
                      <p>
                        <Link
                          to="#"
                          className="underlined underlined--dark"
                          onClick={() => this.selectMsgType("text")}
                        >
                          {t("OR_TYPE_MESSAGE")}
                        </Link>
                      </p>
                    )}
                  </div>
                </div>
                <div className="audio-recorder">
                  {recordStatus === "isReady" && (
                    <>
                      <Button
                        onClick={this.start}
                        type="primary"
                        className="audio-recorder__start-button"
                      >
                        <span className="offset">
                          <MicrophoneIcon />
                          {t("START_RECORDING")}
                        </span>
                      </Button>

                      {has_profanity && (
                        <span className="has-profanity profanity-msg">
                          {t("BOT_ERROR_PROFANITY")}
                        </span>
                      )}

                      {has_error && (
                        <span className="has-profanity polling-maxed-msg">
                          {t("AUDIO_ERROR_UNKNOWN")}
                        </span>
                      )}

                      {has_transcribe_error && (
                        <span className="has-profanity polling-maxed-msg">
                          AWS Transcribe Error: {error_message}
                        </span>
                      )}
                    </>
                  )}
                  {recordStatus === "getStarted" && (
                    <div className="audio-recorder__overlay -align-center">
                      <div className="audio-recorder__overlay-inner">
                        <h2 className="font-spatial-large">{t("GET_READY")}</h2>
                      </div>
                    </div>
                  )}
                  {recordStatus === "starting" && (
                    <div className="audio-recorder__overlay -align-center">
                      <div className="audio-recorder__overlay-inner">
                        <h2 className="font-spatial-large">
                          <Timer recording={this.recording} />
                        </h2>
                      </div>
                    </div>
                  )}
                  {recordStatus === "recording" && (
                    <div className="audio-recorder__overlay">
                      <div className="audio-recorder__waveform">
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__waveform-circle"></div>
                        <div className="audio-recorder__recorder -align-center">
                          <AudioRecorder
                            stop={this.stop}
                            reset={this.reset}
                            duration={MAX_RECORD_DURATION_SECONDS}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {recordStatus === "pending" && (
                    <div className="audio-recorder__overlay -align-center">
                      <div className="audio-recorder__overlay-inner">
                        <Spin indicator={antIcon} />
                        <div className="audio-recorder__pending-text">
                          <h2 className="multi-size">
                            <Heading keyName="RECORDING_WILL_BE_READY_SOON" />
                          </h2>
                          <p className="font-longform">
                            {t("RECORDING_PROCESSING_HINT")}
                          </p>
                        </div>
                        <p className="-no-margin">{t("SOMETHING_TO_PONDER")}</p>
                        <p className="font-longform">{this.onLoadingTips()}</p>
                      </div>
                    </div>
                  )}
                  {recordStatus === "completed" && audioURL && (
                    <div className="audio-recorder__overlay">
                      <div className="audio-recorder__overlay-inner">
                        <h2 className="multi-size -half-margin">
                          <TextFitter
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Heading keyName="REVIEW_YOUR_MESSAGE" />
                          </TextFitter>
                        </h2>
                        <div className="record-message">
                          <p className="record-message__description">
                            {t("REVIEW_AND_RE_RECORD")}
                          </p>
                          <div className="-align-center">
                            <div className="audio-recorder__player">
                              <div className="audio-player">
                                <AudioPlayer
                                  key={this.state.completedPlaybackKey}
                                  blobURL={audioURL}
                                  duration={duration}
                                  afterPlay={() =>
                                    this.setState((prev) => ({
                                      ...prev,
                                      completedPlaybackKey:
                                        prev.completedPlaybackKey + 1,
                                    }))
                                  }
                                />
                              </div>
                              <Link
                                to="#"
                                onClick={this.reRecord}
                                className="underlined underlined--light"
                              >
                                {t("RE_RECORD")}
                              </Link>
                            </div>
                            <Button
                              onClick={this.addImage}
                              type="primary"
                              shape="round"
                            >
                              {t("ADD_YOUR_PHOTO")}
                              <ArrowDown />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
          {slug === "text" && (
            <>
              <h1 className="multi-size">
                <Heading keyName="WRITE_YOUR_MESSAGE" />
              </h1>
              <div className="type-message">
                <p>{t("DONT_BR_MEAN")}</p>
                <Form
                  name="type_message"
                  onFinish={this.onSendMessage}
                  requiredMark={"optional"}
                  initialValues={{
                    message: this.props.writtenTextMesssage,
                  }}
                >
                  <Form.Item
                    label={t("YOUR_MESSAGE")}
                    name="message"
                    className="full-width"
                    rules={[
                      {
                        required: true,
                        message: t("VALIDATION_TEXT_MESSAGE"),
                      },
                    ]}
                  >
                    <TextArea
                      showCount
                      maxLength={250}
                      onChange={this.onChange}
                      placeholder={t("YOUR_MESSAGE")}
                      className="message-type-field"
                    />
                  </Form.Item>
                  {(Array.isArray(addTextResponse?.HasProfanity)
                    ? addTextResponse?.HasProfanity.length > 0
                    : addTextResponse?.HasProfanity) && (
                    <Form.Item className="full-width">
                      <span className="has-profanity">
                        {t("BOT_ERROR_PROFANITY")}
                      </span>
                    </Form.Item>
                  )}
                  <Form.Item shouldUpdate className="type-message__submit">
                    <Button type="primary" shape="round" htmlType="submit">
                      {t("ADD_YOUR_PHOTO")}
                      <ArrowDown />
                    </Button>
                  </Form.Item>
                  {this.state.canRecord && (
                    <Link
                      to="#"
                      className="underlined underlined--dark"
                      onClick={() => this.selectMsgType("audio")}
                    >
                      {t("OR_AUDIO_MESSAGE")}
                    </Link>
                  )}
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.addRecoredVoiceReducer.isLoading,
  addVoiceResponse: state.addRecoredVoiceReducer.addRecorededVoiceData,
  addVoiceError: state.addRecoredVoiceReducer.error, // undefined
  addTextResponse: state.addTextReducer.addTextData,
  slug: state.common.slug,
  bottleId: state.common.bottleId,
  messagesData: state.messagesReducer.messagesData,
  occasionData: state.common.occasionData,
  onlyWrittenMsg: state.common.onlyWrittenMsg,
  audioBlob: state.common.audioBlob,
  productKey: state.common.productKey,
  writtenTextMesssage: state.common.writtenTextMesssage,
  languageCode: state.translationReducer.translationData?.culture,
  transcribeLanguageCode:
    state.translationReducer.translationData?.transcribeCulture,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setSlug: (data) => dispatch(actions.setSlug(data)),
    fetchAddRecoredVoice: (data) => dispatch(fetchAddRecoredVoice(data)),
    fetchAddText: (data) => dispatch(fetchAddText(data)),
    setOnlyWrittenMsg: (data) => dispatch(actions.setOnlyWrittenMsg(data)),
    clearRecording: () => dispatch(clearRecording()),
    setCommonData: (data) => dispatch(setCommonData(data)),
    clearCommonData: (data) => dispatch(clearCommonData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Record));
