import React, { useEffect, useState } from "react";
import { Button } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as Logo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowLeft } from "Assets/img/arrow-left.svg";
import { Row, Col } from "antd";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import "./Guide.scss";
import "./Consumer.scss";

const GuidePage = () => {
  const history = useHistory();

  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const translation = offTradeTranslationData?.consumerOffTrade;
  const quickQuideTranslation = translation?.QuickGuidePage;
  const Steps = quickQuideTranslation?.Steps;

  return (
    <div className="guide page-wrap page-wrap--light">
      <div className="consumer-navigation">
        <Logo className="consumer-navigation__logo" />
        <Bottle className="consumer-navigation__bottle" />
        <div className="nav-menu">
          <Link to="/consumer/faq" className="help-link">
            {translation?.NeedHelp}
          </Link>
        </div>
      </div>
      <section className="ant-layout">
        <main className="ant-layout">
          <div className="container">
            <div className="consumer__title-wrapper">
              <h1>
                <span className="font-tight-normal font-tight-normal--alt">
                  {quickQuideTranslation?.MainHeading}
                </span>
                <span className="font-spatial-small">
                  {quickQuideTranslation?.SubHeading}
                </span>
              </h1>
              <button
                className="consumer__back-btn"
                onClick={() => history.goBack()}
              >
                <ArrowLeft />
                <span>{translation?.Back}</span>
              </button>
            </div>
            <Row justify="center" align="middle">
              <Col xs={24} md={20} lg={20}>
                <h2 className="consumer__subtitle font-tight-x-large font-tight-x-large--alt">
                  {quickQuideTranslation?.IntroText}
                </h2>
                {Steps.map((item) => {
                  return (
                    <div className="guide__block" key={item.Id}>
                      <div className="guide__number">
                        <span className="font-spatial-small">{item.Id}</span>
                      </div>
                      <img
                        className="guide__image"
                        alt={item.Name}
                        src={item.ImageUrl}
                      />
                      <p>{item.Info}</p>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </div>
        </main>
      </section>
    </div>
  );
};

export default GuidePage;
