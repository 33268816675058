import React, { Children, memo } from "react";
import { Route } from "react-router-dom";

import ManageRedirect from "./ManageRedirect";
import { MultiProducts } from "Pages/Author";

import RecipientHome from "Pages/Recipient/Home/Home";
import RecipientPlayMessage from "Pages/Recipient/Home/PlayMessage";
import RecipientEnd from "Pages/Recipient/End/End";
import RecipientEmailCopy from "Pages/Recipient/End/EmailCopy";
import RecipientSocialMedia from "Pages/Recipient/End/SocialMedia";
import RecipientInformation from "Pages/Recipient/End/Information";
import Welcome from "Pages/Author/Welcome/Welcome";

import Step from "Pages/Author/Steps/Step";
import SendConfirmation from "Pages/Author/Steps/SendConfirmation";

import Steps from "Pages/Author/Steps";
import Complete from "Pages/Author/Complete";
import { useOrientation } from "Hooks/useOrientation";
import { AnimatedContainer, AnimatedRouter } from "Components/framer";
import { PageNotFound } from "Components/Layout/PageNotFound";
import { useFooterLogo } from "Hooks/useFooterLogo";
import TestRenderer from "Pages/Test";
import StartPage from "../pages/Consumer/Start";
import BrandSelectPage from "../pages/Consumer/BrandSelect";

import FaqPage from "../pages/Consumer/faq";
import GuidePage from "../pages/Consumer/guide";
import ExpiryPage from "../pages/Consumer/Expiry";
import Scan from "../pages/Message/scan";
import NotFound404 from "../pages/NotFound404";
import WelcomePage from "Pages/Pair/Welcome";
import OrderPage from "Pages/Pair/Order";
import PairPage from "Pages/Pair/Pair";
import UnPairPage from "Pages/Pair/UnPair";
import FinishPage from "Pages/Pair/Finish";
import StartPair from "Pages/Pair/StartPage";

export const RenderRoutes = memo(({ routes }) => {
  useOrientation();
  useFooterLogo();
  return (
    <AnimatedRouter subscribeOnExitComplete>
      {Children.toArray(
        routes.map(({ RenderComponent, key, routes, ...props }, i) => {
          return (
            <Route path={props.path} exact={props.exact} key={key}>
              <AnimatedContainer>
                <RenderComponent {...props} routes={routes} />
              </AnimatedContainer>
            </Route>
          );
        })
      )}
      <Route>
        <PageNotFound />
      </Route>
    </AnimatedRouter>
  );
});

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */
const ROUTES = [
  { path: "/", key: "ROOT", exact: true, RenderComponent: ManageRedirect },
  { path: "/test", key: "TEST", exact: true, RenderComponent: TestRenderer },
  { path: "/message", key: "MESSAGE", exact: true, RenderComponent: Scan },
  {
    path: "/pair",
    key: "PAIR",
    RenderComponent: RenderRoutes,
    routes: [
      {
        path: "/pair",
        key: "WELCOME",
        exact: true,
        RenderComponent: StartPair,
      },
      {
        path: "/pair/welcome",
        key: "WELCOME",
        exact: true,
        RenderComponent: WelcomePage,
      },
      {
        path: "/pair/order",
        key: "ORDER",
        exact: true,
        RenderComponent: OrderPage,
      },
      {
        path: "/pair/pair",
        key: "PAIR",
        exact: true,
        RenderComponent: PairPage,
      },
      {
        path: "/pair/unpair",
        key: "UNPAIR",
        exact: true,
        RenderComponent: UnPairPage,
      },
      {
        path: "/pair/finish",
        key: "FINISH",
        exact: true,
        RenderComponent: FinishPage,
      },
    ],
  },
  {
    path: "/consumer",
    key: "CONSUMER",
    RenderComponent: RenderRoutes,
    routes: [
      {
        path: "/consumer",
        key: "START",
        exact: true,
        RenderComponent: StartPage,
      },
      {
        path: "/consumer/select-brand",
        key: "SELECT_BRAND",
        exact: true,
        RenderComponent: BrandSelectPage,
      },
      {
        path: "/consumer/faq",
        key: "FAQ",
        exact: true,
        RenderComponent: FaqPage,
      },
      {
        path: "/consumer/guide",
        key: "GUIDE",
        exact: true,
        RenderComponent: GuidePage,
      },
      {
        path: "/consumer/expiry",
        key: "EXPIRY",
        exact: true,
        RenderComponent: ExpiryPage,
      },
    ],
  },
  {
    path: "/author",
    key: "AUTHOR",
    RenderComponent: RenderRoutes,
    routes: [
      {
        path: "/author/welcome",
        key: "WELCOME",
        exact: true,
        RenderComponent: Welcome,
      },
      {
        path: "/author/main-steps",
        key: "MAIN-STEPS",
        exact: true,
        RenderComponent: Step,
      },
      {
        path: "/author/steps",
        key: "AUTHOR_STEPS",
        RenderComponent: Steps,
      },
      {
        path: "/author/send-confirmation",
        key: "AUTHOR_STEPS_SEND_CONFIRMATION",
        exact: true,
        RenderComponent: SendConfirmation,
      },
      {
        path: "/author/multi-products",
        key: "AUTHOR_MULTI_PRODUCTIONS",
        exact: true,
        RenderComponent: MultiProducts,
      },
      {
        path: "/author/complete",
        key: "AUTHOR__COMPLETE",
        exact: true,
        RenderComponent: Complete,
      },
      {
        path: "/author/order/not-found",
        key: "ORDER_NOT_FOUND",
        exact: true,
        RenderComponent: Complete,
      },
    ],
  },
  {
    path: "/recipient",
    key: "RECIPIENT",
    RenderComponent: RenderRoutes,
    routes: [
      {
        path: "/recipient/home",
        key: "RECIPIENT_HOME",
        exact: true,
        RenderComponent: RecipientHome,
      },
      {
        path: "/recipient/play-message",
        key: "RECIPIENT_PLAY_MESSAGE",
        exact: true,
        RenderComponent: RecipientPlayMessage,
      },
      {
        path: "/recipient/end",
        key: "RECIPIENT_END",
        exact: true,
        RenderComponent: RecipientEnd,
      },
      {
        path: "/recipient/email-copy",
        key: "RECIPIENT_EMAIL_COPY",
        exact: true,
        RenderComponent: RecipientEmailCopy,
      },
      {
        path: "/recipient/social-media",
        key: "RECIPIENT_SOCIAL_MEDIA",
        exact: true,
        RenderComponent: RecipientSocialMedia,
      },
      {
        path: "/recipient/information",
        key: "RECIPIENT_INFORMATION",
        exact: true,
        RenderComponent: RecipientInformation,
      },
    ],
  },
  {
    path: "/*",
    RenderComponent: NotFound404,
  },
];
export default ROUTES;
