import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useQueryString } from "Hooks/useQueryString";

import { loadMessages } from "Services/messagesService";
import { getToken } from "Services/tokenService";
import { tokenReducerSelector } from "Store/reducers/token";
import { AuthToken, OrderToken, QrCode } from "Utils/web-storage";

export default function ManageRedirect() {
  const { isOrder, isQrCode, codeOrToken } = useQueryString();
  const dispatch = useDispatch();
  const authToken = useSelector(tokenReducerSelector);
  
  useEffect(() => {
    if (!authToken) {
      dispatch(getToken(codeOrToken, isOrder))
    }
  })
  if (isOrder && authToken) {
    OrderToken(codeOrToken)
    dispatch(loadMessages(codeOrToken));
  }
  if (isQrCode && authToken) {
    QrCode(codeOrToken)
  }
  if (authToken) {
    AuthToken(authToken)
    return (
      <Child isOrder={isOrder} isQrCode={isQrCode} />
    );
  }
  return null;
}

function Child({ isOrder, isQrCode }) {
  return (
    <div>
      {isQrCode && <Redirect to="/recipient/home" />}
      {isOrder && <Redirect to="/author/welcome" />}
    </div>
  );
}
