export const INITIAL_STATE = {
  fetching: false,
  data: null,
  errors: null,
};

export const GetApiDipatchType = (base) => ({
  FETCHING: `${base}_FETCHING`,
  SUCCESS: `${base}_SUCCESS`,
  FAILURE: `${base}_FAILURE`,
});
