import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"

export class AnimationMaskSingleton_4 extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('singleton_5.png'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(75.0, 66.8, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		// set plane initial state
		plane1.position.set(0, -250, -80);

		// create plane  2
		const geometry2 = new THREE.PlaneGeometry(200, 300, 2);
		const material2 = new THREE.MeshBasicMaterial({ color: this.colors[1], side: THREE.DoubleSide, transparent: true });
		const plane2 = new THREE.Mesh(geometry2, material2);
		this.parent.add(plane2);

		// set plane initial state
		plane2.position.set(0, -300, -200);
		plane2.scale.set(1, 0, 1);

		for (var i = 0; i < 4; i++) {
			const word = new TextLine(this.keyword, 2, 2, 400, this.colors[0], undefined, 'Authenia W05', 0);
			this.parent.add(word);

			var x = -18;
			if (i % 2 === 0) {
				x = 18;
			}
			word.position.set(x, 8 - i * 1.7, -6);
			//word.material.map.offset.y = 1;
			gsap.to(word.position, { delay: 0.05 * i, x: 0, ease: "expo.inOut", duration: this.duration })
			gsap.to(word.position, { delay: this.duration + 0.05 * i, y: 15, ease: "expo.inOut", duration: this.duration })
		}


		self.plane1 = plane1;
		// animate
		gsap.to(texture.offset, { delay: 0, x: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, { delay: 0, y: -35, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane2.position, { delay: 0, y: 50, ease: "expo.inOut", duration: this.duration })

		gsap.to(plane2.scale, { y: 1, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane2.position, { delay: this.duration, y: 340, ease: "expo.inOut", duration: this.duration })

		gsap.to(texture.offset, { delay: 0, delay: this.duration, x: 0, y: 1, ease: "expo.inOut", duration: this.duration });
		gsap.to(plane1.position, {
			y: 250, delay: this.duration, ease: "expo.inOut", duration: this.duration, onComplete: function () {
				//self.isAnimating = false;
				self.destroy();
			}
		});
	}

	destroy() {
		this.parent.remove(this.plane1);
	}

}

export default AnimationMaskSingleton_4; // Don’t forget to use export default!