export function isLocal() {
  return window.location.host.indexOf("localhost:") > -1;
}

export function apiUrl() {
  // return 'https://miab.thisisdare.team/api';
  //return 'http://ec2-54-91-213-94.compute-1.amazonaws.com/';
  if (isLocal()) {
    return process.env.FRONTEND_APP_API_LOCAL_URL;
  } else {
    return process.env.FRONTEND_APP_API_PROD_URL;
  }
}

export const getImageUrl = (src = "", defaultImg) => {
  if (src) {
    return `${apiUrl().replace("/api/", "")}${src}`;
  }

  if (typeof defaultImg !== "undefined") {
    return defaultImg;
  }
  return "https://via.placeholder.com/300";
};

export const sanitiseValue = (value) => {
  if (typeof value !== "undefined") {
    return value;
  }

  return {};
};
