import { Button } from 'antd';
import { ReactComponent as MiabLogo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowRight } from 'Assets/img/arrow-right.svg';
import { useTranslation } from 'Contexts/translation-context';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFallbackTranslation } from 'Services/translationService';

export default function NotFound404() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  useEffect(() => {
    dispatch(getFallbackTranslation())
  }, [dispatch])
  
  const onBack = () => {
    window.location.assign(t("STORE_URL") || `https://www.malts.com/`);
  }

  return (
    <div className="page-wrap page-wrap--light sway-wrap">
      <section className="ant-layout ant-layout-has-sider">
        <aside className="side-bar side-bar--light side-bar--welcome">
          <Bottle className="bottle-image" />
        </aside>
        <main className="ant-layout">
          <div className="container">
            <MiabLogo className="miab-logo" />
            <p>{t('SEND_ERROR')}</p>
            <div className="sway-button">
              <Button
                type="primary"
                shape="round"
                onClick={onBack}
              >
                {t("BACK_TO_WEBSITE")}
                <ArrowRight />
              </Button>
            </div>
          </div>
        </main>
      </section>
    </div>
  );
}
