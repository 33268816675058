import { shallowEqual, useSelector } from "react-redux";

const isDev = process.env.NODE_ENV !== "production";

export const GetDevAssetPath = (asset) => {
  if (!asset) return asset;
  const [, , , assetName] = asset.split("/");

  return `/creative/${assetName}`;
};

export const GetAssetPath = (asset) => {
  if (!isDev) return `/brand/${asset}`;
  return GetDevAssetPath(asset);
};

// For Diageo-Creative generic animation packs only
export const GetGenericAnimationAssetPath = (asset) => {
  const userData = useSelector(
    (state) => state.messageUserReducer.data,
    shallowEqual
  );

  console.log("GetGenericAnimationAssetPath.....");
  console.log("userData: ", userData.creative);

  const { brand } = userData.creative; // Why is this undefined

  const [, , mediaDir, assetName] = asset.split("/");
  return `/creative/${brand}/${mediaDir}/${assetName}`;
};
