import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { MESSAGES } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  messagesData: [],
  messagesState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: messages,
  success: messagesSuccess,
  fail: messagesFail,
} = defineLoopActions(MESSAGES);

export const fetchMessages = orderToken => {
  const apiUrl = `/authoring/messages?orderToken=` + orderToken;

  return apiAction({
    url: apiUrl,
    onStart: messages,
    onSuccess: messagesSuccess,
    onFailure: messagesFail,
    label: MESSAGES,
  });
};

export const messagesReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: MESSAGES,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          messagesState: REQUEST_STATUS.SUCCESS,
          messagesData: payload.message_payload,
        };
      },
      stateField: "messagesState",
    }),
  },
  initialState
);


export const singleMessagesSelector = (bottleId = null) => state => {
  const messagesData = state.messagesReducer.messagesData;
  const defaultReturn = { length: messagesData.length };
  if (messagesData.length === 0) {
    return defaultReturn
  }
  if (messagesData.length === 1) {
    return {
      ...defaultReturn,
      ...messagesData[0]
    };
  }
  if (!bottleId) {
    return defaultReturn;
  }
  return {
    ...defaultReturn,
    ...messagesData.find(data => data.id === bottleId)
  };
}