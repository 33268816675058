import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Zero from "../common/Zero.js"
import TextLine from "../common/textLine.js"


export class AnimationZerosSingleton_3 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
      
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    var zeroContainer = new THREE.Group();
    this.parent.add(zeroContainer);
    zeroContainer.position.set(-7.5, -13, -13);

    for (var j = 0; j < 6; j++) {


        for (var i = 0; i < j+1; i++) {
          const positionVector3 = new THREE.Vector3(i*3, j*5.5, 0);
          var zero = new Zero(zeroContainer, this.colors[0], this.duration, positionVector3);
          zero.material.opacity = 0;
          zero.rotation.set(0,Math.PI/2,0);
          gsap.to(zero.rotation,{y:0, delay:0.5+0.1*i, ease: "expo.inOut", duration:this.duration/2});
          gsap.to(zero.material,{opacity:1, delay:0.5+0.1*i, ease: "expo.inOut", duration:this.duration/2});

          gsap.to(zero.rotation,{y:Math.PI/2, delay:this.duration, ease: "expo.inOut", duration:this.duration/2});
          gsap.to(zero.material,{opacity:0, delay:this.duration, ease: "expo.inOut", duration:this.duration/2});

        }
        
    }

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(zeroContainer);
    }});

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationZerosSingleton_3; // Don’t forget to use export default!