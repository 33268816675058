import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"


export class AnimationMask_3 extends AnimationTemplate {

	constructor(parent, colors, duration, keyword) {
		super(parent, colors, duration, keyword);
	}

	start() {

		var self = this;
		// load and set texture initial state
		const texture = new THREE.TextureLoader().load(this.getAssetPath('black_label_4.jpg'));
		texture.offset.y = -1;
		texture.wrapS = THREE.RepeatWrapping;
		texture.wrapT = THREE.RepeatWrapping;
		texture.repeat.set(1, 1);

		// create plane 
		const geometry1 = new THREE.PlaneGeometry(75.0, 133.4, 2);
		const material1 = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
		const plane1 = new THREE.Mesh(geometry1, material1);
		this.parent.add(plane1);

		// set plane initial state
		plane1.position.set(0, -250, -80);

		// create plane  2
		const geometry2 = new THREE.PlaneGeometry(200, 200, 2);
		const material2 = new THREE.MeshBasicMaterial({ color: this.colors[1], side: THREE.DoubleSide, transparent: true });
		const plane2 = new THREE.Mesh(geometry2, material2);
		this.parent.add(plane2);

		// set plane initial state
		plane2.position.set(0, -300, -200);
		plane2.scale.set(1, 0, 1);

		self.plane1 = plane1;
		// animate
		gsap.to(texture.offset, { delay: 0, x: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane1.position, { delay: 0, y: 0, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane2.position, { delay: 0, y: -50, ease: "expo.inOut", duration: this.duration })

		gsap.to(plane2.scale, { y: 1, ease: "expo.inOut", duration: this.duration })
		gsap.to(plane2.position, { delay: this.duration, y: 300, ease: "expo.inOut", duration: this.duration })

		gsap.to(texture.offset, { delay: 0, delay: this.duration, x: 0, y: 1, ease: "expo.inOut", duration: this.duration });
		gsap.to(plane1.position, {
			y: 250, delay: this.duration, ease: "expo.inOut", duration: this.duration, onComplete: function () {

				self.parent.remove(plane1);
				self.parent.remove(plane2);

			}
		});
	}

	destroy() {

	}

}

export default AnimationMask_3; // Don’t forget to use export default!