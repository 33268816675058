import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "Assets/img/miab-logo.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle.svg";
import { ReactComponent as ArrowRight } from "Assets/img/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "Assets/img/arrow-left.svg";
import { Row, Col, Button } from "antd";

import "./Faq.scss";
import "./Consumer.scss";

import { useSelector } from "react-redux";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

const FaqPage = () => {
  const history = useHistory();

  const offTradeTranslationData = useSelector(
    (state) => state.offTradeReducer.offTradeTranslationData
  );
  const translation = offTradeTranslationData?.consumerOffTrade;
  const helpPageTranslation = translation?.HelpPage;

  const faqItems = helpPageTranslation?.Faqs;
  console.log("FAQs:", faqItems);

  return (
    <div className="faq page-wrap page-wrap--light">
      <div className="consumer-navigation">
        <Logo className="consumer-navigation__logo" />
        <Bottle className="consumer-navigation__bottle" />
        <div className="nav-menu">
          <Link to="/consumer/faq" className="help-link">
            {translation?.NeedHelp}
          </Link>
        </div>
      </div>
      <section className="ant-layout">
        <main className="ant-layout">
          <div className="container">
            <div className="consumer__title-wrapper">
              <h1>
                <span className="font-tight-normal font-tight-normal--alt">
                  {helpPageTranslation?.MainHeading}
                </span>
                <span className="font-spatial-small">
                  {helpPageTranslation?.SubHeading}
                </span>
              </h1>
              <button
                className="consumer__back-btn"
                onClick={() => history.goBack()}
              >
                <ArrowLeft />
                <span>{translation?.Back}</span>
              </button>
            </div>
            <Row justify="center" align="middle">
              <Col xs={24} md={20} lg={20}>
                <div className="faq__guide">
                  <h2 className="faq__guide-heading font-spatial-x-small">
                    {helpPageTranslation?.HeroTitle}
                  </h2>
                  <p className="faq__guide-intro">
                    {helpPageTranslation?.HeroDescription}
                  </p>
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => history.push("/consumer/guide")}
                  >
                    {helpPageTranslation?.QuickGuideBtn}
                    <ArrowRight />
                  </Button>
                </div>
                <h2 className="consumer__subtitle font-tight-x-large font-tight-x-large--alt">
                  {helpPageTranslation?.Faqtitle}
                </h2>
                <div className="faq__block">
                  {faqItems.map((item, index) => {
                    return (
                      <div className="faq__block-item" key={index}>
                        <p className="faq__block-item-q font-longform-large">
                          {item.Question}
                        </p>
                        <p>{item.Answer}</p>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </div>
        </main>
      </section>
    </div>
  );
};

export default FaqPage;
