import * as THREE from "three";
import { GetAssetPath } from "Services/apiService";


export class Stamp {

  constructor(parent, color, duration, positionVector3) {

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(GetAssetPath('talisker-stamp.png'));

    // create plane 
    const geometry = new THREE.PlaneGeometry(2.14, 2.12, 2);
    const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y, positionVector3.z);
    return plane;
  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default Stamp; // Don’t forget to use export default!