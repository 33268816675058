import { handleActions } from "redux-actions";
import { REQUEST_STATUS } from "Axios/constants";
import { defineLoopActions, requestLoopHandlers } from "Axios/state";
import { apiAction } from "Axios/apiCall";
import { FINALIZE, CLEAR_FINALIZE } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  finalizeData: null,
  finalizeState: REQUEST_STATUS.INITIAL,
  state: REQUEST_STATUS.INITIAL,
  error: {},
};

export const {
  start: finalize,
  success: finalizeSuccess,
  fail: finalizeFail,
} = defineLoopActions(FINALIZE);

export const fetchFinalize = (data) => {
  return apiAction({
    url: '/authoring/finalise',
    method: 'POST',
    onStart: finalize,
    onSuccess: finalizeSuccess,
    onFailure: finalizeFail,
    label: FINALIZE,
    data,
  });
};

export const finalizeReducer = handleActions(
  {
    ...requestLoopHandlers({
      action: FINALIZE,
      onSuccess: (state, payload) => {
        return {
          ...state,
          isLoading: true,
          finalizeState: REQUEST_STATUS.SUCCESS,
          finalizeData: payload,
        };
      },
      onFailure: (state) => ({
        ...state,
        finalizeState: REQUEST_STATUS.FAIL,
        finalizeData: null,
        error: true,
      }),
      stateField: "finalizeState",
    }),
    [CLEAR_FINALIZE]: (state) => ({
      ...state,
      finalizeData: null,
      error: null,
    }),
  },
  initialState
);



export const clearFinalize = () => {
  return {
    type: CLEAR_FINALIZE,
  }
}

export const finalizeReducerSelector = (prop = null) => (state) => prop ? state.finalizeReducer[prop] : state.finalizeReducer;