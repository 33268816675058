import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonMap from "../common/SingletonMap.js"
import TextLine from "../common/textLine.js"


export class AnimationMapSingleton_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
    
    const self = this;
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const positionVector3 = new THREE.Vector3(0, 7, -2);

    const map = new SingletonMap(this.parent, this.colors[0], this.duration, positionVector3);
    map.rotation.set(0,0,Math.PI/4);
    map.material.opacity = 0;
    gsap.to(map.rotation,{delay:0, z:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(map.material,{delay:0, opacity:1, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(map.rotation,{delay:this.duration, z:-Math.PI/4, ease: "expo.inOut", duration:this.duration});
    gsap.to(map.material,{delay:this.duration, opacity:0, ease: "expo.inOut", duration:this.duration});

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var i = 0; i < 2; i++) {
    
      const word = new TextLine(this.keyword, 1, 1, 400, this.colors[0], undefined, "Authenia W05");
      wordContainer.add(word);
      word.position.set(-2+i*3,-2+i*1,4);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration});

    }

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
      self.parent.remove(wordContainer);
      self.parent.remove(map);
    }})

  }
   
}

export default AnimationMapSingleton_4; // Don’t forget to use export default!