import { Tile } from "./tile";
import { Photography } from "./photography";
import { VerticalHalf } from "./vertical-half";
import { Reel } from "./reel";
import { POSITION } from '../configuration';

export { TEXTURE, POSITION } from '../configuration';
export const withExtendedBaseCreative = (defaultProps, BaseCreative) => class Creativity extends BaseCreative {
  constructor(props) {
    super({ ...defaultProps, ...props });
  }
}

export const withPhotography = () => class Creativity extends Photography {
  constructor({ texture, ...props }) {
    let textureProps = {};

    switch (texture.imagePosition) {
      case POSITION.CENTERED:
        textureProps = {
          imagePosition: POSITION.CENTER,
          imageHeight: 1330*0.075,
          imageWidth: 750*0.075,
        };
        break;
        case POSITION.TOPHALF: 
        case POSITION.BOTTOMHALF:
          textureProps = {
            imageWidth: 750*0.3, imageHeight: 668*0.3
          };
          break;
      default: break;
    }

    super({
      ...props,
      texture: {
        ...texture,
        ...textureProps
      },
      textProps: {
        lines: 3,
      },
    });
  }
}

export const withExtendedPhotography = (defaultProps) => class Creativity extends Photography {
  constructor({ texture, ...props }) {
    const {
      isVertical = false,
      imagePosition = false,
      isStretched = false,
      lines = 4,
      imageWidth,
      imageHeight,
      scaleFactor = null,
    } = defaultProps || {};
    super({
      ...props,
      scaleFactor,
      textProps: {
        isVertical,
        isStretched,
        lines,
      },
      sameBackground: true,
      texture: {
        ...texture,
        imagePosition: imagePosition || texture.imagePosition,
        imageWidth,
        imageHeight,
      }
    });
  }
};

export const withExtendedVerticalHalf = (defaultProps) => class Creativity extends VerticalHalf {
  constructor(props) {
    super({ ...props, ...defaultProps });
  }
};

export const withExtendedTile = (defaultProps) => class Creativity extends Tile {
  constructor(props) {
    const isOccasion = defaultProps && defaultProps.isOccasion;
    const x = Array.from(Array(isOccasion ? 10 : 5));
    const pos = 4.5;

    super({
      ...props,
      ...defaultProps,
      textureConfig: x.map((_, i) => ({
        columns: 5 - (isOccasion ? 0 : i),
        y: pos - i
      })),
    });
  }
};

export const withExtendedReel = (defaultProps) => class PlainReel extends Reel {
  constructor({ texture, ...props }) {
    const {
      imageWidth,
      imageHeight,
      isPlain = false,
    } = defaultProps || {};

    super({
      ...props,
      texture: {
        ...texture,
        imageWidth,
        imageHeight,
      },
      isPlain
    });
  }
}


