import auth from "authur";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
//import { Pair } from "../store/order/action";
import { Pair } from "../../store/actions/pair";
import getAuthToken from "../../utils/pair/get-auth";
import LoadingScreen from "../../components/loading/loading";
import getUserSession from "../../utils/pair/user-session";


import WelcomePage from "./Welcome";
import { ReactComponent as Logo } from "../../assets/img/logo-wordmark-white.svg";
import "./pair.css";

const StartPair = () => {
  ResponseInterceptor();

  const history = useHistory();
  const [loading, setLoading] = useState(false);

  //console.log("START")
  //const u_session = getUserSession();
  //console.log("START 2", u_session)

  //if(u_session){
    //history.replace("/pair/welcome")
  //}

  const [loginForm, setloginForm] = useState({
    username: "",
    password: "",
    error: null,
  });
  const [userIsAuthenticated, setUserIsAuthenticated] = useState(false);
  

  useEffect(() => {
    //console.log("calling: ", window.location.host.indexOf("localhost:") > -1 ? process.env.FRONTEND_APP_API_AUTH_LOCAL_URL : process.env.FRONTEND_APP_API_AUTH_PROD_URL)
    auth.initialize({
      origin: window.location.host.indexOf("localhost:") > -1 ? process.env.FRONTEND_APP_API_AUTH_LOCAL_URL : process.env.FRONTEND_APP_API_AUTH_PROD_URL,//"http://localhost:50853",
      authPath: "/oauth/token",
      apiPath: "/umbraco/api",
      persistenceGet: (key) => localStorage.getItem(key),
      persistenceSet: (key, val) => localStorage.setItem(key, val),
      persistenceClear: (storageKey) => localStorage.removeItem(storageKey),
      debug: true,
      events: {
        onAuthStateChange: (status) => setUserIsAuthenticated(status),
      },
    });
  }, []);

  function ResponseInterceptor() {
    //console.log("ResponseInterceptor")
    const interceptorId = useRef(null);
    useEffect(() => {
      console.log("ResponseInterceptor useEffect")

      interceptorId.current = axios.interceptors.response.use(
        undefined,
        (error) => {
          console.log("error", error)
          if (error.response.data === 404) {
            history.push("/404");
          }
          if (error.response.data === 401) {
            history.push("/login");
          }
        }
      );

      return () => {
        console.log("interceptorId.current", interceptorId.current)
        axios.interceptors.response.eject(interceptorId.current);
      };
    }, []);

    return null;
  }

  async function login() {
    //console.log("login")
    if (!loginForm.username || !loginForm.password) return;
    setLoading(true);

    const attempt = async (loginForm) => {
      try {
        const response = await auth.authenticate(loginForm);
        console.log("response",response);
        if (!response.ok) {
          console.log("attempt", response.error)
          setloginForm((state) => ({
            error: response.error,
            username: state.username,
            password: "",
          }));
        }else{
          const params = new URL(location.href).searchParams;
          const retUrl = params.get('retUrl');
          if(retUrl !== ""){
            //console.log("retUrl", retUrl)
            history.push(retUrl)
          }
        }
        setLoading(false);
      }
      catch (e) {
        var genError = "Server timeout, no response"
        setloginForm((state) => ({
          error: e.message === "Failed to fetch" ? genError : e.message,
          username: state.username,
          password: "",
        }));
        setLoading(false);
        console.log('We have the error', e);
        console.log('loginForm', loginForm);
      }
    }
    attempt(loginForm);
    //const attempt = await auth.authenticate(loginForm);
    
    console.log("auth", auth.origin)
    if (!attempt.ok) {
      console.log("attempt", attempt.error)
      setloginForm((state) => ({
        error: attempt.error,
        username: state.username,
        password: "",
      }));
      setLoading(false);
    } else {
    }
    
  }

  function handleInputChange(event) {
    const { value, name } = event.target;
    setloginForm({ ...loginForm, [name]: value });
  }

  return (
    <>
      {!userIsAuthenticated ? (
        <section className="center-content section-pair">
          <div className="container sm:w-96 mx-auto px-7 py-7 bg-black">
            <Logo className="logo" />
            <div className="pt-10">
              <div className="bg-white px-6 py-8 shadow rounded-lg">
                <p className="text-lg text-black">
                  Please login to your account using the form below
                </p>
                <div className="login-form">
                  <div className="input-area mt-8">
                    <label className="block text-sm font-bold text-black">
                      Username
                    </label>
                    <div className="mt-2">
                      <input
                        required
                        name="username"
                        value={loginForm.username}
                        onChange={handleInputChange}
                        type="text"
                        className="w-full border-gray-400 rounded-md shadow-md"
                      />
                    </div>
                  </div>
                  <div className="mt-8">
                    <label className="block text-sm font-bold text-black">
                      Password
                    </label>
                    <div className="input-area mt-2">
                      <input
                        required
                        onChange={handleInputChange}
                        value={loginForm.password}
                        type="password"
                        name="password"
                        className="w-full border-gray-400 rounded-md shadow-md"
                      />
                    </div>
                    <div className="flex items-center mt-8 flex-box">
                      <button
                        onClick={login}
                        disabled={loading}
                        type="submit"
                        className="btn-bg rounded-md px-6 py-3 font-bold text-sm"
                      >
                        Sign in
                      </button>
                      <p className="error-msg">{loginForm.error}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
      ) : (
        <WelcomePage />
      )}
      {(loading) && (
            <div className="loader-wrap">
              <LoadingScreen />
            </div>
          )}
    </>
  );
};

export default StartPair;
