import { combineReducers } from "redux";
import { addRecoredVoiceReducer } from "./record";
import { addProfileImageReducer } from "./image";
import { messagesReducer } from "./messages";
import { messageReducer } from "./message";
import { messageUserReducer } from "./message-user";
import { messageExperienceReducer } from "./message-experience";
import { consumerReducer, consumerUpdateReducer } from "./consumer";
import { orderReducer } from "./order";
import common from "./common";
import { addTextReducer } from "./record-text";
import { tokenReducer } from "./token";
import { pollingReducer } from "./polling";
import { finalizeReducer } from "./finalize";
import { translationReducer } from "./translation";
import { brandsReducer } from "./brands";
import { offTradeReducer } from "./offtrade";
import { setOrder } from "./pair";

export default combineReducers({
  addRecoredVoiceReducer,
  addProfileImageReducer,
  orderReducer,
  messagesReducer,
  messageReducer,
  consumerReducer,
  common,
  messageUserReducer,
  messageExperienceReducer,
  addTextReducer,
  tokenReducer,
  pollingReducer,
  finalizeReducer,
  consumerUpdateReducer,
  translationReducer,
  brandsReducer,
  offTradeReducer,
  order: setOrder,
});
