import { GetTranscriptionJobCommand } from "@aws-sdk/client-transcribe";
import { transcribeClient } from "../libs/transcribeClient";
import delay from "delay";

let status = "IN_PROGRESS";

//expects fn() to throw if it failed
//if it runs out of retries, poll() will resolve to an rejected promise, containing the latest error
function poll(fn, retries = 10, timeoutBetweenAttempts = 2000) {
  return Promise.resolve()
    .then(fn)
    .catch(function retry(err) {
      if (retries-- > 0)
        return delay(timeoutBetweenAttempts).then(fn).catch(retry);
      throw err;
    });
}

const getTranscribeJob = async (jobName) => {
  try {
    console.log("JOB NAME:", jobName);
    if (!jobName) {
      return {
        NoTranscription: true,
        TranscriptionJobStatus: 'COMPLETED'
      }
    }

    const params = { TranscriptionJobName: `${jobName}` };
    const data = await transcribeClient.send(
      new GetTranscriptionJobCommand(params)
    );

    const status = data.TranscriptionJob.TranscriptionJobStatus;
    console.log("the AWS Status is: ", status);
    if (status === "COMPLETED") {
      console.info("the status is now complete... STOP!!!!!!!!!!!");
      return data.TranscriptionJob;
    }
  } catch (err) {
    console.warn("error!!", err);
    throw err;
  }
};

const getTranscribeJobStatus = (jobName) => {
  function getTranscribeJobInfo(res) {
    const transcript = res.Transcript;
    console.log("", transcript?.TranscriptFileUri);

    return transcript?.TranscriptFileUri;
  }
  return poll(
    async () => await getTranscribeJob(jobName).then(getTranscribeJobInfo),
    25, // Number of tries
    5000 // Time between tries
  );
};

export default getTranscribeJobStatus;
