import { useLocation } from "react-router-dom";

export const useQueryString = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  
  const orderToken = query.get("orderToken");
  const qrCode = query.get("qrCode");

  const isOrder = orderToken !== null;
  const isQrCode = qrCode !== null;

  return { 
    isOrder, 
    isQrCode, 
    codeOrToken:  orderToken || qrCode 
  };
}