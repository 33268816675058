import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Zero from "../common/Zero.js"
import TextLine from "../common/textLine.js"


export class AnimationZerosSingleton extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

    const self = this;
    
    var zeroContainer = new THREE.Group();
    this.parent.add(zeroContainer);
    zeroContainer.position.set(-7.5, -13, -13);

    for (var j = 0; j < 6; j++) {

      const word = new TextLine(this.keyword, 6, 6, 400, this.colors[2], undefined, 'Mackay Regular');
      zeroContainer.add(word);
      word.position.set(17+i*3,j*5.5-6.4,0);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0.05*j, y:0, ease: "expo.inOut", duration:this.duration/2})
      gsap.to(word.material.map.offset,{delay:this.duration, y:-1, ease: "expo.inOut", duration:this.duration/2})

        for (var i = 0; i < j+1; i++) {
          const positionVector3 = new THREE.Vector3(i*3, j*5.5, 0);
          var zero = new Zero(zeroContainer, this.colors[1], this.duration, positionVector3);
          zero.material.opacity = 0;
          zero.rotation.set(0,Math.PI/2,0);
          gsap.to(zero.rotation,{y:0, delay:0.1*i, ease: "expo.inOut", duration:this.duration/2});
          gsap.to(zero.material,{opacity:1, delay:0.1*i, ease: "expo.inOut", duration:this.duration/2});

          gsap.to(zero.rotation,{y:Math.PI/2, delay:this.duration, ease: "expo.inOut", duration:this.duration/2});
          gsap.to(zero.material,{opacity:0, delay:this.duration, ease: "expo.inOut", duration:this.duration/2});

        }
        
    }

    gsap.to(zeroContainer.position,{y:0, delay:this.duration, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(zeroContainer);
    }});

  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationZerosSingleton; // Don’t forget to use export default!