import React from "react";
import { useHistory, useLocation  } from "react-router";
import { createModuleResolutionCache } from "typescript";
import Footer from "../../components/pair/footer";
import Header from "../../components/pair/header";
import getUserSession from "../../utils/pair/user-session";
import "./pair.css";

const WelcomePage = () => {
  const history = useHistory();
  const u_session = getUserSession();

  
  return (
  <section className="center-content section-pair">
    <div className="container sm:w-96 mx-auto">
      <Header />
      <div className=" bg-white">
        <div className="px-6 py-8">
          <p className="text-3xl text-black">
            Welcome to Diageo Message in a Bottle QR code pairing assisstant.
          </p>
          <p className="text-lg text-black mt-10">
            To pair or unpair a QR code simple scan an eligible product QR code
            with your phone camera, and follow the intructions on screen.
          </p>
          <Footer />
        </div>
      </div>
    </div>
  </section>
  );
};

export default WelcomePage;
