import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import JWStripeHorizontal from "../common/JWStripeHorizontal.js"
import TextLine from "../common/textLine.js"

export class AnimationStripe_4 extends AnimationTemplate{
  
  constructor(parent, colors, duration, keyword) {
    super(parent, colors, duration, keyword);
  }

  start(){

    var self = this;
       
    // create plane 
    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: this.colors[2], side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);
      
    for (var i = 0; i < 4; i++) {
      const word = new TextLine(this.keyword.toUpperCase(), 1.6, 1.6, 400, this.colors[0], undefined, "JohnnieWalkerWeb-Headline");
      wordContainer.add(word);
      word.position.set(0,2-i*1.2,0);
      word.material.map.offset.y = 1;
      gsap.to(word.material.map.offset,{delay:0.05*i, y:0, ease: "expo.inOut", duration:this.duration})
      gsap.to(word.material.map.offset,{delay:this.duration+0.05*i, y:-1, ease: "expo.inOut", duration:this.duration})
    }

    var positionVector3 = new THREE.Vector3(0, -17, 0 );
    new JWStripeHorizontal(this.parent, this.colors[0], this.duration, positionVector3); 

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

    gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(wordContainer);
      self.parent.remove(plane1);
    }});

  }

  destroy(){
    //this.parent.remove(this.plane1);
  }
   
}

export default AnimationStripe_4; // Don’t forget to use export default!