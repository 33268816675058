import { withRouter, useHistory } from "react-router-dom";
import { Row, Col, Button } from "antd";
import { Footer } from "Components/Layout";
import "./RecipientEnd.scss";
import "./../RecipientFrame.scss";
import { ReactComponent as Restart } from "Assets/img/restart-white.svg";
import { ReactComponent as Share } from "Assets/img/share.svg";
import { ReactComponent as Bottle } from "Assets/img/miab-bottle-alt.svg";
import { ReactComponent as PlusCircle } from "Assets/img/plus-circle.svg";
import { ReactComponent as CloseIcon } from "Assets/img/circle-close.svg";
import { ReactComponent as Copy } from "Assets/img/copy.svg";
import { useMessage } from "Hooks/recipient/useMessage";
import { useState } from "react";
import { useTranslation } from "Contexts/translation-context";

export const End = () => {
  const { t } = useTranslation();
  const COPY_LINK_TEXT = t("COPY_LINK");
  const SHARE_TO_SOCIAL_MEDIA = t("SHARE_TO_SOCIAL_MEDIA"); // todo: this condition will need to be from a settings node or similar.
  const { occasionImage, productImage, profileImage, messageToken } =
    useMessage();
  const history = useHistory();
  const [copyLink, setCopyLink] = useState(COPY_LINK_TEXT);
  const handleReplay = () => {
    history.push("/recipient/play-message");
  };

  const handleSocialMediaClick = () => {
    history.push("/recipient/social-media");
  };
  const handleEmailClick = () => {
    history.push("/recipient/email-copy");
  };
  const copyToClipBoard = () => {
    const el = document.createElement("textarea");
    el.value = `${window.location.origin}/message?utm_source=cp&qrCode=${messageToken}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopyLink("Copied");

    setTimeout(() => {
      setCopyLink(COPY_LINK_TEXT);
    }, 3000);
  };

  const handleInformationClick = () => {
    history.push("/recipient/information");
  };

  return (
    <div className="page-wrap">
      <section className="recipient-end">
        <div className="container">
          <div className="recipient-frame">
            <div className="recipient-frame__inner">
              <div className="recipient-end__header">
                <Bottle className="recipient-end__bottle-image" />
                <CloseIcon className="recipient-end__close-icon recipient-end__close-icon--hidden" />
              </div>

              <main className="">
                <Row justify="center">
                  <Col>
                    {profileImage && (
                      <img
                        className="recipient-end__image"
                        src={profileImage}
                        alt={t("SENDER")}
                      />
                    )}
                  </Col>
                </Row>
                <div className="recipient-end__cta-wrapper">
                  <Row justify="center">
                    <Col xs={24} md={16} lg={24}>
                      <Button
                        className="recipient-end__cta"
                        type="secondary"
                        shape="round"
                        onClick={handleReplay}
                      >
                        {t("REPLAY_YOUR_MESSAGE")}
                        <Restart />
                      </Button>
                    </Col>
                  </Row>
                  {SHARE_TO_SOCIAL_MEDIA !== "" ? (
                    <Row justify="center">
                      <Col xs={24} md={16} lg={24}>
                        <Button
                          className="recipient-end__cta"
                          type="secondary"
                          shape="round"
                          htmlType="submit"
                          onClick={handleSocialMediaClick}
                        >
                          {t("SHARE_TO_SOCIAL_MEDIA")}
                          <Share />
                        </Button>
                      </Col>
                    </Row>
                  ) : null}

                  <Row justify="center">
                    <Col xs={24} md={16} lg={24}>
                      <p className="recipient-end__copy-body">
                        {t("YOUR_MESSAGE_AVAILABLE_FOR")}
                      </p>
                      <Button
                        className="recipient-end__cta"
                        type="secondary"
                        shape="round"
                        htmlType="submit"
                        onClick={copyToClipBoard}
                      >
                        {copyLink}
                        <Copy />
                      </Button>
                    </Col>
                  </Row>
                </div>
                <div className="recipient-end__expand">
                  <Row justify="center">
                    {productImage && (
                      <img
                        className="recipient-end__image"
                        src={productImage}
                        alt={t("PRODUCT")}
                      />
                    )}
                    <PlusCircle
                      type="button"
                      onClick={handleInformationClick}
                      className="recipient-end__plus-icon"
                    />
                  </Row>
                </div>
              </main>
            </div>
          </div>
        </div>
      </section>

      {/* <Footer /> */}
    </div>
  );
};

export default withRouter(End);
