import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import SingletonLogo from "../common/SingletonLogo.js"
import OccasionSymbol from "../common/OccasionSymbol.js"
import TextLine from "../common/textLine.js"


export class AnimationOccasionJW extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword, occasionID) {
  	super(parent, colors, duration, keyword);
    this.occasionID = occasionID;
  }

  start(){
    
    const self = this;
    var color = this.colors[1];
    switch(this.occasionID){
      case 1261:
        this.animationFathersDay();
        color = this.colors[0];
        break;

      default:
        this.animationBirthDay();
        color = this.colors[1];
        break;
    }

    const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
    const material1 = new THREE.MeshBasicMaterial( {color: color, side: THREE.DoubleSide,transparent: true} );
    const plane1 = new THREE.Mesh( geometry1, material1 );
    this.parent.add( plane1 );

    // set plane initial state
    plane1.position.set(0,-350,-150);

    gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    
    gsap.to(plane1.position,{delay:this.duration, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
      self.parent.remove(plane1);
   
    }})

  }

  animationFathersDay(){

    const self = this;
    const positionVector3 = new THREE.Vector3(0, -20, -10);

    var occasion = new OccasionSymbol(this.parent, this.colors[1], this.duration, positionVector3, "gift");
    gsap.to(occasion.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});

    const wordContainer = new THREE.Group();
    this.parent.add(wordContainer);

    for (var j = 0; j < 2; j++) {

      for (var i = 0; i < 3; i++) {
        const word = new TextLine(this.keyword.toUpperCase(), 2, 2, 400, this.colors[2], undefined, "JohnnieWalkerWeb-Headline");
        wordContainer.add(word);
        word.position.set(-3.4+j*7.2,6.5-i*10,-2);
        word.rotation.set(0,0,Math.PI/2);
        word.material.map.offset.x = 1;
        gsap.to(word.material.map.offset,{x:0, ease: "expo.inOut", duration:this.duration})
        gsap.to(word.material.map.offset,{delay:this.duration, x:-1, ease: "expo.inOut", duration:this.duration})
      }

    }

    gsap.to(occasion.position,{delay:this.duration, y:20, ease: "expo.inOut", duration:this.duration,onComplete:function(){
      self.parent.remove(occasion);
      self.parent.remove(wordContainer);
    }})

  }

  animationBirthDay(){

  const self = this;

 
    const positionVector3 = new THREE.Vector3(0, -10, -5);

    var occasion = new OccasionSymbol(this.parent, this.colors[2], this.duration, positionVector3, "baloon");
    occasion.rotation.set(0,0,-2);
    occasion.position.set(positionVector3.x,positionVector3.y-60,positionVector3.z);

    gsap.to(occasion.position,{delay:0.05, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(occasion.rotation,{delay:0.05, z:0, ease: "expo.inOut", duration:this.duration});

    gsap.to(occasion.position,{delay:this.duration, y:positionVector3.y+60, ease: "expo.inOut", duration:this.duration});
     
    const word = new TextLine(this.keyword.toUpperCase(), 1, 1, 400, this.colors[0], undefined, "JWCapsSerif-Book", 20);
    this.parent.add(word);
    word.position.set(0,-5.5,-2);

    //word.rotation.set(0,0,Math.PI/2);
    word.material.map.offset.y = 1;
    gsap.to(word.material.map.offset,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
    gsap.to(word.position,{delay:this.duration, y:10, ease: "expo.inOut", duration:this.duration,onComplete:function(){
      self.parent.remove(word);
      self.parent.remove(occasion);
    }})
    
  }

   
}

export default AnimationOccasionJW; // Don’t forget to use export default!