import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import TextLine from "../common/textLine.js"
import Coordinates from "../common/Coordinates.js"
import Compass from "../common/Compass.js"

export class AnimationCompassTalisker_4 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){

  	var self = this;

	// create plane  2
	const geometry2 = new THREE.PlaneGeometry(200 , 400, 2 );
	const material2 = new THREE.MeshBasicMaterial( {color: this.colors[0], side: THREE.DoubleSide,transparent: true} );
	const plane2 = new THREE.Mesh( geometry2, material2 );
	this.parent.add( plane2 );

	// set plane initial state
	plane2.position.set(0, -300,-200);
	plane2.scale.set(1,0,1);

	const logoContainer = new THREE.Group();
	this.parent.add(logoContainer);

	logoContainer.position.set(-7,-160,30);

	gsap.to(logoContainer.position,{delay:0, y:-7, ease: "expo.inOut", duration:this.duration});

	var count = 0;
	for (var i = 0; i < 3; i++) {
		for (var j = 0; j < 3; j++) {

			
			var type = "empty";
			if(j == 1 && i == 1){
				type = "";
			}
			if(count !=0 && count!=2 && count!=6 && count!=8){
				const positionVector3 = new THREE.Vector3(i*7.5, j*7.5-100, -250);
				const logo = new Compass(logoContainer, this.colors[1], this.duration, positionVector3, type);
				logo.rotation.set(0,0,Math.PI/2);
				gsap.to(logo.position,{delay:0.05*i, y:j*7.5,z:-35, ease: "expo.inOut", duration:this.duration});
				gsap.to(logo.rotation,{delay:0.05*i, z:0, ease: "expo.inOut", duration:this.duration});
				gsap.to(logo.position,{delay:this.duration+0.05*i, y:j*7.5+150,z:35, ease: "expo.inOut", duration:this.duration});
				gsap.to(logo.rotation,{delay:this.duration+0.05*i, z:-Math.PI/2, ease: "expo.inOut", duration:this.duration});
			}
			count++;
		}
	}

	const positionVector3b = new THREE.Vector3(-3, -10, 0 );
	const coord = new Coordinates(this.parent, this.colors[1], this.duration, positionVector3b);	
	gsap.to(coord.position,{delay:0, y:-3.8, ease: "expo.inOut", duration:this.duration});
	gsap.to(coord.position,{delay:this.duration, y:20, ease: "expo.inOut", duration:this.duration});

	// animate
	gsap.to(plane2.scale,{y:1, ease: "expo.inOut", duration:this.duration})
	gsap.to(plane2.position, {y: 0, delay:0, ease: "expo.inOut", duration:this.duration});
	gsap.to(plane2.position, {y: 400, delay:this.duration, ease: "expo.inOut", duration:this.duration, onComplete:function(){
		//self.isAnimating = false;
		
		self.parent.remove(logoContainer);
		self.parent.remove(plane2);
		self.parent.remove(coord);
	}});

  }

  destroy(){

  }
   
}

export default AnimationCompassTalisker_4; // Don’t forget to use export default!