import * as THREE from "three";
import gsap from "gsap";
import AnimationTemplate from "../animationTemplate.js"
import Zero from "../common/Zero.js"
import TextLine from "../common/textLine.js"


export class AnimationZerosSingleton_1 extends AnimationTemplate{
	
  constructor(parent, colors, duration, keyword) {
  	super(parent, colors, duration, keyword);
  }

  start(){
      
        const self = this;

      const geometry1 = new THREE.PlaneGeometry(200.0 , 400, 2 );
      const material1 = new THREE.MeshBasicMaterial( {color: this.colors[1], side: THREE.DoubleSide,transparent: true} );
      const plane1 = new THREE.Mesh( geometry1, material1 );
      this.parent.add( plane1 );

      // set plane initial state
      plane1.position.set(0,-350,-150);

      const wordContainer = new THREE.Group();
      this.parent.add(wordContainer);

      for (var i = 0; i < 2; i++) {

        var c = this.colors[0];
         if (i % 2 === 0) {  
            c = this.colors[2];   
          }
        const word = new TextLine(this.keyword, 1.5, 1.5, 400, c, undefined, 'Mackay Regular');
        wordContainer.add(word);
        word.position.set(-2,-25+i*10,0);
        word.rotation.set(0,0,Math.PI/2);

        gsap.to(word.position,{delay:0.05*i, y:-2+i*10, ease: "expo.inOut", duration:this.duration});
        gsap.to(word.position,{delay:this.duration, y:15+i*10, ease: "expo.inOut", duration:this.duration});

      }
    
      const positionVector3 = new THREE.Vector3(5, 0, 4);
      var zero = new Zero(this.parent, this.colors[0], this.duration, positionVector3);
      //zero.material.opacity = 0;
      //zero.rotation.set(0,Math.PI/2,0);
      gsap.to(zero.position,{x:2, delay:0, ease: "expo.inOut", duration:this.duration});
      //gsap.to(zero.material,{opacity:1, delay:0, ease: "expo.inOut", duration:this.duration});
      //gsap.to(zero.rotation,{y:Math.PI/2, delay:this.duration, ease: "expo.inOut", duration:this.duration});
      gsap.to(zero.position,{x:5, delay:this.duration, ease: "expo.inOut", duration:this.duration});

      gsap.to(plane1.position,{delay:0, y:0, ease: "expo.inOut", duration:this.duration});
      gsap.to(plane1.position,{delay:this.duration+0.5, y:400, ease: "expo.inOut", duration:this.duration, onComplete:function(){
        self.parent.remove(plane1);
        self.parent.remove(zero);
        self.parent.remove(wordContainer);
      }});


        
  }

  destroy(){
  	//this.parent.remove(this.plane1);
  }
   
}

export default AnimationZerosSingleton_1; // Don’t forget to use export default!