import React, { Component } from "react";
import * as THREE from "three";
//import OrbitControls from "three-orbitcontrols";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FullControl } from "Components/Audio/players";
import gsap from "gsap";
import FontFaceObserver from "fontfaceobserver";
import { GetAssetPath } from "Services/apiService";
import Button from "Components/Audio/players/Button";
import { ReactComponent as BottleStacked } from "Assets/img/miab-bottle-stacked.svg";
import TextLine from "./common/textLine.js";
import { Spin } from "antd";
import * as hash from "object-hash";

import { LoadingOutlined } from "@ant-design/icons";

// import CCapture from 'ccapture.js'
// import record from 'canvas-to-video';
// import * as CanvasCapture from 'canvas-capture';
// import * as CanvasRecorder from '../../archived/download-as-clip/canvas-recorder'
// import WebMWriter from "webm-writer";

import {
  AnimationOccasionJW,
  AnimationMask,
  AnimationMask_1,
  AnimationMask_2,
  AnimationMask_3,
  AnimationMask_4,
  AnimationParallelogram,
  AnimationParallelogram_1,
  AnimationParallelogram_2,
  AnimationParallelogram_3,
  AnimationParallelogram_4,
  AnimationWalkingMan,
  AnimationWalkingMan_1,
  AnimationWalkingMan_2,
  AnimationWalkingMan_3,
  AnimationWalkingMan_4,
  AnimationStripe,
  AnimationStripe_1,
  AnimationStripe_2,
  AnimationStripe_3,
  AnimationStripe_4,
  AnimationBottle,
  AnimationBottle_1,
  AnimationBottle_2,
  AnimationBottle_3,
  AnimationBottle_4,
} from "./johnnie-walker-black/";

import {
  AnimationOccasion,
  AnimationMaskSingleton,
  AnimationMaskSingleton_1,
  AnimationMaskSingleton_2,
  AnimationMaskSingleton_3,
  AnimationMaskSingleton_4,
  AnimationBrushSingleton,
  AnimationBrushSingleton_1,
  AnimationBrushSingleton_2,
  AnimationBrushSingleton_3,
  AnimationBrushSingleton_4,
  AnimationZerosSingleton,
  AnimationZerosSingleton_1,
  AnimationZerosSingleton_2,
  AnimationZerosSingleton_3,
  AnimationZerosSingleton_4,
  AnimationSalmonSingleton,
  AnimationSalmonSingleton_1,
  AnimationSalmonSingleton_2,
  AnimationSalmonSingleton_3,
  AnimationSalmonSingleton_4,
  AnimationMapSingleton,
  AnimationMapSingleton_1,
  AnimationMapSingleton_2,
  AnimationMapSingleton_3,
  AnimationMapSingleton_4,
  AnimationBrandSingleton,
  AnimationBrandSingleton_1,
  AnimationBrandSingleton_2,
  AnimationBrandSingleton_3,
  AnimationBrandSingleton_4,
} from "./singleton/";

import {
  AnimationOccasionTalisker,
  AnimationMaskTalisker,
  AnimationMaskTalisker_1,
  AnimationMaskTalisker_2,
  AnimationMaskTalisker_3,
  AnimationMaskTalisker_4,
  AnimationBrandTalisker,
  AnimationBrandTalisker_1,
  AnimationBrandTalisker_2,
  AnimationBrandTalisker_3,
  AnimationBrandTalisker_4,
  AnimationCompassTalisker,
  AnimationCompassTalisker_1,
  AnimationCompassTalisker_2,
  AnimationCompassTalisker_3,
  AnimationCompassTalisker_4,
  AnimationStampTalisker,
  AnimationStampTalisker_1,
  AnimationStampTalisker_2,
  AnimationStampTalisker_3,
  AnimationStampTalisker_4,
  AnimationCoastlineTalisker,
  AnimationTextureTalisker,
  AnimationTextureTalisker_1,
  AnimationTextureTalisker_2,
  AnimationTextureTalisker_3,
  AnimationTextureTalisker_4,
} from "./talisker/";

import "./Experience.scss";
import { connect } from "react-redux";
import {
  loadMessageExperience,
  loadMessageUser,
} from "Services/messagesService";
import { EventManager, EVENTNAME } from "Utils/event-manager";
import { clearMessageExperience } from "Store/reducers/message-experience";
import { clearMessageUser } from "Store/reducers/message-user";
import { TranslationContext } from "Contexts/translation-context";

class Experience extends Component {
  static contextType = TranslationContext;
  mounted = false;

  constructor(props) {
    super(props);

    this._player = React.createRef();
    this._spin = React.createRef();
    this.sequenceCount = -1;
    this.local = false;
    // var capturer = new CCapture( { format: 'webm', framerate: 30 } );
    // Initialize and pass in canvas.

    this.animations = [
      AnimationOccasionJW,
      AnimationMask,
      AnimationMask_1,
      AnimationMask_2,
      AnimationMask_3,
      AnimationMask_4,
      AnimationParallelogram,
      AnimationParallelogram_1,
      AnimationParallelogram_2,
      AnimationParallelogram_3,
      AnimationParallelogram_4,
      AnimationWalkingMan,
      AnimationWalkingMan_1,
      AnimationWalkingMan_2,
      AnimationWalkingMan_3,
      AnimationWalkingMan_4,
      AnimationStripe,
      AnimationStripe_1,
      AnimationStripe_2,
      AnimationStripe_3,
      AnimationStripe_4,
      AnimationBottle,
      AnimationBottle_1,
      AnimationBottle_2,
      AnimationBottle_3,
      AnimationBottle_4,
      AnimationOccasion,
      AnimationMaskSingleton,
      AnimationMaskSingleton_1,
      AnimationMaskSingleton_2,
      AnimationMaskSingleton_3,
      AnimationMaskSingleton_4,
      AnimationBrushSingleton,
      AnimationBrushSingleton_1,
      AnimationBrushSingleton_2,
      AnimationBrushSingleton_3,
      AnimationBrushSingleton_4,
      AnimationZerosSingleton,
      AnimationZerosSingleton_1,
      AnimationZerosSingleton_2,
      AnimationZerosSingleton_3,
      AnimationZerosSingleton_4,
      AnimationSalmonSingleton,
      AnimationSalmonSingleton_1,
      AnimationSalmonSingleton_2,
      AnimationSalmonSingleton_3,
      AnimationSalmonSingleton_4,
      AnimationMapSingleton,
      AnimationMapSingleton_1,
      AnimationMapSingleton_2,
      AnimationMapSingleton_3,
      AnimationMapSingleton_4,
      AnimationBrandSingleton,
      AnimationBrandSingleton_1,
      AnimationBrandSingleton_2,
      AnimationBrandSingleton_3,
      AnimationBrandSingleton_4,
      AnimationOccasionTalisker,
      AnimationMaskTalisker,
      AnimationMaskTalisker_1,
      AnimationMaskTalisker_2,
      AnimationMaskTalisker_3,
      AnimationMaskTalisker_4,
      AnimationBrandTalisker,
      AnimationBrandTalisker_1,
      AnimationBrandTalisker_2,
      AnimationBrandTalisker_3,
      AnimationBrandTalisker_4,
      AnimationCompassTalisker,
      AnimationCompassTalisker_1,
      AnimationCompassTalisker_2,
      AnimationCompassTalisker_3,
      AnimationCompassTalisker_4,
      AnimationStampTalisker,
      AnimationStampTalisker_1,
      AnimationStampTalisker_2,
      AnimationStampTalisker_3,
      AnimationStampTalisker_4,
      AnimationCoastlineTalisker,
      AnimationTextureTalisker,
      AnimationTextureTalisker_1,
      AnimationTextureTalisker_2,
      AnimationTextureTalisker_3,
      AnimationTextureTalisker_4,
    ];
    this.maxPause = 1.5;
    this.colourPalette = [];
    this.state = {
      user: null,
      products: null,
      sequence: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26,
      ],
      isTextOnly: false,
      words: [],
      textOnlySentences: [],
      loaded: false,
      isAudioPlayerCollapsed: false,
      audioLoaded: false,
      fontsLoaded: false,
      texturesLoaded: false,
      buttonPlaying: false,
      audioEnded: false,
      playerWidth: "",
    };
    // text only frames
    this.captionFrames = [];
    // used for debugging only
    this.testAnimation = AnimationBrushSingleton_4;
    this.animationStartTime = -1;

    THREE.Cache.enabled = true;

    this.eventManager = new EventManager({
      eventName: EVENTNAME.PLAY_MESSAGE_IN_A_BOTTLE,
      handler: () => {
        console.log(
          "Event Manager Called:",
          EVENTNAME.PLAY_MESSAGE_IN_A_BOTTLE
        );
        this.playMessage();
      },
    });
  }

  componentDidMount() {
    this.mounted = true;
    this.preparePlayer();
    this.eventManager.subscribe();
  }

  componentWillUnmount() {
    this.mounted = false;
    this.eventManager.unsubscribe();
  }

  componentDidUpdate() {
    this.checkExperienceHash();
  }

  checkExperienceHash() {
    requestAnimationFrame(() => {
      if (this.mounted) {
        // This is a slightly weird way to update the component but is neccessary due to how it's been built
        // previously - we quickly hash the object ot detect changes and then push that upwards so we can refresh
        // this Experience component fully if changes have been made.
        const { user } = getProps(this.props.getUserData);
        const experienceHash = hash(user);
        if (this.props.experienceUpdated)
          this.props.experienceUpdated(experienceHash);
      }
    });
  }

  preparePlayer() {
    this.checkExperienceHash();
    const { user, products } = getProps(this.props.getUserData);

    if (user && products && this.mounted) {
      // parse colour palette
      for (let i = 0; i < products.colour_palette.length; i++) {
        this.colourPalette.push(parseInt(products.colour_palette[i]));
      }
      // parseint all sequence
      for (let i = 0; i < products.animations.length; i++)
        products.animations[i] = parseInt(products.animations[i], 10);

      let isTextOnly = false;
      let textOnlySentences = [];

      const ext = user.audio_message.substr(
        user.audio_message.lastIndexOf(".") + 1
      );

      // if the audio file is not present (search for mp3 extension) activate the text only experience.
      if (ext !== "mp3") {
        isTextOnly = true;
        textOnlySentences = this.getSentences(user.text_message);
      }

      this.setState({
        user,
        products,
        sequence: products.animations,
        isTextOnly,
        textOnlySentences,
      }); // update component state

      //this.simulate();
      //preload all textures
      this.loadTextures();
      this.loadFonts();
    }
  }

  loadTextures() {
    const self = this;
    const manager = new THREE.LoadingManager();
    manager.onStart = function (url, itemsLoaded, itemsTotal) {};

    manager.onLoad = () => {
      if (this.mounted) {
        self.setState({ texturesLoaded: true });
        // auto play only if the audio is fully loaded or the experience is text only (no audio)
        self.setupExperience();
      }
    };

    manager.onProgress = function (url, itemsLoaded, itemsTotal) {};

    manager.onError = function (url) {};

    // load all textures
    var textures = [
      "bottle.png",
      "brush.png",
      "JwStripe.png",
      "JwStripe-h.png",
      "JwStripe2.png",
      "Black_label-walking-man.png",
      "zero.png",
      "black_label_1.jpg",
      "black_label_2.jpg",
      "black_label_3.jpg",
      "black_label_4.jpg",
      "black_label_5.jpg",
      "singleton_1.png",
      "singleton_2.png",
      "singleton_3.png",
      "singleton_4.png",
      "singleton_5.png",
      "singleton-map.png",
      "singleton-logo.png",
      "singleton-signature.png",
      "occasion-baloon.png",
      "occasion-gift.png",
      "talisker-coordinates.png",
      "talisker-compass.png",
      "talisker-compass-empty.png",
      "talisker-stamp.png",
      "talisker-1.jpg",
      "talisker-2.jpg",
      "talisker-3.jpg",
      "talisker-4.jpg",
      "talisker-5.jpg",
      "talisker-coastline.png",
      "talisker-texture-blue.jpg",
      "talisker-texture-white.jpg",
      "talisker-merlion.png",
    ];

    for (var i = 0; i < textures.length; i++) {
      const loader = new THREE.TextureLoader(manager);
      const imagePath = GetAssetPath(textures[i]);
      loader.load(imagePath, function (object) {});
    }
  }

  setupExperience = () => {
    if (this.state.loaded) {
      return;
    }
    if (!this.state.isTextOnly) {
      if (
        !this.state.audioLoaded ||
        !this.state.texturesLoaded ||
        !this.state.fontsLoaded
      ) {
        return;
      }
    } else {
      if (!this.state.texturesLoaded || !this.state.fontsLoaded) {
        return;
      }
    }

    this.setState({ loaded: true });
    this.setupScene(); // init three.js scene

    this.sequenceCount = 0;

    if (!this.state.isTextOnly) {
      this.composeExperience();
    } else {
      this.composeTextOnlyExperience();
    }

    // this.playMessage();
  };

  // setup Three.js scene
  setupScene = () => {
    const width = this.mount.clientWidth;
    const height = this.mount.clientHeight;
    this.scene = new THREE.Scene();

    //Add Renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(this.colourPalette[0]);
    this.renderer.setSize(width, height);
    this.mount.appendChild(this.renderer.domElement);

    //add Camera
    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    this.camera.position.z = 10;

    //Camera Controls
    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enabled = false;

    //LIGHTS
    var lights = [];
    lights[0] = new THREE.PointLight(0x304ffe, 1, 0);
    lights[1] = new THREE.PointLight(0xffffff, 1, 0);
    lights[2] = new THREE.PointLight(0xffffff, 1, 0);
    lights[0].position.set(0, 200, 0);
    lights[1].position.set(100, 200, 100);
    lights[2].position.set(-100, -200, -100);
    this.scene.add(lights[0]);
    this.scene.add(lights[1]);
    this.scene.add(lights[2]);
    //ADD Your 3D Models here
    this.renderScene();
    //start animation
    this.start();
  };

  loadFonts = () => {
    this.fonts = [
      "DIN Condensed W05",
      "Mackay Regular",
      "Korolev Compressed Bold",
      "JohnnieWalkerWeb-Headline",
      "JWCapsSerif-Book",
      "Authenia W05",
    ];

    var observers = [];
    for (var i = 0; i < this.fonts.length; i++) {
      observers[i] = new FontFaceObserver(this.fonts[i]).load(null, 6000);
    }

    Promise.all(observers)
      .catch((errors) => {
        console.error(errors);
      })
      .finally(() => {
        if (this.mounted) {
          this.setState({ fontsLoaded: true });
          this.setupExperience();
        }
      });
  };

  // only used to generate client preview
  simulate = () => {
    var self = this;

    // interval for testing animation sequence
    setInterval(function () {
      if (self.state) {
        var animationType =
          self.animations[self.state.sequence[self.sequenceCount]];

        var animation = new animationType(
          self.scene,
          self.colourPalette,
          1.5,
          "happy anniversary",
          1261
        );

        animation.start();

        var randomSound = Math.floor(Math.random() * 9);
        self.playSound(randomSound);

        if (self.sequenceCount < self.state.sequence.length - 1) {
          self.sequenceCount++;
        } else {
          self.sequenceCount = 0;
        }
      }
    }, 2000);
  };

  start = () => {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate);
    }
  };

  stop = () => {
    cancelAnimationFrame(this.frameId);
  };

  playSound = (id) => {
    var sound = document.getElementById("audio-" + id);
    sound.volume = 0.2;
    sound.play();
  };

  prePlaySounds = () => {
    for (var i = 0; i < this.state.products.sounds.length; i++) {
      var sound = document.getElementById("audio-" + i);
      // console.log(sound);
      // sound.play();
      sound.pause();
      sound.currentTime = 0;
    }
  };

  playMessage = async () => {
    if (this.state.playing || !this.mounted) return;

    if (this.state.isTextOnly) {
      this.handleTextOnlyToggle();
    }

    if (!this.state.loaded) return;
    this.audioEnded = false;

    if (this._player?.current && !this._player?.current?.isPlaying()) {
      this._player.current.play();
    }

    if (this.props.statusFeedback) this.props.statusFeedback(true);

    // var audioDuration = Math.round(this._player.current.getDuration()) * 1000; // get audio duration
    // console.log("PLAY MESSAGE");
    // console.log("START RECORDING CANVAS/THREE.JS ANIMATIONS", audioDuration);

    // let videoWriter = new WebMWriter({
    //   quality: 0.95, // WebM image quality from 0.0 (worst) to 0.99999 (best), 1.00 (VP8L lossless) is not supported
    //   fileWriter: null, // FileWriter in order to stream to a file instead of buffering to memory (optional)
    //   fd: null, // Node.js file handle to write to instead of buffering to memory (optional)

    //   // You must supply one of:
    //   frameDuration: audioDuration, // Duration of frames in milliseconds
    //   frameRate: 30, // Number of frames per second

    //   transparent: false, // True if an alpha channel should be included in the video
    //   alphaQuality: undefined, // Allows you to set the quality level of the alpha channel separately.
    //   // If not specified this defaults to the same value as `quality`.
    // });

    // let canvas = document.querySelector("#parent-canvas").firstChild;
    // videoWriter.addFrame(canvas);

    // CanvasRecorder.prepareVideo(document.querySelector('#parent-canvas').firstChild)

    // CanvasCapture.beginVideoRecord({ format: CanvasCapture.WEBM })

    // capturer.start();

    // setTimeout(async () => {
    //   console.log("finish record canvas");
    //   // await CanvasCapture.stopRecord({
    //   //   onExport: (blob, filename) => {
    //   //     const videoUrl = URL.createObjectURL(blob);
    //   //     console.log('video webm url', videoUrl)
    //   //   }
    //   // });
    //   // CanvasRecorder.stopVideo()
    //   videoWriter.complete().then(function (webMBlob) {
    //     console.log("videoURl", URL.createObjectURL(webMBlob));
    //     const a = document.createElement("a");
    //     document.body.appendChild(a);
    //     const url = window.URL.createObjectURL(webMBlob);
    //     a.href = url;
    //     a.download = "video-canvas.webm";
    //     a.click();
    //   });

    //   console.log("finish record canvas after wait");
    // }, audioDuration);
  };

  animatePlayer = () => {
    if (!this.state.isAudioPlayerCollapsed) {
      var initialDelay = 1;
      gsap.to(".sender-label", {
        delay: initialDelay,
        opacity: 0,
        ease: "expo.inOut",
        duration: 1,
      });
      gsap.to(".sender-name", {
        delay: initialDelay,
        opacity: 0,
        ease: "expo.inOut",
        duration: 1,
      });
      gsap.to(".sender-name", {
        delay: initialDelay,
        opacity: 0,
        ease: "expo.inOut",
        duration: 1,
      });
      gsap.to(".player-container", {
        right: "calc(100% - 14rem)",
        ease: "expo.inOut",
        duration: 1,
        delay: initialDelay + 1,
        onComplete: () => {
          if (this.mounted) {
            this.setState({ isAudioPlayerCollapsed: true });
            if (!this.state.playing) this.expandPlayer();
          }
        },
      });
    }
  };

  expandPlayer = () => {
    if (this.state.isAudioPlayerCollapsed) {
      const self = this;
      gsap.to(".sender-label", {
        opacity: 1,
        ease: "expo.inOut",
        duration: 1,
        delay: 1,
      });
      gsap.to(".sender-name", {
        opacity: 1,
        ease: "expo.inOut",
        duration: 1,
        delay: 1,
      });
      gsap.to(".sender-name", {
        opacity: 1,
        ease: "expo.inOut",
        duration: 1,
        delay: 1,
      });
      gsap.to(".howler-play-button", {
        opacity: 1,
        ease: "expo.inOut",
        duration: 1,
        delay: 1,
      });
      gsap.to(".player-container", {
        right: "2rem",
        ease: "expo.inOut",
        duration: 1,
        onComplete: function () {
          self.setState({ isAudioPlayerCollapsed: false });
        },
      });
    }
  };

  animate = () => {
    if (this.state.isTextOnly) {
      this.animateTextOnlyExperience();
    } else {
      if (!this._player.current) {
        return;
      }
      this.animateAudioExperience();
    }

    //Animate Models Here
    //ReDraw Scene with Camera and Scene Object
    this.renderScene();
    this.frameId = window.requestAnimationFrame(this.animate);
    // capturer.capture( document.querySelector('#parent-canvas').firstChild );
  };

  animateAudioExperience = () => {
    // only execute if there arraye any words left
    if (
      this.state.words.length > 0 &&
      this._player.current &&
      this._player.current.isPlaying() &&
      !this.audioEnded
    ) {
      // check if start time is null (punctuation)
      if (this.state.words[0].startTime == null) {
        this.state.words.shift(); // remove executed word from array
      } else {
        const startTime = parseFloat(this.state.words[0].startTime);

        // check whenever the current time is equal or greated than the word start time
        if (this._player.current.getSeek() >= startTime) {
          var word = this.state.words[0].word;

          // play only if it's keyword
          if (this.state.words[0].keyword) {
            var animationType =
              this.animations[this.state.sequence[this.sequenceCount]];

            var animation = new animationType(
              this.scene,
              this.colourPalette,
              1.2,
              word,
              this.props.occasionID
            ); // parent, color array, duration
            animation.start();

            var randomSound = Math.floor(Math.random() * 9);
            this.playSound(randomSound);

            // increment animation sequence
            this.sequenceCount++;

            if (this.sequenceCount >= this.state.sequence.length) {
              this.sequenceCount = 2;
            }
          }

          this.state.words.shift(); // remove executed word from array
        }
      }
    } else {
      // update array if there are no words
      if (
        this.state.words.length < 2 &&
        !this._player.current.isPlaying() &&
        this.sequenceCount !== 0 &&
        this._player.current.isLoaded()
      ) {
        this.composeExperience();
        this.sequenceCount = 0;
      }
    }
  };

  animateTextOnlyExperience = () => {
    if (
      this.captionFrames.length > 0 &&
      this.animationStartTime > 0 &&
      this.state.playing
    ) {
      var currentTime = Date.now() - this.animationStartTime;

      for (var i = 0; i < this.captionFrames.length; i++) {
        const caption = this.captionFrames[i];
        const startTime = parseFloat(caption.startTime);

        if (startTime <= currentTime / 1000) {
          var animationType =
            this.animations[this.state.sequence[this.sequenceCount]];

          const self = this;

          var randomSound = Math.floor(Math.random() * 9);
          this.playSound(randomSound);

          var textColor = this.colourPalette[2];
          var bgColor = this.colourPalette[1];
          var font = "JWCapsSerif-Book";

          var animation_style = this.state.products.animation_style;

          if (animation_style) {
            switch (animation_style) {
              case "1":
                textColor = this.colourPalette[2];
                bgColor = this.colourPalette[1];
                font = "JWCapsSerif-Book";
                break;
              case "2":
                textColor = this.colourPalette[0];
                bgColor = this.colourPalette[2];
                font = "Mackay Regular";
                break;
              case "3":
                textColor = this.colourPalette[0];
                bgColor = this.colourPalette[1];
                font = "DIN Condensed W05";
                break;
              default:
                break;
            }
          } else {
            var brand = this.state.products.brand;

            switch (brand) {
              case "Johnnie Walker":
                textColor = this.colourPalette[2];
                bgColor = this.colourPalette[1];
                font = "JWCapsSerif-Book";
                break;
              case "The Singleton":
                textColor = this.colourPalette[0];
                bgColor = this.colourPalette[2];
                font = "Mackay Regular";
                break;
              case "Talisker":
                textColor = this.colourPalette[0];
                bgColor = this.colourPalette[1];
                font = "DIN Condensed W05";
                break;
              default:
                break;
            }
          }

          var tweenDuration = 1;
          var pauseDuration = 1;

          if (caption.caption1) {
            const caption1 = new TextLine(
              caption.caption1,
              0.6,
              0.8,
              400,
              textColor,
              bgColor,
              font,
              0,
              false
            );
            this.scene.add(caption1);
            caption1.position.set(0, -9, 1);

            gsap.to(caption1.position, {
              y: -3,
              ease: "expo.inOut",
              duration: tweenDuration,
              onComplete: function () {
                //self.scene.remove(caption1);
                // var randomSound = Math.floor(Math.random() * 9);
                //self.playSound(randomSound);
              },
            });

            gsap.to(caption1.position, {
              y: 10,
              ease: "expo.inOut",
              duration: tweenDuration,
              delay: tweenDuration + pauseDuration,
              onComplete: function () {
                self.scene.remove(caption1);
              },
            });
          }

          if (caption.caption2) {
            const caption2 = new TextLine(
              caption.caption2,
              0.6,
              0.8,
              400,
              textColor,
              bgColor,
              font,
              0,
              false
            );
            this.scene.add(caption2);
            caption2.position.set(0, -10, 1);

            gsap.to(caption2.position, {
              y: -4,
              delay: 0.1,
              ease: "expo.inOut",
              duration: tweenDuration,
              onComplete: function () {
                //self.scene.remove(caption2);
              },
            });
            gsap.to(caption2.position, {
              y: 10,
              ease: "expo.inOut",
              duration: tweenDuration,
              delay: tweenDuration + 0.1 + pauseDuration,
              onComplete: function () {
                self.scene.remove(caption2);
              },
            });
          }

          var word = " ";
          var animation = new animationType(
            this.scene,
            this.colourPalette,
            tweenDuration + pauseDuration,
            word,
            this.props.occasionID
          ); // parent, color array, duration

          console.log(animation);
          animation.start();

          this.captionFrames.shift();

          console.log("Caption Frames: ", this.captionFrames);

          if (this.captionFrames <= 0) {
            this.handleOnTextMessageEnd();

            this.sequenceCount = -1;
            this.setState({ playing: false });
          } else {
            this.sequenceCount++;
          }
        }
      }
    } else {
      if (this.sequenceCount == -1) {
        this.composeTextOnlyExperience();
      }
    }
  };

  // split text into captions
  getSentences = (text) => {
    // max character per caption
    var maxCharacters = 10;
    // split words
    var words = text.split(" ");

    var sentence = "";
    var sentences = [];
    // loop through words
    for (var i = 0; i < words.length; i++) {
      // add word to current caption
      sentence += words[i] + " ";
      // if caption is longer than limit or if it's the last word
      if (sentence.length >= maxCharacters || i == words.length - 1) {
        // trim last character if it's a space
        sentence = sentence.replace(/\s+$/, "");
        // add to sentences array
        sentences.push(sentence);
        //reset temp variable
        sentence = "";
      }
    }
    return sentences;
  };

  composeTextOnlyExperience = () => {
    this.sequenceCount = 0;
    this.animationStartTime = Date.now();

    var captions = this.state.textOnlySentences;
    var captionFrames = [];
    var interval = 1.5;

    for (var i = 0; i < captions.length; i += 2) {
      var frame = {
        startTime: i * interval,
        endTime: i * interval + 1,
        caption1: captions[i],
        caption2: captions[i + 1],
      };

      captionFrames.push(frame);
    }

    this.captionFrames = captionFrames;
  };

  composeExperience = () => {
    if (this.state.isTextOnly) {
      this.composeTextOnlyExperience();
      return;
    }

    var keywordsOnly = [];
    this.state.words = [];

    // add first Frame
    var firstFrame = {
      startTime: 0.1,
      endTime: 0.2,
      word: this.state.user.occasion,
      keyword: true,
    };

    keywordsOnly.push(firstFrame);

    // create temporary array with keywords only
    for (var i = 0; i < this.state.user.words.length; i++) {
      var wordObj = this.state.user.words[i];

      if (wordObj.keyword == true) {
        keywordsOnly.push(this.state.user.words[i]);
      }
    }

    // add last frame animation
    var audioDuration = this._player.current.getDuration(); // get audio duration

    if (audioDuration > 1) {
      var lastFrame = {
        startTime: audioDuration,
        endTime: audioDuration,
        word: this.state.user.occasion,
        keyword: true,
      };

      keywordsOnly.push(lastFrame);
    }

    // loop through keyword only array
    for (var j = 0; j < keywordsOnly.length; j++) {
      var keyword = keywordsOnly[j];

      // convert time to float
      keyword.startTime = parseFloat(keyword.startTime);
      keyword.endTime = parseFloat(keyword.endTime);

      // add the keyword on the final array
      this.state.words.push(keyword);

      // look if there is a next keyword
      var nextKeyword = keywordsOnly[j + 1];

      if (nextKeyword) {
        nextKeyword.startTime = parseFloat(nextKeyword.startTime);
        nextKeyword.endTime = parseFloat(nextKeyword.endTime);

        // calculate the gap between keywords
        var keywordGap = nextKeyword.startTime - keyword.startTime;

        // discard next keyword if it's too close
        if (keywordGap < 1) {
          // remove the next keyword
          keywordsOnly.splice(j + 1, 1);
          // look for a new keyword
          nextKeyword = keywordsOnly[j + 1];

          //if there is a keyword
          if (nextKeyword) {
            // recalculate keyword gap to fill
            nextKeyword.startTime = parseFloat(nextKeyword.startTime);
            nextKeyword.endTime = parseFloat(nextKeyword.endTime);
            keywordGap = nextKeyword.startTime - keyword.startTime;
          }
        }

        // calculate the number of black animations we can fit into the gap
        var keywordsToFill = Math.floor(keywordGap / this.maxPause) - 1;

        for (var g = 0; g < keywordsToFill; g++) {
          // add animations (empty keywords)
          var emptyKeyword = {
            startTime: parseFloat(
              this.maxPause + keyword.startTime + this.maxPause * g
            ).toFixed(2),
            endTime: parseFloat(
              this.maxPause + keyword.startTime + this.maxPause * g + 0.5
            ).toFixed(2),
            word: " ",
            keyword: true,
          };

          this.state.words.push(emptyKeyword);
        }
      }
    }
  };

  handleOnTextMessageEnd = () => {
    if (this.props.source == "recipient") {
      if (this._player.current) {
        this._player.current.mute();
      }

      setTimeout(() => {
        this.setState({ audioEnded: true });
      }, 3000);

      setTimeout(() => {
        this.props.onEnd();
      }, 5000);
    }

    setTimeout(() => {
      // Give time for the animation to finish.
      if (this.props.statusFeedback && this.mounted)
        this.props.statusFeedback(false);
      this.expandPlayer();
    }, 3000);
  };

  handleOnEnd = () => {
    console.log("😍😍 - Ending!!!");

    if (this.props.source == "recipient") {
      this.setState({ audioEnded: true });

      if (this._player.current) {
        this._player.current.mute();
      }

      setTimeout(() => {
        console.log("### - Timeout Ended!!");
        this.props.onEnd();
      }, 2000);
    }

    if (this.props.statusFeedback) this.props.statusFeedback(false);
    this.expandPlayer();
  };

  handleOnPlay = () => {
    if (this.state.playing || !this.mounted) return;
    this.prePlaySounds();

    this.animationStartTime = Date.now();
    this.animatePlayer();
    this.setState({ buttonPlaying: true });

    console.log("Step 1 - About to play message recording...");

    this.playMessage().catch((error) => {
      console.log("ERROR - Cant play audio recording.");
      console.log(error);
    });
  };

  handleAudioOnLoad = () => {
    this.setState({ audioLoaded: true });
    this.setupExperience();
  };

  handleTextOnlyToggle = () => {
    console.log("PLAY TO SEE THE PREVIEW");

    this.prePlaySounds();
    if (!this.state.playing) {
      this.animationStartTime = Date.now();
      this.animatePlayer();
    }

    this.setState({
      playing: !this.state.playing,
      buttonPlaying: true,
    });

    if (this.props.statusFeedback)
      this.props.statusFeedback(!this.state.playing);
  };

  renderScene = () => {
    if (this.renderer) this.renderer.render(this.scene, this.camera);
  };

  render() {
    if (!this.state.products || !this.state.user) {
      return null;
    }

    var renderItems = [];
    var audioFile = "";
    var profilePic = "";
    var senderName = "";

    if (this.state.products) {
      audioFile = this.state.user.audio_message;
      // audioFile = ""; // REMOVE

      profilePic = this.state.user.profile_pic;

      //var ext = profilePic.substr(profilePic.lastIndexOf('.') + 1);

      const imgMatchMattern = /\.(jpe?g|png|gif|svg)$/gi;
      profilePic = imgMatchMattern.test(profilePic)
        ? profilePic
        : this.props.productImage;

      senderName = this.state.user.first_name + " " + this.state.user.last_name;

      renderItems = this.state.products.sounds.map(function (item, i) {
        var audio_id = "audio-" + i;
        var audio_url =
          window.location.protocol +
          "//" +
          window.location.host +
          "/" +
          GetAssetPath(item, true);
        return <audio id={audio_id} key={i} src={audio_url}></audio>;
      });
    }

    const canvasW = this.props.canvasWidth;
    const canvasH = this.props.canvasHeight;
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    const { loaded } = this.state;
    const { isAudioPlayerCollapsed } = this.state;
    const { isTextOnly } = this.state;

    var playerClasses = "player-container";
    var experienceClass = "experience-recipient";
    var styles = { width: canvasW, height: canvasH, margin: "auto" };

    if (this.props.source != "recipient") {
      //playerClasses += ' hidden';
      experienceClass = "experience";
      styles = {};
      // delete styles['margin'];
    }

    // hide audio player if it's the text only version
    if (isTextOnly) {
      playerClasses += " hidden";
    }

    const { t } = this.context;
    return (
      <div className={experienceClass} style={styles}>
        {/* <video ref={this.state.videoRef} src={this.state.videoUrl} width={canvasW} height={canvasH} ></video> */}
        {loaded && !this.state.playing && !this.state.buttonPlaying ? (
          <div className="big-play">
            <div className="big-play-wrap">
              <Button
                className="big-play-button"
                onClick={() => {
                  if (!isTextOnly) {
                    this.handleOnPlay();
                  } else {
                    this.handleTextOnlyToggle();
                  }
                }}
              >
                <span className="play-icon-white"></span>
              </Button>
            </div>
          </div>
        ) : null}

        {loaded ? null : <Spin indicator={antIcon} ref={this._spin} />}
        {this.state.audioEnded ? (
          <div className="end-message">
            <div className="end-message-wrap">
              <BottleStacked className="end-message-image" />
            </div>
          </div>
        ) : null}

        <div
          id="parent-canvas"
          style={{ width: canvasW, height: canvasH, margin: "auto" }}
          ref={(mount) => {
            this.mount = mount;
          }}
        ></div>

        {!isTextOnly ? (
          <div className={playerClasses}>
            <div className="sender-label">{t("SENT_BY")}</div>
            <div className="sender-name">{this.props.senderName}</div>
            {profilePic ? (
              <img className="profile-pic" src={profilePic} alt={t("SENDER")} />
            ) : null}

            <FullControl
              ref={this._player}
              enabled={!isAudioPlayerCollapsed}
              audioFile={audioFile}
              onEnd={this.handleOnEnd}
              onPlay={this.handleOnPlay}
              onLoad={this.handleAudioOnLoad}
              prePlaySounds={this.prePlaySounds}
            />
          </div>
        ) : null}

        {isTextOnly ? (
          <div className="player-container">
            <div className="sender-label">{t("SENT_BY")}</div>
            <div className="sender-name">{this.props.senderName}</div>
            {profilePic ? (
              <img className="profile-pic" src={profilePic} alt={t("SENDER")} />
            ) : null}
            <Button
              className="howler-play-button"
              onClick={this.handleTextOnlyToggle}
            >
              {this.state.playing ? (
                <span className="pause-icon"></span>
              ) : (
                <span className="play-icon"></span>
              )}
            </Button>
          </div>
        ) : null}
        <div className="audio-container">{renderItems}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getUserData: state.messageUserReducer.data,
  getExperienceData: state.messageExperienceReducer.data,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (messageToken, ocassionId) =>
      dispatch(loadMessageUser(messageToken, ocassionId)),
    getExperience: (messageToken) =>
      dispatch(loadMessageExperience(messageToken)),
    clearUser: () => dispatch(clearMessageUser()),
    clearExperience: () => dispatch(clearMessageExperience()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Experience);

const getProps = (data) => {
  if (!data || !data?.legacy) {
    return { user: null, products: null };
  }

  const {
    brand,
    product_name,
    product_sku,
    colour_palette,
    animations,
    sounds,
    first_name,
    last_name,
    profile_pic,
    occasion,
    audio_message,
    text_message,
    words,
  } = data.legacy;

  return {
    user: {
      first_name,
      last_name,
      profile_pic,
      occasion,
      product_sku,
      audio_message: audio_message || "",
      text_message,
      words,
    },
    products: {
      brand,
      product_name,
      product_sku,
      colour_palette,
      animations,
      sounds,
    },
  };
};
