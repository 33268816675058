import React, { useEffect } from "react";
import { Button } from "antd";
import { Footer } from "Components/Layout";
import "./Recipient.scss";
import "./../RecipientFrame.scss";
import { ReactComponent as BottleStacked } from "Assets/img/miab-bottle-stacked.svg";
import { ReactComponent as Volume } from "Assets/img/volume.svg";
import { ReactComponent as Play } from "Assets/img/play.svg";

import { useDispatch } from "react-redux";
import { getConsumer } from "Services/consumerService";
import { useHistory } from "react-router";
import { useMessage } from "Hooks/recipient/useMessage";
import { useConsumer } from "Hooks/recipient/useConsumer";
import { useTranslation } from "Contexts/translation-context";

export default function Home() {
  const { t } = useTranslation();
  const {
    occasionImage,
    productImage,
    profileImage,
    consumerId,
    messageToken
  } = useMessage();
  const { recipientName, giverName } = useConsumer();
  const history = useHistory();

  const handleOnPlay = () => {
    history.push("/recipient/play-message");
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (consumerId) {
      dispatch(getConsumer(consumerId));
    }
  }, [consumerId]);
  console.log("occasionImage", occasionImage)
  console.log("messageToken", messageToken)
  return (
    <div className="page-wrap">
      <section className="recipient">
        <div className="container">
          <div className="recipient-frame">
          {messageToken === "00000000-0000-0000-0000-000000000000"
          ? (
              <div className="no-message-err">
                <p>Message not recorded yet</p>
              </div>
            )
          : (

            <div className="recipient-frame__inner">
              <div className="recipient__container">
                {occasionImage && (
                  <img
                    className="recipient__occasion-image"
                    src={occasionImage}
                    alt={t("OCASSION_BACKGROUND")}
                  />
                )}

                <BottleStacked className="recipient__logo" />

                <main className="ant-layout" style={{ alignItems: "center" }}>
                  <div className="recipient__greeting">
                    {recipientName && <p>{t("HELLO_RECIPIENT", [recipientName])},</p>}
                    {!recipientName && <p>{t("HELLO")}</p>}
                    {giverName && <p>{t("GIVER_SENT_A_MESSAGE", [giverName])}</p>}
                    {!giverName && <p>{t("YOU_RECEIVED_A_MESSAGE")}</p>}
                  </div>
                  <div className="recipient__images">
                    {profileImage && (
                      <img
                        className="recipient__uploaded-giver-image"
                        src={profileImage}
                        alt={t("SENDER")}
                      />
                    )}
                    {productImage && <img src={productImage} alt={t("PRODUCT")} />}
                  </div>
                  <div className="recipient__volume-block">
                    <Volume />
                    {t("AUDIO_VISUAL_MESSAGE")} &nbsp;
                    <br />
                    {t("TURN_UP_THE_VOLUME")}
                  </div>

                  <Button
                    type="primary"
                    shape="round"
                    className="recipient__message-play"
                    onClick={handleOnPlay}
                  >
                    {t("PLAY_MY_MESSAGE")}
                    <Play />
                  </Button>
                </main>
              </div>
            </div>


          )
          }
          </div>


        </div>
      </section>
      {/* <Footer /> */}
    </div >
  );
}
