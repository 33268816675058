import React, {
  useState,
  useEffect,
  memo,
} from 'react';

import {
  useSelector,
  useDispatch,
  shallowEqual
} from 'react-redux';

import {
  withRouter,
  useHistory
} from 'react-router-dom';

import {
  Form,
  Input,
  Button
} from 'antd';

import * as actions from 'Store/actions/index';

import './GiftFor.scss';
import { ReactComponent as ArrowDown } from 'Assets/img/arrow-down.svg';
import { useAuthorable } from 'Hooks/useAuthorable';
import { getImageUrl } from 'Utils/common';
import { useTranslation } from 'Contexts/translation-context';
import { Heading } from 'Components/heading';
import TextFitter from 'Components/Layout/TextFitter';

const REGEX_NAME = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{1,}$/;
export function GiftFor() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const dispatch = useDispatch();
  const [productImg, setProductImg] = useState('https://via.placeholder.com/300')
  // To disable submit button at the beginning.
  const messagesData = useSelector(
    (state) => state.messagesReducer.messagesData, shallowEqual
  );
  const { giftData } = useSelector(state => state.common, shallowEqual)
  const bottleId = useSelector(state => state.common.bottleId, shallowEqual)

  useEffect(() => {
    forceUpdate({});
  }, []);

  useAuthorable();
  useEffect(() => {
    if (messagesData.length === 0) {
      setProductImg('');
      return;
    }
    if (messagesData.length === 1) {
      setProductImg(messagesData[0].product_image);
    } else {
      const message = messagesData.find(data => data.id === bottleId)
      setProductImg(message.product_image);
    }
  }, [messagesData])
  const history = useHistory();

  const onFinish = (values) => {
    dispatch(actions.setGiftData(values))
    history.push('/author/steps/occasion');
  };

  const nameValidator = (rule, value) => {
    if (REGEX_NAME.test(value) || value.length === 0) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(t("VALIDATION_FOR_CHARS")));
    }
  };
  const rules = [{ required: true, message: t("VALIDATION_FOR_EMPTY") }, { validator: nameValidator }];
  return (
    <div className="page-wrap">
      <div className="container">
        <div className="gift-for-heading">
          <div className='product-title'>
            <TextFitter matchParent style={{ display: 'table', transformOrigin: '0 50%' }}>
              <h1 className="multi-size">
                <Heading keyName="WHO_IS_IT_FOR" />
              </h1>
            </TextFitter>
          </div>
          {productImg &&
            <div className="product-image">
              <img src={getImageUrl(productImg)} alt={t("PLACEHOLDER")} />
            </div>
          }
        </div>
        <Form
          form={form}
          name="horizontal_login"
          layout="inline"
          onFinish={onFinish}
          requiredMark={'optional'}
          initialValues={{
            gift_recipient_name: giftData?.gift_recipient_name || '',
            gift_giver_name: giftData?.gift_giver_name || ''
          }}
        >
          <Form.Item
            label={t("TO")}
            name="gift_recipient_name"
            className="half-width"
            rules={rules}
          >
            <Input
              type="text"
              placeholder={t("PLACEHOLDER_NAME")}
              className="font-tight-small gift-name-field"
            />
          </Form.Item>
          <Form.Item
            label={t("FROM")}
            name="gift_giver_name"
            className="half-width"
            rules={rules}
          >
            <Input
              type="text"
              placeholder={t("PLACEHOLDER_NAME")}
              className="font-tight-small gift-name-field"
            />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                shape="round"
                htmlType="submit"
                disabled={
                  !!form
                    .getFieldsError()
                    .filter(({ errors }) => errors.length).length
                }
              >
                {t("OCCASION_BUTTON_TEXT")}
                <ArrowDown />
              </Button>
            )}
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default withRouter(memo(GiftFor));