import { animationsMediaPath, GetAssetPath } from "Services/apiService";
export class AnimationTemplate {
  constructor(parent, colors, duration, keyword) {
    this.colors = colors;
    this.parent = parent;
    this.duration = duration;
    this.keyword = keyword;
    this.animationMediaPath = animationsMediaPath;
    this.getAssetPath = GetAssetPath;
  }

  start() {

  }

  destroy() {

  }

}

export default AnimationTemplate; // Don’t forget to use export default!