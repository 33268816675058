import { BaseCreative } from "./base-creative.js";
import * as THREE from "three";


export class LogoSteps extends BaseCreative {

  getBackgroundColor = () => {
    return this[this.backgroundColour[0]];
  }
  getFontFamily = () => {
    return this.font[this.fontName[0]];
  }
  getTextColor = () => {
    return this[this.textColour[0]];
  }
  addTexture = () => {

    const posX = 1.75;
    const logoContainer = this.addGroup();

    logoContainer.setPosition(-2, -3, 0,
      {
        delay: 0,
        x: posX,
      }, {
      delay: this.duration,
      x: 100,
    });

    this.dummyMapper(3, i => {
      const [x, y, z] = [-20, i * 2 * posX, 0];
      const logo = this.addMesh(null, 2, 1, 1, { map: this.loadAsset().element }, logoContainer.element);
      logo.setScale(2.2, 2.2, 2)
      logo.setPosition(x, y, z,
        {
          delay: 0,
          x: i === 0 ? 0 : i === 1 ? -posX : -(2 * posX),
        }, {
        delay: this.duration,
        x: 10 - i,
      });
    });
  }

  addKeyword = () => {

    //console.log("logo steps");

    const wordContainer = this.addGroup();

    wordContainer.setPosition(0, 0, -1, {}, {
      delay: this.duration,
      y: 0
    });
    [
      [-2.5, -10, -50],
      [0.5, 12, 70]
    ].forEach(([a, b, c]) => {

      [-1.5, 1.5].forEach(multiplier => {
      
        var [x, y, yInOut] = [a, b, c].map(i => i * multiplier);

        const textLine = this.addTextLine(2, 4, 500, this.getTextColor(), undefined, this.getFontFamily(), { parentEl: wordContainer });
        textLine.setRotation(0, 0, Math.PI / 2);
          
          // getting bounding box to measure text width
        var box = new THREE.Box3().setFromObject( textLine.element );

        // calculating text width
        var textWidth = box.max.y - box.min.y;
        // 20:1 = textwidth :s
        // s = textWidth/20
        y *= textWidth/18;

        textLine.setPosition(x, yInOut, 0,
          {
            delay: 0,
            y,
          }, {
          delay: this.duration,
          y: yInOut,
          x: 200
        });
      });

    });
  }

  start() {
    this.addBackground({ sameBackground: true });
    this.addTexture();
    this.addKeyword();
  }
}
