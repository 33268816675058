import * as THREE from "three";
import { GetAssetPath } from "Services/apiService";

export class TaliskerLogo {

  constructor(parent, color, duration, positionVector3) {

    // load and set texture initial state
    const texture = new THREE.TextureLoader().load(GetAssetPath('talisker-logo.png'));

    // create plane 
    const geometry = new THREE.PlaneGeometry(4.16, 1.06, 2);
    const material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide, transparent: true, map: texture });
    const plane = new THREE.Mesh(geometry, material);
    parent.add(plane);

    plane.position.set(positionVector3.x, positionVector3.y, positionVector3.z);

    return plane;
  }

  destroy() {
    //this.parent.remove(this.plane1);
  }

}

export default TaliskerLogo; // Don’t forget to use export default!